import { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router';
import { AsidePanel } from '../../../common/components/layout/aside/AsidePanel';
import Actions from '../../../common/components/navBar/navBarActions/Actions';
import { useCaseType } from '../../../hooks';
import { useUserProfileData } from '../../../hooks/shared/useUserProfileData';
import { CaseType, PathLevels } from '../../../models';
import { navBarActionsConfigOrtho } from '../../../orthodontics/components/layout/navBar';
import { AbilityAction, AbilityContext, CommonAbilitySubject } from '../../../shared';
import { navBarActionsConfigSurgery } from '../../../surgeries/components/layout/navBar';
import NavBarConfiguration from './NavBarConfiguration';
import { SecretAlignerLogo } from './SecretAlignerLogo';
import CaseVersionSelector from './caseVersionSelector/CaseVersionSelector';
import './navBar.scss';
import { CommonPagesUrl } from '../../../types';

export const NAVBAR_ID = 'navbar-id';

export function NavBar() {
  const { pathname } = useLocation();
  const caseType = useCaseType();
  const shortedPathName = `/${pathname.split('/')[PathLevels.RouteView]}`;
  const rootPathName = '/';
  const forbiddenPathName = CommonPagesUrl.Forbidden;
  const incompatiblePathName = CommonPagesUrl.Incompatible;
  const isPageWithNavBar =
    pathname !== rootPathName && pathname !== forbiddenPathName && pathname !== incompatiblePathName;
  const [userProfile] = useUserProfileData();
  const caseTypeShortedPathName = `/${pathname.split('/')[PathLevels.CaseType]}/${
    pathname.split('/')[PathLevels.RouteView]
  }`;

  const ability = useContext(AbilityContext);

  const navBarActionsConfig = useMemo(
    () => (caseType === CaseType.Surgery ? navBarActionsConfigSurgery : navBarActionsConfigOrtho),
    [caseType]
  );

  const getNameInitials = useCallback((name: string) => {
    if (!name) return;
    return name
      .split(' ')
      .map((n: string) => n[0])
      .join('')
      .toUpperCase();
  }, []);

  const getAsidePanelOrLogo = useMemo(() => {
    if (!isPageWithNavBar) {
      return;
    }

    if (!ability || ability.can(AbilityAction.View, CommonAbilitySubject.CaseStatePanel)) {
      return <AsidePanel pathname={caseTypeShortedPathName} />;
    }

    return <SecretAlignerLogo />;
  }, [isPageWithNavBar, ability, caseTypeShortedPathName]);

  return (
    <nav
      id={NAVBAR_ID}
      className={`navbar d-flex justify-content-start ${isPageWithNavBar ? '' : 'navbar-transparent'}`}
    >
      {getAsidePanelOrLogo}
      <div className="navbar-actions d-flex" data-testid="actions-container">
        {navBarActionsConfig.content.body.map((container: any) => {
          if (pathname.includes(Object.keys(container).toString())) {
            return container[shortedPathName]?.map((item: any) => Actions(item));
          }
        })}
      </div>
      <div className="navbar-right d-flex ms-auto">
        {isPageWithNavBar && (
          <div className="navbar-right-container">
            <CaseVersionSelector />
            <div className="navbar-user d-flex align-items-center">
              <span
                className="navbar-user-name me-2 no-select"
                title={`${userProfile?.firstName} ${userProfile?.lastNames}`}
              >{`${userProfile?.firstName} ${userProfile?.lastNames}`}</span>
              {/* <span className="navbar-user-bullet d-flex align-items-center justify-content-center">
                {getNameInitials(userProfile?.email)}
              </span> */}
            </div>
          </div>
        )}
      </div>
      {pathname !== forbiddenPathName && <NavBarConfiguration />}
    </nav>
  );
}
