import { Axis } from '../../../common';
import { StlOrientationMovementTypes } from '../../../orthodontics/components/evergineToolbarElements/tools/stlEdition/stlOrientation/stlOrientation.types';

import { MeasureUnit } from '../../../orthodontics/models';

export const getInitialValues = () => ({
  [StlOrientationMovementTypes.RotationAxisX]: {
    value: 0,
    measureUnit: MeasureUnit.Degrees
  },
  [StlOrientationMovementTypes.RotationAxisY]: {
    value: 0,
    measureUnit: MeasureUnit.Degrees
  },
  [StlOrientationMovementTypes.RotationAxisZ]: {
    value: 0,
    measureUnit: MeasureUnit.Degrees
  },
  [StlOrientationMovementTypes.LeftRight]: {
    value: 0,
    measureUnit: MeasureUnit.Millimeter
  },
  [StlOrientationMovementTypes.UpBottom]: {
    value: 0,
    measureUnit: MeasureUnit.Millimeter
  },
  [StlOrientationMovementTypes.InsideOutside]: {
    value: 0,
    measureUnit: MeasureUnit.Millimeter
  }
});

export const mapMovementFunctionFrom = (movementType: StlOrientationMovementTypes): Function => {
  const mapping: Record<StlOrientationMovementTypes, Function> = {
    [StlOrientationMovementTypes.RotationAxisX]: window.App.webEventsProxy.modelTransformation.sendRotationToTransform,
    [StlOrientationMovementTypes.RotationAxisY]: window.App.webEventsProxy.modelTransformation.sendRotationToTransform,
    [StlOrientationMovementTypes.RotationAxisZ]: window.App.webEventsProxy.modelTransformation.sendRotationToTransform,
    [StlOrientationMovementTypes.LeftRight]: window.App.webEventsProxy.modelTransformation.sendTranslationToTransform,
    [StlOrientationMovementTypes.UpBottom]: window.App.webEventsProxy.modelTransformation.sendTranslationToTransform,
    [StlOrientationMovementTypes.InsideOutside]:
      window.App.webEventsProxy.modelTransformation.sendTranslationToTransform
  };

  return mapping[movementType];
};

export const mapAbsoluteMovementFunctionFrom = (movementType: StlOrientationMovementTypes): Function => {
  const mapping: Record<StlOrientationMovementTypes, Function> = {
    [StlOrientationMovementTypes.RotationAxisX]:
      window.App.webEventsProxy.modelTransformation.applyAbsoluteValueOnRotation,
    [StlOrientationMovementTypes.RotationAxisY]:
      window.App.webEventsProxy.modelTransformation.applyAbsoluteValueOnRotation,
    [StlOrientationMovementTypes.RotationAxisZ]:
      window.App.webEventsProxy.modelTransformation.applyAbsoluteValueOnRotation,
    [StlOrientationMovementTypes.LeftRight]:
      window.App.webEventsProxy.modelTransformation.applyAbsoluteValueOnTranslation,
    [StlOrientationMovementTypes.UpBottom]:
      window.App.webEventsProxy.modelTransformation.applyAbsoluteValueOnTranslation,
    [StlOrientationMovementTypes.InsideOutside]:
      window.App.webEventsProxy.modelTransformation.applyAbsoluteValueOnTranslation
  };

  return mapping[movementType];
};

export const mapMovementAxisFrom = (movementType: StlOrientationMovementTypes): Axis => {
  const mapping: Record<StlOrientationMovementTypes, Axis> = {
    [StlOrientationMovementTypes.RotationAxisX]: Axis.X,
    [StlOrientationMovementTypes.RotationAxisY]: Axis.Y,
    [StlOrientationMovementTypes.RotationAxisZ]: Axis.Z,
    [StlOrientationMovementTypes.LeftRight]: Axis.X,
    [StlOrientationMovementTypes.UpBottom]: Axis.Y,
    [StlOrientationMovementTypes.InsideOutside]: Axis.Z
  };

  return mapping[movementType];
};
