import { useCallback } from 'react';
import { DentalMovementGroup, INJECTED_TYPES, Step, container } from '../../common';
import { CaseVersion, CaseVersionState, IDentalMovementsService } from '../../shared';
import { useUtils } from './useUtils';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { updateTreatmentPlanificationMovements } from '../../common/utils';

export function useGetDentalMovements() {
  const dentalMovementsService = container.get<IDentalMovementsService>(INJECTED_TYPES.IDentalMovementsService);
  const { getStageEvergineByCurrentPhase } = useUtils();
  const currentVersion = useOrthBoundStore((state) => state.currentVersion);

  const getDentalMovementsFromEvergine = useCallback((): {
    upperMovements: DentalMovementGroup | null;
    lowerMovements: DentalMovementGroup | null;
  } => {
    const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();
    return {
      upperMovements,
      lowerMovements
    };
  }, []);

  const buildFinalStepFromDesignedByClient = (clientFinalPosition: Step, newStepIndex: number) => ({
    ...clientFinalPosition,
    stepIndex: newStepIndex,
    toothMovements: clientFinalPosition.toothMovements.map((tm) => ({
      ...tm,
      movedInCurrentStep: false
    })),
    isClientModification: false
  });

  const getDentalMovementsFromBackend = useCallback(
    async (
      version: CaseVersion
    ): Promise<{ upperMovements: DentalMovementGroup | null; lowerMovements: DentalMovementGroup | null }> => {
      const movements = await dentalMovementsService.getTreatmentPlanificationMovements(version.caseId, version.id);

      if (movements !== null && (movements.upper !== null || movements.lower !== null)) {
        if (!!currentVersion?.clientFinalPositionStep) {
          if (!!currentVersion.clientFinalPositionStep.upper) {
            movements.upper.steps = [
              ...movements.upper.steps.slice(0, -1),
              buildFinalStepFromDesignedByClient(
                currentVersion.clientFinalPositionStep.upper,
                movements.upper.steps.length - 1
              )
            ];
          }
          if (!!currentVersion.clientFinalPositionStep.lower) {
            movements.lower.steps = [
              ...movements.lower.steps.slice(0, -1),
              buildFinalStepFromDesignedByClient(
                currentVersion.clientFinalPositionStep.lower,
                movements.lower.steps.length - 1
              )
            ];
          }
          // movements.upper.finalPositionHasBeenReplacedByClient = true;
          // movements.lower.finalPositionHasBeenReplacedByClient = true;

          await updateTreatmentPlanificationMovements(currentVersion.caseId, currentVersion, {
            upper: movements.upper,
            lower: movements.lower
          });
        }

        return Promise.resolve({
          upperMovements: movements.upper,
          lowerMovements: movements.lower
        });
      }

      return Promise.resolve({
        upperMovements: null,
        lowerMovements: null
      });
    },
    [currentVersion, dentalMovementsService]
  );

  const updateTreatmentState = useCallback(
    async (caseId: string, version: CaseVersion, state: CaseVersionState, isResetingPlan = false) => {
      const stage = getStageEvergineByCurrentPhase();
      await dentalMovementsService.updateTreatmentState(caseId, version, state, stage, isResetingPlan);
    },
    [dentalMovementsService, getStageEvergineByCurrentPhase]
  );

  return { updateTreatmentState, getDentalMovementsFromEvergine, getDentalMovementsFromBackend };
}
