import { useEffect, useMemo, useRef, useState } from 'react';
import { EvolutionStep } from '../../orthodontics';

export function usePiecesRows(
  activeStep: number,
  currentSliceSteps: EvolutionStep[],
  headerStepId: string,
  showEvolutionPanel: boolean
) {
  const piecesRef = useRef(null);
  const defaultPositionXInPixels = 8;
  const [isBodyScrollVisible, setIsBodyScrollVisible] = useState<boolean>();
  const [positionX, setPositionX] = useState<number>(defaultPositionXInPixels);
  const [visibilityBefore, setVisibilityBefore] = useState<string>('block');

  const defaultActiveStepStyle = useMemo(
    () =>
      ({
        '--before-height': `${piecesRef.current?.scrollHeight}px`,
        '--position-x': `${positionX}px`,
        '--visibility-before': visibilityBefore,
        overflow: `${isBodyScrollVisible ? 'auto' : 'hidden'}`,
        overflowX: 'hidden'
      } as React.CSSProperties),
    [piecesRef.current?.scrollHeight, isBodyScrollVisible, positionX, visibilityBefore]
  );

  useEffect(() => {
    const updatePositionX = () => {
      const selectedStepIndexEl = document.getElementById(`${headerStepId}${activeStep}`);

      if (!selectedStepIndexEl) {
        if (piecesRef.current) {
          setVisibilityBefore('none');
        }
        return;
      }

      const newPositionX = selectedStepIndexEl.offsetLeft || defaultPositionXInPixels;

      if (newPositionX !== positionX) {
        setPositionX(newPositionX);
      }

      if (piecesRef.current) {
        setVisibilityBefore('block');
      }
    };

    updatePositionX();
  }, [activeStep, showEvolutionPanel, currentSliceSteps]);

  useEffect(() => {
    const updateScrollVisibility = () => {
      if (!piecesRef.current) {
        return;
      }

      const isScrollVisible = piecesRef.current.clientHeight < piecesRef.current.scrollHeight;
      setIsBodyScrollVisible(isScrollVisible);
    };

    updateScrollVisibility();

    const observer = new ResizeObserver(() => {
      updateScrollVisibility();
    });

    if (piecesRef.current) {
      observer.observe(piecesRef.current);
    }

    window.addEventListener('resize', updateScrollVisibility);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', updateScrollVisibility);
    };
  }, [piecesRef, showEvolutionPanel]);

  return {
    piecesRef,
    defaultActiveStepStyle,
    isBodyScrollVisible
  };
}
