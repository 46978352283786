import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import shallow from 'zustand/shallow';
import { ReactComponent as Info } from '../../../../../assets/icons/modal/info.svg';
import { AttachmentStepMode } from '../../../../../common';
import Radio from '../../../../../common/components/radio/Radio';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useUtils } from '../../../../../hooks/shared/useUtils';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';
import { useDentalMovementsSnapshot } from '../../../../../hooks';
import { ModalAttachmentType } from './modal-attache-types.enum';

interface ModalAddAttachProps {
  type?: ModalAttachmentType;
}

export function ModalAddAttach({ type = ModalAttachmentType.Attache }: ModalAddAttachProps) {
  const toggleModalIsOpened = useCommonBoundStore((state) => state.toggleModalIsOpened, shallow);
  const { selectedTeethId, attachmentTypeToBeAdded, setAddingAttache } = useOrthBoundStore(
    (state) => ({
      selectedTeethId: state.selectedTeethId,
      attachmentTypeToBeAdded: state.attachmentTypeToBeAdded,
      setAddingAttache: state.setAddingAttache
    }),
    shallow
  );

  const [t] = useTranslation();
  const { updateMovements } = useUtils();
  const { updateDentalMovementsSnapshot } = useDentalMovementsSnapshot();
  const [selectedOption, setSelectedOption] = useState<number>(AttachmentStepMode.AllSteps);

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  const handleContinue = () => {
    if (!attachmentTypeToBeAdded) {
      return;
    }

    if (selectedTeethId.length > 0) {
      selectedTeethId.forEach((id) => {
        const addedAttache = window.App.webEventsProxy.attachments.addAttachment(
          id,
          attachmentTypeToBeAdded,
          selectedOption
        );
        setAddingAttache(addedAttache);
      });
    }

    updateMovements();
    updateDentalMovementsSnapshot();
    toggleModalIsOpened('');
  };

  const handleSelected = (selectedOption: number) => {
    setSelectedOption(selectedOption);
  };

  const isActive = (option: number) => {
    return selectedOption === option;
  };

  const getModalTitleBy = (type: ModalAttachmentType) => {
    const partialTranslationPath = 'modal.addAttachment';
    if (type === ModalAttachmentType.Attache) {
      return t(`${partialTranslationPath}.titleAttache`);
    }

    if (type === ModalAttachmentType.TAD) {
      return t(`${partialTranslationPath}.titleTAD`);
    }

    return t(`${partialTranslationPath}.titleLabel`);
  };

  return (
    <div className="modal-content" data-testid={'modal-addAttach'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <Info className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">{getModalTitleBy(type)}</h3>
        <p className="modal-options modal-text text-left mb-0">
          <Radio
            label={t(`modal.add.options.addInAllSteps`)}
            active={isActive(AttachmentStepMode.AllSteps)}
            callBack={() => handleSelected(AttachmentStepMode.AllSteps)}
            expanded={false}
          />
          <Radio
            label={t(`modal.add.options.addFromActualStepToLast`)}
            active={isActive(AttachmentStepMode.FromCurrentStepToEnd)}
            callBack={() => handleSelected(AttachmentStepMode.FromCurrentStepToEnd)}
            expanded={false}
          />
          <Radio
            label={t(`modal.add.options.addFromActualStepToInitial`)}
            active={isActive(AttachmentStepMode.FromCurrentStepToStart)}
            callBack={() => handleSelected(AttachmentStepMode.FromCurrentStepToStart)}
            expanded={false}
          />
        </p>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button
          onClick={onCloseButtonClick}
          type="button"
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.cancel`)}
        </button>
        <button
          onClick={handleContinue}
          disabled={selectedOption === null}
          type="button"
          className="btn btn-primary flex-fill modal-button"
        >
          {t('common.add')}
        </button>
      </div>
    </div>
  );
}
