import { CSSProperties, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { PROFILES_TYPE } from '../../../shared';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useCommonBoundStore } from '../../stores/useStore';
import { ToolbarConfigProps } from './evergine-toolbar-config';
import './evergineToolbar.scss';
import { NAVBAR_ID } from '../navBar';

type EvergineToolbarProps = {
  tools: ToolbarConfigProps[];
};

export const EvergineToolbar = memo(({ tools }: EvergineToolbarProps) => {
  const [selectedTool, setSelectedTool] = useState<string | undefined>();
  const [t] = useTranslation();
  const toolbarRef = useRef(null);
  const toggleModalIsOpened = useCommonBoundStore((state) => state.toggleModalIsOpened);
  const navbarElement = document.getElementById(NAVBAR_ID);
  const isClient = useBoundStore.getState().userProfile?.type === PROFILES_TYPE.client;

  const styles = useMemo(() => {
    const navbarBottomPosition = navbarElement?.getBoundingClientRect()?.bottom;
    const position = navbarBottomPosition && navbarBottomPosition > 0 ? navbarBottomPosition : 93;
    return { top: position } as CSSProperties;
  }, [navbarElement]);

  function onClickTool(
    key: string | undefined,
    handleClick: Function | undefined,
    e?: React.KeyboardEvent<HTMLButtonElement>
  ) {
    openModalIfNecesary(key);
    const tool = tools.find((tool) => tool.tooltipKey === selectedTool);
    if (e && selectedTool === key && e.key) {
      return;
    }

    if (tool?.keepOpen) {
      selectedTool ? setSelectedTool(undefined) : setSelectedTool(key);
    } else {
      setSelectedTool(key);
    }
    handleClick && handleClick();
  }

  if (tools && tools.length > 0) {
    tools.forEach((tool) => {
      if (tool?.shortcut) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useHotkeys(
          tool.shortcut,
          () => {
            setSelectedTool(tool.tooltipKey);
            tool.handleClick && tool.handleClick();
          },
          {
            // following are the default options, we put here to document how to declare shortcuts in the toolbar's actions
            combinationKey: '+',
            splitKey: ','
          }
        );
      }
    });
  }

  const openModalIfNecesary = (key: string | undefined) => {
    const tool = tools.find((tool) => tool.tooltipKey === key);
    if (tool?.openModal) {
      toggleModalIsOpened(tool?.modalType);
    }
  };

  const isToolHidden = (tool: ToolbarConfigProps) => {
    if (tool.hiddenForClient && isClient) {
      return true;
    }

    if (!tool.isHidden) {
      return false;
    }

    return tool.isHidden();
  };

  const isToolDisabled = (tool: ToolbarConfigProps) => {
    if (!tool.isDisabled) {
      return false;
    }

    return tool.isDisabled();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (toolbarRef.current && !toolbarRef.current.contains(event.target)) {
        const tool = tools.find((tool) => tool.tooltipKey === selectedTool);
        if (!tool?.keepOpen) setSelectedTool(undefined);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedTool, tools]);

  return (
    <div className="etoolbar" style={styles} ref={toolbarRef}>
      <div className="etoolbar-container">
        {tools.map((tool) => {
          const toolKey = tool.tooltipKey;
          const ToolComponent = tool.customComponent;
          const ToolIcon = tool.conditionalIcon ? tool.conditionalIcon() : tool.icon;

          return (
            !isToolHidden(tool) && (
              <div key={toolKey}>
                {tool.renderCustomComponent ? (
                  <ToolComponent
                    handleClick={() => onClickTool(toolKey, tool.handleClick)}
                    dataFor={toolKey}
                    className={`etoolbar-button clickable ${selectedTool === toolKey ? 'is-active' : ''}`}
                  />
                ) : (
                  <button
                    data-for={toolKey}
                    data-tip
                    id={tool?.customId}
                    className={`etoolbar-button ${isToolDisabled(tool) ? 'disabled' : 'clickable'} ${
                      selectedTool === toolKey ? 'is-active' : ''
                    }`}
                    onClick={() => onClickTool(toolKey, tool.handleClick)}
                    onKeyDown={(e) => onClickTool(toolKey, tool.handleClick, e)}
                    disabled={isToolDisabled(tool)}
                  >
                    <ToolIcon />
                  </button>
                )}
                <ReactTooltip id={toolKey} place="left" effect="solid" className="tooltip" type="dark">
                  {t(toolKey || '')}
                </ReactTooltip>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
});
