import { useCallback } from 'react';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { useUtils } from './useUtils';

export function useIprCalculation() {
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const { getCurrentPhaseStageByRoute } = useUtils();
  const isCaseReseting = useCommonBoundStore((state) => state.isCaseReseting);
  const { setIprProcessed, iprProcessed, movementsTable, caseStatusFromStore, setShowIprPanel, setForceShowIpr } =
    useOrthBoundStore((state) => ({
      setIprProcessed: state.setIprProcessed,
      iprProcessed: state.iprProcessed,
      movementsTable: state.movementsTable,
      caseStatusFromStore: state.caseStatus,
      setShowIprPanel: state.setShowIprPanel,
      setForceShowIpr: state.setForceShowIpr
    }));

  const calculateIpr = useCallback(async () => {
    setWebBusy(true);
    await window.App.webEventsProxy.movements.processIntermediateSteps();
    setWebBusy(false);
    setIprProcessed(true);
    setShowIprPanel(false);
    setForceShowIpr(true);
  }, [setForceShowIpr, setIprProcessed, setShowIprPanel, setWebBusy]);

  const getPhaseCompletionDate = useCallback(
    (phaseKey: string) => {
      if (!caseStatusFromStore) {
        return;
      }
      const phase = caseStatusFromStore.phases.find((p) => p.name === phaseKey);
      return phase && phase.completionDate ? new Date(phase.completionDate).toLocaleDateString('es-es') : '';
    },
    [caseStatusFromStore]
  );

  const hasPhaseCompletionDate = useCallback((): boolean => {
    const currentPhase = getCurrentPhaseStageByRoute();
    if (currentPhase === undefined) {
      return false;
    }
    const completionDate = getPhaseCompletionDate(currentPhase);

    return completionDate !== '';
  }, [getCurrentPhaseStageByRoute, getPhaseCompletionDate]);

  const checkIfIprsAreCalculated = useCallback((): boolean => {
    return (
      movementsTable?.movementsRelative?.length <= 2 ||
      iprProcessed ||
      (iprProcessed == null && hasPhaseCompletionDate() && !isCaseReseting)
    );
  }, [movementsTable, iprProcessed, hasPhaseCompletionDate, isCaseReseting]);

  return { calculateIpr, checkIfIprsAreCalculated };
}
