import { useCallback, useEffect } from 'react';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useShallow } from 'zustand/react/shallow';
import { EvolutionStep } from '../../../orthodontics';
import { useStepsManager } from '../useStepsManager';

export function useEvolutionPanelNavigator(steps: EvolutionStep[]) {
  const {
    currentSliceRange,
    setCurrentSliceRange,
    setCurrentSliceSteps,
    setIsEvolutionPanelSliced,
    fitColumnsInPanel
  } = useOrthBoundStore(
    useShallow((state) => ({
      currentSliceRange: state.currentSliceRange,
      setCurrentSliceRange: state.setCurrentSliceRange,
      setCurrentSliceSteps: state.setCurrentSliceSteps,
      setIsEvolutionPanelSliced: state.setIsEvolutionPanelSliced,
      fitColumnsInPanel: state.fitColumnsInPanel
    }))
  );

  const { activeStep, setActiveStep, setSelectedStepIndexes } = useCommonBoundStore(
    useShallow((state) => ({
      activeStep: state.activeStep,
      setActiveStep: state.setActiveStep,
      setSelectedStepIndexes: state.setSelectedStepIndexes
    }))
  );

  const { goToStep } = useStepsManager();

  const markAsCurrentStep = useCallback(
    (stepIndex: number) => {
      goToStep(stepIndex, false);
      setSelectedStepIndexes([stepIndex]);
    },
    [setActiveStep, setSelectedStepIndexes]
  );

  const isFirstStep = useCallback(() => {
    return activeStep === 0;
  }, [activeStep]);

  const isLastStep = useCallback(() => {
    return activeStep === steps.length - 1;
  }, [activeStep, steps.length]);

  const isLastSlice = useCallback(() => {
    if (!currentSliceRange) {
      return true;
    }

    return currentSliceRange.to >= steps.length;
  }, [currentSliceRange, steps]);

  const goToNextStep = useCallback(() => {
    if (isLastStep()) {
      return;
    }

    markAsCurrentStep(activeStep + 1);
  }, [
    isLastStep,
    isLastSlice,
    markAsCurrentStep,
    activeStep,
    currentSliceRange?.from,
    currentSliceRange?.to,
    setCurrentSliceRange,
    setCurrentSliceSteps,
    steps
  ]);

  const goToPreviousStep = useCallback(() => {
    if (isFirstStep()) {
      return;
    }

    markAsCurrentStep(activeStep - 1);
  }, [
    isFirstStep,
    currentSliceRange,
    markAsCurrentStep,
    activeStep,
    setCurrentSliceRange,
    setCurrentSliceSteps,
    steps
  ]);

  const goToFirstStep = useCallback(() => {
    markAsCurrentStep(0);
  }, [markAsCurrentStep]);

  const goToLastStep = useCallback(() => {
    markAsCurrentStep(steps.length - 1);
  }, [markAsCurrentStep, steps.length]);

  const handleChangeInActiveStep = useCallback(() => {
    if (Number.isNaN(activeStep) || activeStep === undefined || !steps) return;
    const halfFitRows = Math.floor(fitColumnsInPanel / 2);

    let from = Math.max(0, activeStep - halfFitRows);
    let to = from + fitColumnsInPanel;

    if (to > steps.length) {
      to = steps.length;
      from = Math.max(0, to - fitColumnsInPanel);
    }

    setCurrentSliceRange({ from, to });
    const sliceSteps = steps.slice(from, to);
    setCurrentSliceSteps(steps.slice(from, to));
    setIsEvolutionPanelSliced(sliceSteps.length !== steps.length);
  }, [fitColumnsInPanel, activeStep, steps, setCurrentSliceRange, setCurrentSliceSteps]);

  useEffect(() => {
    if (!steps || steps.length === 0) {
      return;
    }
    handleChangeInActiveStep();
  }, [activeStep, steps, handleChangeInActiveStep]);

  return {
    activeStep,
    isFirstStep,
    isLastStep,
    goToPreviousStep,
    goToNextStep,
    goToFirstStep,
    goToLastStep,
    handleChangeInActiveStep
  };
}
