import { createWithEqualityFn } from 'zustand/traditional';
import { createToothTransformSlice, ToothTransformSlice } from './slices/toothTransformSlice';
import { shallow } from 'zustand/shallow';

export type ToothTransformState = ToothTransformSlice;

/* eslint-enable*/

export const useToothTransformStore = createWithEqualityFn<ToothTransformState>()(
  (...a) => ({
    ...createToothTransformSlice(...a)
  }),
  shallow
);
