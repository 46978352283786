import { ReactComponent as IPR } from '../../../assets/icons/evergineTools/ipr.svg';
import { ReactComponent as IPR_PENDING } from '../../../assets/icons/evergineTools/ipr-pending.svg';

import { ToolbarConfigProps } from '../../../common';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { debouncedSetState } from '../../../common/utils';
import { PROFILES_TYPE } from '../../../shared';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../stores/useStore';
import { useIprCalculation } from '../../../hooks/shared/useIprCalculation';

export const iprMovementsToolbarElement: ToolbarConfigProps = {
  icon: IPR,
  tooltipKey: 'evergineTools.ipr',
  customId: 'iprPanelButton',
  handleClick: () => {
    const currentValue = useOrthBoundStore.getState().showIprPanel;
    debouncedSetState(useOrthBoundStore, { showIprPanel: !currentValue });
  },
  isHidden: () => {
    const isClient = useBoundStore.getState().userProfile?.type === PROFILES_TYPE.client;
    const isModificationMode = useCommonBoundStore.getState().isNewStepClientModification;
    const isHiddenForClient = isClient && !isModificationMode;

    return (
      isHiddenForClient ||
      (!useOrthBoundStore.getState().lowerDentalMovements && !useOrthBoundStore.getState().upperDentalMovements)
    );
  },
  conditionalIcon: () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { checkIfIprsAreCalculated } = useIprCalculation();
    return checkIfIprsAreCalculated() ? IPR : IPR_PENDING;
  }
};
