import { useAttachesManager } from '../../../hooks';
import { CustomTooltip, PagesWithTools, RenderModels, Stage } from '../../../common';
import { GeneralPanels } from '../../layout';
import { useOrthBoundStore } from '../../stores/useStore';
import { OrthContextualMenu, PagesWithSpecificOptions } from '../contextMenus';
import { useMemo } from 'react';
import { IPRButtons } from '../buttonsIPR';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { ClientValidation } from '../../../common/components/clientValidation';

import { TreatmentTimeline } from '.';

const firstStepIndex = 0;

export function Treatment() {
  return (
    <RenderModels stage={Stage.Treatment}>
      <TreatmentContent />
    </RenderModels>
  );
}

export function TreatmentContent() {
  const { activeStep, isExpertModeEnabled } = useCommonBoundStore((state) => ({
    activeStep: state.activeStep,
    isExpertModeEnabled: state.isExpertModeEnabled
  }));
  const showIpr = useOrthBoundStore((state) => state.showIPR);

  const { hoveredAttach, positionTooltip, thicknessTextTooltip } = useAttachesManager();

  const showClientValidationIfAllowed = useMemo(() => {
    if (isExpertModeEnabled) {
      return <ClientValidation isInEditionMode={true} isCaseBlocked={true} />;
    }
  }, [isExpertModeEnabled]);

  return (
    <TreatmentTimeline>
      <GeneralPanels pageWithTools={PagesWithTools.Treatment}>
        <>
          {hoveredAttach && <CustomTooltip text={thicknessTextTooltip} position={positionTooltip} />}{' '}
          <OrthContextualMenu
            preventContextMenu={activeStep === firstStepIndex}
            pageWithSpecificOptions={PagesWithSpecificOptions.Treatment}
          />
          {showIpr && <IPRButtons />}
          {showClientValidationIfAllowed}
        </>
      </GeneralPanels>
    </TreatmentTimeline>
  );
}
