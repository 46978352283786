import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { CommonPagesUrl } from '../../../../../types';
import { BaseTeethArchDropdown, NavBarAction, Split } from '../../../../../common';
import { PathLevels, TeethArchPosition } from '../../../../../models';
import { useShowVisibleTeetharch, useWindowSize } from '../../../../../hooks';
import { useFocusCanvas } from '../../../../../hooks/shared/useFocusCanvas';
import { useBoundStore } from '../../../../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../../../stores/useStore';
import { ArchsToTreat, Constants } from '../../../../../shared';

import LowerTheethArch from '../../../../../assets/icons/jaw-bottom.svg';
import OpenJaws from '../../../../../assets/icons/jaw-open.svg';
import UpperTheethArch from '../../../../../assets/icons/jaw-top.svg';
import BothTheethArch from '../../../../../assets/icons/jaws.svg';

import './orthoteetharch.scss';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useShallow } from 'zustand/react/shallow';

export const OrthoTeethArch = (actionProps: any) => {
  const {
    orthTeethArch,
    setOrthTeethArch,
    showUpperArch,
    setShowUpperArch,
    showLowerArch,
    setShowLowerArch,
    setShowMiniOcclusogram
  } = useOrthBoundStore(
    useShallow((state) => ({
      orthTeethArch: state.orthTeethArch,
      setOrthTeethArch: state.setOrthTeethArch,
      showUpperArch: state.showUpperArch,
      setShowUpperArch: state.setShowUpperArch,
      showLowerArch: state.showLowerArch,
      setShowLowerArch: state.setShowLowerArch,
      setShowMiniOcclusogram: state.setShowMiniOcclusogram
    }))
  );
  const { setSplitMode, setTeethArch } = useBoundStore(
    useShallow((state) => ({
      setSplitMode: state.setSplitMode,
      setTeethArch: state.setTeethArch
    }))
  );
  const archsToBeTreated = useCommonBoundStore((state) => state.archsToBeTreated);
  const size = useWindowSize();
  const { showVisibleCaptureFromPosition } = useShowVisibleTeetharch();
  const [t] = useTranslation();
  const { pathname } = useLocation();
  const { setFocusOnCanvas } = useFocusCanvas();
  const [currentBothArchSelected, setCurrentBothArchSelected] = useState(TeethArchPosition.BOTH);
  const shortedPathName = useMemo(() => `/${pathname.split('/')[PathLevels.RouteView]}`, [pathname]);

  const options = [
    { translationKey: 'upper', type: TeethArchPosition.UPPER },
    { translationKey: 'lower', type: TeethArchPosition.LOWER },
    { translationKey: 'both', type: TeethArchPosition.BOTH },
    { translationKey: 'open', type: TeethArchPosition.BOTH_OPEN }
  ];

  const onSelectTeeth = useCallback(
    (option: TeethArchPosition) => {
      const isBoth = option === TeethArchPosition.BOTH || option === TeethArchPosition.BOTH_OPEN;
      /* const isUpperVisible = isBoth ? true : option === TeethArchPosition.UPPER ? !showUpperArch : showUpperArch;
      const isLowerVisible = isBoth ? true : option === TeethArchPosition.LOWER ? !showLowerArch : showLowerArch; */
      const isUpperVisible = isBoth ? true : option === TeethArchPosition.UPPER ? showUpperArch : false;
      const isLowerVisible = isBoth ? true : option === TeethArchPosition.LOWER ? showLowerArch : false;
      const isBothArchVisible = isUpperVisible && isLowerVisible;
      const archToShow = isBoth ? option : isBothArchVisible ? currentBothArchSelected : option;

      showVisibleCaptureFromPosition[archToShow](isUpperVisible, isLowerVisible);
      setTeethArch(archToShow);

      setShowUpperArch(isBoth || option === TeethArchPosition.UPPER);
      setShowLowerArch(isBoth || option === TeethArchPosition.LOWER);
      setOrthTeethArch(option);

      if (isBoth) {
        setCurrentBothArchSelected(option);
      }

      if (actionProps.block.changeSplitMode) {
        setSplitMode(isBoth ? Split.Split2 : Split.Single);
      }

      if (option === TeethArchPosition.BOTH_OPEN) {
        setShowMiniOcclusogram(false);
      }

      setFocusOnCanvas();
    },
    [showLowerArch, setShowLowerArch, showUpperArch, setShowUpperArch, currentBothArchSelected]
  );

  const getItemsOnARow = () => (
    <>
      <NavBarAction
        icon={UpperTheethArch}
        dataFor="archViewUpper"
        disabled={shortedPathName === CommonPagesUrl.StlCaptures || archsToBeTreated === ArchsToTreat.Lower}
        altText={t('navbar.actions.maxilla.upper')}
        action={() => onSelectTeeth(TeethArchPosition.UPPER)}
      />
      <ReactTooltip id="archViewUpper" place="bottom" effect="solid" className="tooltip" type="dark">
        {t('navbar.actions.maxilla.upper')}
      </ReactTooltip>
      <NavBarAction
        icon={LowerTheethArch}
        dataFor="archViewLower"
        disabled={shortedPathName === CommonPagesUrl.StlCaptures || archsToBeTreated === ArchsToTreat.Upper}
        altText={t('navbar.actions.maxilla.lower')}
        action={() => onSelectTeeth(TeethArchPosition.LOWER)}
      />
      <ReactTooltip id="archViewLower" place="bottom" effect="solid" className="tooltip" type="dark">
        {t('navbar.actions.maxilla.lower')}
      </ReactTooltip>
      <NavBarAction
        icon={BothTheethArch}
        dataFor="archViewBoth"
        disabled={shortedPathName === CommonPagesUrl.StlCaptures || archsToBeTreated !== ArchsToTreat.Both}
        altText={t('navbar.actions.maxilla.both')}
        action={() => onSelectTeeth(TeethArchPosition.BOTH)}
      />
      <ReactTooltip id="archViewBoth" place="bottom" effect="solid" className="tooltip" type="dark">
        {t('navbar.actions.maxilla.both')}
      </ReactTooltip>
      <NavBarAction
        icon={OpenJaws}
        dataFor="archViewBothOpen"
        disabled={shortedPathName === CommonPagesUrl.StlCaptures || archsToBeTreated !== ArchsToTreat.Both}
        altText={t('navbar.actions.maxilla.open')}
        action={() => onSelectTeeth(TeethArchPosition.BOTH_OPEN)}
        isEndOfBlock={true}
      />
      <ReactTooltip id="archViewBothOpen" place="bottom" effect="solid" className="tooltip" type="dark">
        {t('navbar.actions.maxilla.open')}
      </ReactTooltip>
    </>
  );

  return size.width <= Constants.widthMSizePixels ? (
    <BaseTeethArchDropdown
      dropdownOptions={options}
      handleSelectOption={(option: TeethArchPosition) => onSelectTeeth(option)}
      defaultType={orthTeethArch || TeethArchPosition.BOTH}
    />
  ) : (
    getItemsOnARow()
  );
};
