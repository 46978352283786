import { useMemo } from 'react';
import { CaseType, PathLevels } from '../../models';
import { OrthodonticsOrderedRoutes } from '../../orthodontics/components/layout';
import { SurgeryOrderedRoutes } from '../../surgeries/components/layout/aside/surgeryAsideConfig';

function getCurrentRouteIndex(enumValues: any[], currentEnumValue: string) {
  const currentRouteIndex = enumValues.findIndex((route) => route.toString().includes(currentEnumValue));

  if (currentRouteIndex === -1) {
    return null;
  }

  return currentRouteIndex;
}

function getNextEnumValue<T extends Record<string, string>>(enumObj: T, currentEnumValue: string): keyof T | null {
  const enumValues = Object.values(enumObj) as unknown as Array<keyof T>;
  const index = getCurrentRouteIndex(enumValues, currentEnumValue);

  return index !== null ? enumValues[index + 1] : null;
}

function getPreviousEnumValue<T extends Record<string, string>>(enumObj: T, currentEnumValue: string): keyof T | null {
  const enumValues = Object.values(enumObj) as unknown as Array<keyof T>;
  const index = getCurrentRouteIndex(enumValues, currentEnumValue);

  return index !== null ? enumValues[index - 1] : null;
}

export function useCalculateRoutes(pathname: string, caseId: string, caseType: CaseType) {
  const isSurgery = caseType != CaseType.Orthodontics;

  const calculateNextRoute = useMemo(() => {
    const currentRoute = `/${pathname.split('/')[PathLevels.RouteView]}`;
    const nextRoute = getNextEnumValue(isSurgery ? SurgeryOrderedRoutes : OrthodonticsOrderedRoutes, currentRoute);

    if (!nextRoute) {
      return '/';
    }
    return `${nextRoute}/${caseId || pathname.split('/')[PathLevels.CaseId]}`;
  }, [caseId, isSurgery, pathname]);

  const calculatePreviousRoute = useMemo(() => {
    const currentRoute = `/${pathname.split('/')[PathLevels.RouteView]}`;
    const previousRoute = getPreviousEnumValue(
      isSurgery ? SurgeryOrderedRoutes : OrthodonticsOrderedRoutes,
      currentRoute
    );

    if (!previousRoute) {
      return '/';
    }

    return `${previousRoute}/${caseId || pathname.split('/')[PathLevels.CaseId]}`;
  }, [caseId, isSurgery, pathname]);

  return [calculateNextRoute, calculatePreviousRoute] as const;
}
