import { useTranslation } from 'react-i18next';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useEffect } from 'react';

export const ErrorBoundaryMessage = () => {
  const [t] = useTranslation();
  const setWebBusy = useBoundStore((state) => state.setWebBusy);

  useEffect(() => {
    setWebBusy(false);
  }, [setWebBusy]);

  return <div className="error-boundary">{t('errors.boundaryError')}</div>;
};
