import { Container } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Must be placed before <Routers>
import { AppRouter } from './AppRouter';

import { AuthGuard } from './common';
import './styles/app.scss';

export default function App() {
  return (
    <AuthGuard>
      <BrowserRouter>
        <Container id="app" disableGutters={true} maxWidth={false}>
          <AppRouter />
        </Container>
      </BrowserRouter>
    </AuthGuard>
  );
}
