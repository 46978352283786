import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { ReactComponent as CheckIcon } from '../../../../../assets/icons/modal/check.svg';
import { useEffect } from 'react';

export function ModalAutoClosed() {
  const { toggleModalIsOpened, modalMessage, modalTitle, setModalMessage, setModalTitle } = useCommonBoundStore(
    (store) => ({
      toggleModalIsOpened: store.toggleModalIsOpened,
      modalMessage: store.modalMessage,
      modalTitle: store.modalTitle,
      setModalMessage: store.setModalMessage,
      setModalTitle: store.setModalTitle
    })
  );
  const MILLISECONDS_MODAL_ACTIVE = 5 * 1_000;

  useEffect(() => {
    const timer = setTimeout(() => {
      toggleModalIsOpened('');
      setModalMessage('');
      setModalTitle('');
    }, MILLISECONDS_MODAL_ACTIVE);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="modal-content" data-testid={'modal-autoClosed'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <CheckIcon className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">{modalTitle}</h3>
        <p className="modal-text text-center mb-0">{modalMessage}</p>
      </div>

      <div className="modal-footer d-flex flex-grow-1"></div>
    </div>
  );
}
