import JSZip from 'jszip';
import { useCallback, useState } from 'react';
import { CapturePosition, DentalMovementGroup, INJECTED_TYPES, Model3dType, container } from '../../common';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { IErrorHandlerService, IHttpService } from '../../shared';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useGetFiles } from '../shared';
import { useFiles } from '../useFiles';
import { Model } from '../../common/evergine/types';
import { useTranslation } from 'react-i18next';
import { isSaasApiEnabled } from '../../shared/settings';

export function useDentalMovements(caseId: string) {
  const [t] = useTranslation();
  const [showInfoPanel, setShowInfoPanel] = useState(false);
  const { getFileInfo } = useGetFiles(caseId);
  const { setLowerDentalMovements, setUpperDentalMovements } = useOrthBoundStore((state) => ({
    setLowerDentalMovements: state.setLowerDentalMovements,
    setUpperDentalMovements: state.setUpperDentalMovements
  }));
  const { isDir } = useFiles();
  const { setWebBusy } = useBoundStore((state) => ({ setWebBusy: state.setWebBusy }));
  const httpService = container.get<IHttpService>(INJECTED_TYPES.IHttpService);
  const errorHandlerService = container.get<IErrorHandlerService>(INJECTED_TYPES.IErrorHandlerService);

  const handleInfoPanelToggle = useCallback(() => {
    setShowInfoPanel(!showInfoPanel);
  }, [showInfoPanel, setShowInfoPanel]);

  const loadDentalMovements = useCallback(
    async (versionId: string, teetharchPosition: CapturePosition, dbMovements: DentalMovementGroup = null) => {
      if (!Module.FS) {
        return;
      }
      // const dentalMovementsInfo = await getFile(
      //   teetharchPosition === CapturePosition.UPPER ? MovementsFileEnum.Upper : MovementsFileEnum.Lower,
      //   versionId
      // );
      try {
        const dentalMovementsInfo = await getFileInfo('treatment-model', versionId);

        if (dentalMovementsInfo) {
          let fileMovements: DentalMovementGroup;
          const dynamicModels = 'DynamicModels';
          const dynamicModelsFullPath = '/Content/DynamicModels';

          if (!isDir(dynamicModelsFullPath)) {
            Module.FS.mkdir(dynamicModelsFullPath);
          }

          let headers: HeadersInit = {};

          if (isSaasApiEnabled()) {
            if (sessionStorage.getItem('authToken')) {
              headers = {
                Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
              };
            } else {
              headers = {
                'X-Public-Access-Token': sessionStorage.getItem('publicAccessToken')
              };
            }
          }

          const res = await fetch(dentalMovementsInfo.url, {
            method: 'GET',
            headers: headers
          });
          const arrayBufferData = await res.arrayBuffer();

          const unzipper = new JSZip();
          const zipData = await unzipper.loadAsync(arrayBufferData);

          const promises: Promise<void>[] = [];

          zipData.forEach(async (_, zipEntry) => {
            if (!zipEntry.dir) {
              if (zipEntry.name.endsWith('json')) {
                const fileData = await zipEntry.async('string');
                fileMovements = JSON.parse(fileData) as DentalMovementGroup;
              } else {
                promises.push(
                  (async () => {
                    const fileData = await zipEntry.async('uint8array');
                    const binData = new Int8Array(fileData);
                    const fileFullPath = `${dynamicModelsFullPath}/${zipEntry.name}`;
                    const filePath = `${dynamicModels}/${zipEntry.name}`;
                    await Module.FS.writeFile(fileFullPath, binData);
                  })()
                );
              }
            }
          });

          await Promise.all(promises);

          const filesObject = (await Module.FS.open(dynamicModelsFullPath)).node.contents;
          const files = Object.values(filesObject) as File[];

          const modelFiles: Model[] = [];

          files.forEach((file) => {
            const filePath = `${dynamicModels}/${file.name}`;
            modelFiles.push({
              id: file.name.replace('.wepmd', ''),
              uri: filePath,
              teethArch: teetharchPosition,
              model3dType: file.name.startsWith('tooth') ? Model3dType.Tooth : Model3dType.Gum
            });
          });

          await window.App.webEventsProxy.common.loadModels(modelFiles);

          const movements = dbMovements ? dbMovements : fileMovements;

          if (teetharchPosition === CapturePosition.UPPER) {
            setUpperDentalMovements(movements);
          } else {
            setLowerDentalMovements(movements);
          }
        }
      } catch (error) {
        errorHandlerService.showError(t('errors.serverError'));
        console.error(error);
      }
    },
    [getFileInfo, isDir, setUpperDentalMovements, setLowerDentalMovements, errorHandlerService, t]
  );

  const loadMovements = useCallback(
    (filePath: string, isUpper: boolean) => {
      const movementsFile = Module.FS.readFile(filePath);
      const movementsFileString = getStringFrom(movementsFile);
      const movements = JSON.parse(movementsFileString) as DentalMovementGroup;
      if (isUpper) {
        setUpperDentalMovements(movements);
      } else {
        setLowerDentalMovements(movements);
      }
    },
    [setLowerDentalMovements, setUpperDentalMovements]
  );

  const loadLegacyModels = useCallback(
    async (versionId: string) => {
      setWebBusy(true);
      try {
        const dentalMovementsInfo = await getFileInfo('treatment-model', versionId);
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        };
        const model = await fetch(dentalMovementsInfo.url, { method: 'GET', headers: headers });

        const fileBlob = model.blob();

        const casePath = 'Case'; // TODO: cambiar la ruta por otra que os convenga más
        const caseInputFullPath = `/Content/${casePath}`;
        const upperMovementsFileName = 'upperMovements.json';
        const lowerMovementsFileName = 'lowerMovements.json';

        if (!isDir(caseInputFullPath)) {
          Module.FS.mkdir(caseInputFullPath);
        }

        const unzipper = new JSZip();
        const zipData = await unzipper.loadAsync(fileBlob);
        console.log(`Extracting ${Object.keys(zipData.files).length} files`);

        const promises: Promise<void>[] = [];
        zipData.forEach((_, zipEntry) => {
          const fullPath = `${caseInputFullPath}/${zipEntry.name}`;
          promises.push(
            zipEntry.async('uint8array').then((fileData) => {
              const binData = new Int8Array(fileData);
              try {
                if (isDirZip(zipEntry.name)) {
                  const dirName = `${caseInputFullPath}/${getDirName(zipEntry.name)}`;
                  if (!isDir(dirName)) {
                    Module.FS.mkdir(dirName);
                  }
                }
                if (!isDir(fullPath)) {
                  Module.FS.writeFile(fullPath, binData);
                }
              } catch (error) {
                console.error(`Error writing to the file "${fullPath}":`, error);
              }
            })
          );
        });

        await Promise.all(promises);

        const filesObject = (await Module.FS.open(caseInputFullPath)).node.contents;
        const files = Object.values(filesObject) as File[];
        const modelFiles: Model[] = [];

        files.forEach((file) => {
          if (!file.name.endsWith('json')) {
            const filePath = `${caseInputFullPath}/${file.name}`;
            modelFiles.push({
              id: file.name.replace('.wepmd', ''),
              uri: filePath,
              teethArch: CapturePosition.LOWER,
              model3dType: file.name.startsWith('ctooth') ? Model3dType.Tooth : Model3dType.Gum
            });
          }
        });

        await window.App.webEventsProxy.common.loadModels(modelFiles);
        loadMovements(`${caseInputFullPath}/${upperMovementsFileName}`, true);
        setTimeout(() => {
          loadMovements(`${caseInputFullPath}/${lowerMovementsFileName}`, false);
          setWebBusy(false);
        });
      } catch (error) {
        errorHandlerService.showError(t('errors.serverError'));
        console.error(error);
      }
    },
    [errorHandlerService, getFileInfo, isDir, loadMovements, setWebBusy, t]
  );

  const getStringFrom = (data: ArrayBuffer) => {
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(data);
  };

  const isDirZip = (path: string) => {
    return path.split('/').length > 1;
  };

  const getDirName = (path: string) => {
    return path.split('/')[0];
  };

  return { showInfoPanel, handleInfoPanelToggle, loadDentalMovements, loadLegacyModels };
}
