import { useMemo } from 'react';
import { ReactComponent as Warning } from '../../../../assets/icons/modal/warning.svg';
import { useOrthBoundStore } from '../../../../orthodontics/stores/useStore';
import { Odontogram } from '../../../../surgeries/components';
import { findArchPositionByFdi } from '../../../../surgeries/components/odontogramTeethArch/odontogramTeethArchUtils';
import './modalConfirmEnumeration.scss';

interface ModalConfirmEnumerationProps {
  title: string;
  yesText: string;
  noText: string;
  from: string;
  onClickClose: () => void;
  onClickContinue: () => void;
}

export function ModalConfirmEnumeration({
  title,
  yesText,
  noText,
  from,
  onClickClose,
  onClickContinue
}: ModalConfirmEnumerationProps) {
  const fromToPieces = useOrthBoundStore((store) => store.fromToPieces);

  const getFromAndToPieces = useMemo(() => {
    return {
      fromPiece: fromToPieces[0],
      toPiece: fromToPieces[1]
    };
  }, [fromToPieces]);

  return (
    <div data-testid="modal" className="modal modal-opened modal-confirm-numeration">
      <div data-testid="modal-container" className={`modal-dialog modal-dialog-centered`}>
        <div className="modal-content">
          <div className="modal-body d-flex flex-column align-items-center text-center">
            <Warning className="modal-icon mb-3" />
            <h3 className="modal-title mb-4">{title}</h3>
            <span className="text-modal">Ya existe un elemento con esta numeración. que quieres hacer?</span>

            <Odontogram
              archPosition={findArchPositionByFdi(from)}
              showToggleNumbers={true}
              selectedPieces={[from]}
              toRenumeration={getFromAndToPieces.toPiece}
            />
          </div>

          <div className="modal-footer">
            <button
              onClick={onClickClose}
              type="button"
              className="btn btn-outline-primary modal-button"
              data-bs-dismiss="modal"
            >
              {noText}
            </button>
            <button onClick={onClickContinue} type="button" className="btn btn-dark modal-button">
              {yesText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
