import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { AttachmentType, IconTextInfo } from '../../../../../../common';
import { useUtils } from '../../../../../../hooks/shared/useUtils';
import { useOrthBoundStore } from '../../../../../stores/useStore';
import { useAttaches, useDentalMovementsSnapshot } from '../../../../../../hooks';
import { useCommonBoundStore } from '../../../../../../common/stores/useStore';
import { ModalTypes } from '../../../../../../surgeries/components/layout';
import { useShallow } from 'zustand/react/shallow';

import './attacheModel.scss';
import { isTadType } from '../../tads';
import { isAttacheType } from '../attacheTypes';

interface AttacheModelProps {
  model: IconTextInfo;
}

export function AttacheModel({ model }: AttacheModelProps) {
  const {
    selectedTeethId,
    attachActive,
    setAttachActive,
    selectedAttache,
    setAttachementTypeToBeAdded,
    addingAttache,
    setAddingAttache
  } = useOrthBoundStore(
    useShallow((state) => ({
      selectedTeethId: state.selectedTeethId,
      attachActive: state.attachActive,
      setAttachActive: state.setAttachActive,
      selectedAttache: state.selectedAttache,
      setAttachementTypeToBeAdded: state.setAttachementTypeToBeAdded,
      addingAttache: state.addingAttache,
      setAddingAttache: state.setAddingAttache
    }))
  );
  const toggleModalIsOpened = useCommonBoundStore(useShallow((state) => state.toggleModalIsOpened));

  const [t] = useTranslation();
  const { updateMovements } = useUtils();
  const { noTeethSelected, inFirstStep, noAttacheSelected } = useAttaches();
  const { updateDentalMovementsSnapshot } = useDentalMovementsSnapshot();

  useEffect(() => {
    setAddingAttache(null);
  }, [selectedTeethId, selectedAttache]);

  const modalForAttachmentType = (type: AttachmentType) => {
    if (isTadType(type)) {
      return ModalTypes.ModalAddTAD;
    }

    if (isAttacheType(type)) {
      return ModalTypes.ModalAddAttach;
    }

    return ModalTypes.ModalAddLabel;
  };

  const updateMovementsAndSnapshot = () => {
    updateMovements();
    updateDentalMovementsSnapshot();
  };

  const handleAttach = (type: AttachmentType) => {
    setAttachementTypeToBeAdded(type);
    setAttachActive(type);

    if (selectedTeethId.length > 0 && !selectedAttache && !addingAttache) {
      toggleModalIsOpened(modalForAttachmentType(type));
    } else if (selectedAttache && !!selectedAttache.id) {
      window.App.webEventsProxy.attachments.changeAttachmentType(selectedAttache.id, type);
      updateMovementsAndSnapshot();
    } else if (addingAttache) {
      window.App.webEventsProxy.attachments.changeAttachmentType(addingAttache.id, type);
      updateMovementsAndSnapshot();
    }
  };

  const isActive = useMemo(() => {
    if (attachActive === model.typeAtt) {
      return true;
    }
    return false;
  }, [attachActive]);

  const mustDisable = useCallback(() => {
    return (noTeethSelected && noAttacheSelected) || inFirstStep;
  }, [noTeethSelected, noAttacheSelected, inFirstStep]);

  return (
    <div className="attachemodel" key={model.id}>
      <button
        data-for={model.id}
        data-tip
        id={model.id}
        disabled={mustDisable()}
        className={`etoolbar-button clickable ${isActive ? 'is-active' : ''}`}
        onClick={() => handleAttach(model.typeAtt)}
      >
        <div>{<model.icon />}</div>
        <ReactTooltip id={model.id} place="top" effect="solid" className="tooltip" type="dark">
          {t(model.text)}
        </ReactTooltip>
      </button>
    </div>
  );
}
