import { createWithEqualityFn } from 'zustand/traditional';
import {
  BaseCaptureSlice,
  CaseDataSlice,
  ModalSlice,
  SettingsSlice,
  TimelineSlice,
  TouchDeviceSlice,
  createBaseCaptureSlice,
  createCaseDataSlice,
  createModalSlice,
  createSettingsSlice,
  createTimelineSlice,
  createTouchDeviceSlice,
  DesignerSettingsSlice,
  createDesignerSettingsSlice
} from './slices';
import { createValidateTreatmentSlice, ValidateTreatmentSlice } from './slices/validateTreatmentSlice';
import { shallow } from 'zustand/shallow';

/* eslint-disable */
export type CommonBoundState = ModalSlice &
  TouchDeviceSlice &
  SettingsSlice &
  CaseDataSlice &
  TimelineSlice &
  BaseCaptureSlice &
  DesignerSettingsSlice &
  ValidateTreatmentSlice;
/* eslint-enable*/

export const useCommonBoundStore = createWithEqualityFn<CommonBoundState>()(
  (...a) => ({
    ...createModalSlice(...a),
    ...createTouchDeviceSlice(...a),
    ...createSettingsSlice(...a),
    ...createCaseDataSlice(...a),
    ...createTimelineSlice(...a),
    ...createBaseCaptureSlice(...a),
    ...createDesignerSettingsSlice(...a),
    ...createValidateTreatmentSlice(...a)
  }),
  shallow
);
