import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Redo from '../../../../../assets/icons/redo.svg';
import { INJECTED_TYPES, NavBarAction, container } from '../../../../../common';
import { ICommandInvoker } from '../../../../../shared';
import { useOrthBoundStore } from '../../../../stores/useStore';

export default (actionProps: any) => {
  const [t] = useTranslation();
  const canRedo = useOrthBoundStore((store) => store.canRedo);
  const commandInvokerService = container.get<ICommandInvoker>(INJECTED_TYPES.ICommandInvokerService);
  const [isRedoing, setIsRedoing] = useState(false);

  const handleRedoClick = useCallback(() => {
    invokeRedo();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && (event.key === 'y' || event.key === 'Y')) {
        if (canRedo && !isRedoing) {
          invokeRedo();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [canRedo]);

  const invokeRedo = useCallback(async () => {
    if (!isRedoing) {
      setIsRedoing(true);
      try {
        await commandInvokerService.redo();
      } finally {
        setIsRedoing(false);
      }
    }
  }, [commandInvokerService, isRedoing]);

  const buttonId = 'redo';

  return (
    <>
      <NavBarAction
        icon={Redo}
        dataFor={buttonId}
        altText={t('navbar.actions.redo')}
        action={handleRedoClick}
        disabled={!canRedo || isRedoing}
        isEndOfBlock={actionProps.block.isEndOfBlock}
      />
      <ReactTooltip id={buttonId} place="bottom" effect="solid" className="tooltip" type="dark">
        {t('navbar.actions.redo')}
      </ReactTooltip>
    </>
  );
};
