export enum BaseRoutes {
  Surgery = 'surgery',
  Orthodontics = 'orthodontics'
}

export enum CommonPagesUrl {
  GeneralInfo = '/general-information',
  SpecificInfo = '/specific-information',
  DicomCaptures = '/dicom-captures',
  StlCaptures = '/stl-captures',
  PhotoCaptures = '/photo-captures',
  Forbidden = '/forbidden',
  NotFound = '/not-found',
  Incompatible = '/incompatible'
}

export enum OrthodonticsPagesUrl {
  DesignerEntryPoint = '/designer',
  MeshCorrection = '/mesh-correction',
  ImportMaster3D = '/import-master-3d',
  DentalMovements = '/dental-movements',
  TeethSegmentation = '/teeth-segmentation',
  AxisAndRoots = '/axis-roots',
  Treatment = '/treatment',
  Publish = '/publish',
  StlEdition = '/stl-edition'
}
