import { createWithEqualityFn } from 'zustand/traditional';
import { CamViewSlice, createCamViewSlice } from './slices/camViewSlice';
import { CanvasSlice, createCanvasSlice } from './slices/canvasSlice';
import { CaseDataSlice, createCaseDataSlice } from './slices/caseDataSlice';
import { ChatSlice, createChatSlice } from './slices/chatSlice';
import { ConfirmDataSlice, createConfirmDataSlice } from './slices/confirmDataSlice';
import { MatchingSlice, createMatchingSlice } from './slices/matchingSlice';
import { UserProfileSlice, createProfileSlice } from './slices/profileSlice';
import { SpinnerSlice, createSpinnerSlice } from './slices/spinnerSlice';
import { SplitScreenSlice, createSplitScreenSlice } from './slices/splitScreenSlice';
import { TeethArchSlice, createTeethArchSlice } from './slices/teethArch';
import { WasmSlice, createWasmSlice } from './slices/wasmSlice';
import { MessageInfoSlice, createMessageInfoSlice } from './slices/messageInfoSlice';
import { shallow } from 'zustand/shallow';

/* eslint-disable */
export type SurgeriesBoundState = WasmSlice &
  CaseDataSlice &
  UserProfileSlice &
  SplitScreenSlice &
  TeethArchSlice &
  ConfirmDataSlice &
  CanvasSlice &
  MatchingSlice &
  CamViewSlice &
  SpinnerSlice &
  ChatSlice &
  MessageInfoSlice;
/* eslint-enable*/

export const useBoundStore = createWithEqualityFn<SurgeriesBoundState>()(
  (...a) => ({
    ...createWasmSlice(...a),
    ...createCaseDataSlice(...a),
    ...createProfileSlice(...a),
    ...createSplitScreenSlice(...a),
    ...createTeethArchSlice(...a),
    ...createMatchingSlice(...a),
    ...createCanvasSlice(...a),
    ...createConfirmDataSlice(...a),
    ...createCamViewSlice(...a),
    ...createSpinnerSlice(...a),
    ...createChatSlice(...a),
    ...createMessageInfoSlice(...a)
  }),
  shallow
);
