import { useCallback } from 'react';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useGetDentalMovements } from './useGetDentalMovements';
import { OrthodonticsOrderedPhasesKeys } from '../../orthodontics/components/layout';
import { CasePhase } from '../../shared/caseStatus';
import { editCasePhase } from '../../common/utils';
import { CaseVersionState } from '../../shared';
import { useShallow } from 'zustand/react/shallow';

export function usePublishCase() {
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const { currentVersion, isCasePublished, setIsCasePublished } = useOrthBoundStore(
    useShallow((state) => ({
      currentVersion: state.currentVersion,
      isCasePublished: state.isCasePublished,
      setIsCasePublished: state.setIsCasePublished
    }))
  );

  const { updateTreatmentState } = useGetDentalMovements();

  const publishCase = useCallback(async () => {
    setWebBusy(true);
    await updateTreatmentState(currentVersion.caseId, currentVersion, CaseVersionState.published);
    await updatePhaseState();
    setIsCasePublished(true);
    setWebBusy(false);
  }, [currentVersion]);

  const updatePhaseState = useCallback(async () => {
    const buildPhase: CasePhase = {
      id: OrthodonticsOrderedPhasesKeys.Publish,
      name: OrthodonticsOrderedPhasesKeys.Publish,
      completionDate: new Date(),
      editionDate: new Date()
    };
    const versionId = currentVersion.id.toString();
    const caseId = currentVersion.caseId;

    await editCasePhase(caseId, versionId, buildPhase);
  }, [currentVersion, isCasePublished]);

  return { publishCase };
}
