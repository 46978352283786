import { ApplyIprState, DentalMovementGroup, IprLabel } from '../../../../common';
import { EvolutionInterdentalDistance, EvolutionStep } from '../';

export type QuadrantItem = {
  start: number;
  isAsc: boolean;
};

export type DraggingBrickToothInfo = {
  fdi: number;
  stepIndex: number;
};

export type SelectedIprType = {
  stepDistance: EvolutionInterdentalDistance;
  stepIndex: number;
};

export interface QuadrantRowsProps {
  applyIPRList: IprLabel[];
  isKeyShiftDown: boolean;
  steps: EvolutionStep[];
  setIsKeyShiftDown: (isShiftDown: boolean) => void;
  updateIPRList: (applyIPRList: IprLabel[]) => void;
  onInit: () => void;
}

export interface AdjacentBricks {
  leftAdjacentBricks: HTMLElement[];
  draggedBrick: HTMLElement;
  rightAdjacentBricks: HTMLElement[];
}

export interface DragEventData {
  adjacentBricksToDraggingBrick: DraggingBrickToothInfo[];
  draggingBrick: DraggingBrickToothInfo;
}

export interface DragBlockEventData {
  adjacentBrickBlocks: DragEventData[];
  draggingBrickBlock: DragEventData;
}

export interface MovementInfo {
  upperMovements: DentalMovementGroup;
  lowerMovements: DentalMovementGroup;
  stepsToMove: number;
  adjacentBricksToDraggingBrick: DraggingBrickToothInfo[];
}

export function getClassNameForIpr(applyIprState: ApplyIprState, iprDistance: number) {
  let returnClass = '';

  switch (applyIprState) {
    case ApplyIprState.None:
      break;
    case ApplyIprState.PreIpr:
      returnClass = 'is-past';
      break;
    case ApplyIprState.ApplyIpr:
      returnClass = 'is-active';
      break;
    case ApplyIprState.PostIpr:
      returnClass = 'is-future';
      break;
    default:
      break;
  }

  if (iprDistance > 0.5) {
    returnClass += ' is-over';
  }

  return returnClass;
}

export const getStartEndPiecesForQuadrant = (quadrantNumber: number) => {
  const quadrantStartEndPieces: Record<number, { start: number; end: number }> = {
    1: {
      start: 18,
      end: 11
    },
    2: {
      start: 21,
      end: 28
    },
    3: {
      start: 38,
      end: 31
    },
    4: {
      start: 41,
      end: 48
    }
  };

  return quadrantStartEndPieces[quadrantNumber];
};

export const getAttributeInt = (element: HTMLElement, attr: string) => parseInt(element.getAttribute(attr) || '', 10);

export const isDraggingFromOtherRow = (currentStepRow: number, rowNumber: number) => currentStepRow !== rowNumber;

export const isDirectionChange = (isDraggingLeft: boolean, currentStepIndex: number, stepIndex: number) => {
  if (!isDraggingLeft) return currentStepIndex < stepIndex;
  return currentStepIndex > stepIndex;
};

export const getRowBricks = (rowNumber: number) =>
  Array.from(document.querySelectorAll(`.toothinfobrick[data-row="${rowNumber}"]`));

export const getCurrentTargetWithOffset = (rowBricks: Element[], currentTargetIndex: number, offsetIndex: number) =>
  rowBricks[currentTargetIndex + offsetIndex];

export const isElementInArray = (array: HTMLElement[], element: any) => array.some((b) => b === element);

export const getNewAdjacentBricks = (currentTargetWithOffset: Element, rowNumber: number, draggedBrickClass: string) =>
  getAdjacentBricks(currentTargetWithOffset as HTMLDivElement, rowNumber, draggedBrickClass);

export const sortBricksByColumn = (bricks: HTMLElement[]) =>
  bricks.sort((a, b) => getAttributeInt(a, 'data-column') - getAttributeInt(b, 'data-column'));

export const addBrickToContainer = (
  brick: HTMLDivElement,
  container: HTMLDivElement,
  isDraggingLeft: boolean,
  allBricksRef: any
) => {
  const childrenArray = Array.from(container.children);
  if (!childrenArray.some((child) => child.id === brick.id)) {
    if (isDraggingLeft) {
      allBricksRef.leftAdjacentBricks.push(brick);
      container.insertBefore(brick, container.firstChild);
    } else {
      allBricksRef.rightAdjacentBricks.push(brick);
      container.appendChild(brick);
    }
  }
};

export const getElementPosition = (element: HTMLDivElement) => element.getBoundingClientRect();

export const createDragImageContainer = () => {
  const dragImageContainer = document.createElement('div');
  dragImageContainer.id = `dragImage_${Date.now()}`;
  dragImageContainer.style.display = 'flex';
  dragImageContainer.style.position = 'absolute';
  dragImageContainer.style.top = '-9999px';
  dragImageContainer.style.left = '-9999px';
  dragImageContainer.style.zIndex = '10';
  dragImageContainer.style.gap = '3px';
  document.body.appendChild(dragImageContainer);
  return dragImageContainer;
};

export const setDragImage = (e: React.DragEvent<HTMLDivElement>, posX: number, posY: number) => {
  const dummyImage = new Image();
  e.dataTransfer.setDragImage(dummyImage, posX, posY);
};

export const sortAdjacentBricks = (bricks: HTMLElement[]) =>
  bricks.sort((a, b) => getAttributeInt(a, 'data-column') - getAttributeInt(b, 'data-column'));

export const isDragEventValid = (e: any) => e.clientX !== 0 || e.clientY !== 0;

export const setDraggingDirection = (clientX: number, startX: number) => clientX < startX;

export const updateFilterBricksRef = (isDraggingLeft: boolean, allBricksRef: AdjacentBricks) => {
  return isDraggingLeft
    ? [...allBricksRef.leftAdjacentBricks, allBricksRef.draggedBrick]
    : [allBricksRef.draggedBrick, ...allBricksRef.rightAdjacentBricks];
};

export const appendBricksToContainer = (
  bricks: HTMLElement[],
  container: HTMLDivElement,
  draggedBrickClass: string
) => {
  bricks.forEach((brick) => {
    const newBrick = document.createElement('div');
    newBrick.id = `brick_${brick.getAttribute('data-row') || ''}${brick.getAttribute('data-column') || ''}`;
    newBrick.classList.add('toothinfobrick', draggedBrickClass);
    container.appendChild(newBrick);
  });
};

export const getAdjacentBricks = (element: HTMLDivElement, rowNumber: number, className: string): AdjacentBricks => {
  const rowBricks = document.querySelectorAll(`.toothinfobrick[data-row="${rowNumber}"]`);
  const draggedBrick = element;
  const draggedBrickClass = Array.from(draggedBrick.classList).filter((c: string) => c !== 'toothinfobrick')[0];

  // Find the index of the dragged element
  const draggedBrickIndex = Array.from(rowBricks).indexOf(draggedBrick);

  const leftAdjacentBricks: HTMLElement[] = [];
  for (let i = draggedBrickIndex - 1; i >= 0; i--) {
    const brick = rowBricks[i] as HTMLElement;
    if (brick.classList.contains(draggedBrickClass)) {
      leftAdjacentBricks.push(brick);
    } else {
      break;
    }
  }

  const rightAdjacentBricks: HTMLElement[] = [];
  for (let i = draggedBrickIndex + 1; i < rowBricks.length; i++) {
    const brick = rowBricks[i] as HTMLElement;
    if (brick.classList.contains(draggedBrickClass)) {
      rightAdjacentBricks.push(brick);
    } else {
      break;
    }
  }

  return { leftAdjacentBricks, draggedBrick, rightAdjacentBricks };
};
