import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useOrthBoundStore } from '../../../stores/useStore';
import { EvolutionStep } from '..';
import { IprLabel } from '../../../../common/evergine';
import { useCommonBoundStore } from '../../../../common/stores/useStore';
import { useHandleEventListener, useWindowSize } from '../../../../hooks';

import './evolutionTable.scss';
import { useShallow } from 'zustand/react/shallow';
import QuadrantRows from './QuadrantRows';
import { usePiecesRows } from '../../../../hooks/shared/usePiecesRows';
import EvolutionTableHeader from './EvolutionTableHeader';

interface EvolutionTableProps {
  steps: EvolutionStep[];
  applyIPRList: IprLabel[];
  updateIPRList: (applyIPRList: IprLabel[]) => void;
  opened: boolean;
}

const movementTableWidthInPixels = 297.56;
const headerStepId = 'evolutiontable-step-';
const columnElementClass = 'evolutiontable-piece';
const columnBeforeElementClass = 'evolutiontable-piece-number';
const defaultColumnWidth = 22; // Default value 22px
const defaultColumnforeWidth = 48; // Default value 48px

function EvolutionTable({ steps, applyIPRList, updateIPRList, opened }: EvolutionTableProps) {
  const {
    showEvolutionPanel,
    expandEvolutionPanel,
    currentSliceSteps,
    setCurrentSliceSteps,
    setCurrentSliceRange,
    setFitColumnsInPanel,
    setIsEvolutionPanelSliced,
    showMovementsTable
  } = useOrthBoundStore(
    useShallow((state) => ({
      showEvolutionPanel: state.showEvolutionPanel,
      expandEvolutionPanel: state.expandEvolutionPanel,
      currentSliceSteps: state.currentSliceSteps,
      setCurrentSliceSteps: state.setCurrentSliceSteps,
      setCurrentSliceRange: state.setCurrentSliceRange,
      setFitColumnsInPanel: state.setFitColumnsInPanel,
      setIsEvolutionPanelSliced: state.setIsEvolutionPanelSliced,
      showMovementsTable: state.showMovementsTable
    }))
  );

  const { activeStep } = useCommonBoundStore(
    useShallow((state) => ({
      activeStep: state.activeStep
    }))
  );

  const { EventNames } = useHandleEventListener();
  const windowsSize = useWindowSize();
  const { piecesRef, defaultActiveStepStyle, isBodyScrollVisible } = usePiecesRows(
    activeStep,
    currentSliceSteps,
    headerStepId,
    showEvolutionPanel
  );

  const [columnWidth, setColumnWidth] = useState<number>(defaultColumnWidth);
  const [columnBeforeWidth, setColumnBeforeWidth] = useState<number>(defaultColumnforeWidth);

  const [isKeyShiftDown, setIsKeyShiftDown] = useState<boolean>(false);

  const calculateColumnWidth = useCallback(() => {
    if (steps?.length === 0) {
      return;
    }
    const widthEl = document.getElementsByClassName(columnElementClass);
    const width = widthEl[0]?.clientWidth || 0;
    const margin = widthEl[0] ? parseFloat(getComputedStyle(widthEl[0]).marginRight) : 0;
    setColumnWidth(width + margin);
  }, [steps]);

  const calculateColumnBeforeWidth = useCallback(() => {
    if (steps?.length === 0) {
      return;
    }
    const widthEl = document.getElementsByClassName(columnBeforeElementClass);
    const width = widthEl[0]?.clientWidth || 0;
    const margin = widthEl[0] ? parseFloat(getComputedStyle(widthEl[0]).marginRight) : 0;
    setColumnBeforeWidth(width + margin);
  }, [steps]);

  useEffect(() => {
    // Calculate 50% of the width of the screen to fit the steps
    if (steps?.length === 0) {
      return;
    }
    // If movement table is visible, space is 50% - movement table width
    const movementsTableWidth = showMovementsTable ? movementTableWidthInPixels + columnBeforeWidth : 0;
    const availableWidth = expandEvolutionPanel
      ? windowsSize.width
      : windowsSize.width / 2 - columnBeforeWidth - movementsTableWidth;
    const maxVisibleColumns = columnWidth ? Math.floor(availableWidth / columnWidth) : steps.length;
    setFitColumnsInPanel(maxVisibleColumns);

    // If panel should be sliced, the calculation will do by EvolutionPanelNavigator component
    if (maxVisibleColumns > steps.length) {
      const halfFitRows = Math.floor(maxVisibleColumns / 2);

      let from = Math.max(0, activeStep - halfFitRows);
      let to = from + maxVisibleColumns;

      if (to > steps.length) {
        to = steps.length;
        from = Math.max(0, to - maxVisibleColumns);
      }

      setCurrentSliceRange({ from, to });
      const sliceSteps = steps.slice(from, to);
      setCurrentSliceSteps(sliceSteps);
      setIsEvolutionPanelSliced(sliceSteps.length !== steps.length);
    }
  }, [steps, windowsSize, columnWidth, expandEvolutionPanel, showMovementsTable]);

  const calculateWidths = useCallback(() => {
    calculateColumnWidth();
    calculateColumnBeforeWidth();
  }, [calculateColumnBeforeWidth, calculateColumnWidth]);

  const handleKeyDown = useCallback((event: Event) => {
    const keyboardEvent = event as KeyboardEvent;
    if (keyboardEvent.shiftKey) {
      setIsKeyShiftDown(true);
    }
  }, []);

  const handleKeyUp = useCallback((event: Event) => {
    const keyboardEvent = event as KeyboardEvent;
    if (!keyboardEvent.shiftKey) {
      setIsKeyShiftDown(false);
    }
  }, []);

  // The following event handlers are just to manage the funny behaviour of shift keyUp event not being captured properly
  const handleWindowBlur = useCallback(() => {
    setIsKeyShiftDown(false);
  }, []);

  const handleVisibilityChange = useCallback(() => {
    if (document.hidden) {
      setIsKeyShiftDown(false);
    }
  }, []);

  const handleContextMenu = useCallback(() => {
    setIsKeyShiftDown(false);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsKeyShiftDown(false);
  }, []);

  useEffect(() => {
    if (showEvolutionPanel) {
      window.addEventListener(EventNames.Keydown, handleKeyDown);
      window.addEventListener(EventNames.Keyup, handleKeyUp);
      window.addEventListener(EventNames.Blur, handleWindowBlur);
      document.addEventListener(EventNames.VisibilityChange, handleVisibilityChange);
      window.addEventListener(EventNames.ContextMenu, handleContextMenu);
      window.addEventListener(EventNames.MouseUp, handleMouseUp);
    } else {
      window.removeEventListener(EventNames.Keydown, handleKeyDown);
      window.removeEventListener(EventNames.Keyup, handleKeyUp);
      window.removeEventListener(EventNames.Blur, handleWindowBlur);
      document.removeEventListener(EventNames.VisibilityChange, handleVisibilityChange);
      window.removeEventListener(EventNames.ContextMenu, handleContextMenu);
      window.removeEventListener(EventNames.MouseUp, handleMouseUp);
    }

    // Cleanup function to remove listeners if the component unmounts
    return () => {
      window.removeEventListener(EventNames.Keydown, handleKeyDown);
      window.removeEventListener(EventNames.Keyup, handleKeyUp);
      window.removeEventListener(EventNames.Blur, handleWindowBlur);
      document.removeEventListener(EventNames.VisibilityChange, handleVisibilityChange);
      window.removeEventListener(EventNames.ContextMenu, handleContextMenu);
      window.removeEventListener(EventNames.MouseUp, handleMouseUp);
    };
  }, [
    showEvolutionPanel,
    handleKeyDown,
    handleKeyUp,
    handleWindowBlur,
    handleVisibilityChange,
    handleContextMenu,
    EventNames.Keydown,
    EventNames.Keyup,
    EventNames.Blur,
    EventNames.VisibilityChange,
    EventNames.ContextMenu,
    EventNames.MouseUp,
    handleMouseUp
  ]);

  const MemoizedQuadrantRows = React.memo(QuadrantRows, (prevProps, nextProps) => {
    return (
      prevProps.steps === nextProps.steps &&
      prevProps.isKeyShiftDown === nextProps.isKeyShiftDown &&
      prevProps.applyIPRList === nextProps.applyIPRList &&
      prevProps.updateIPRList === nextProps.updateIPRList
    );
  });

  const EvolutionTableBody = ({
    piecesRef,
    isKeyShiftDown,
    currentSliceSteps,
    updateIPRList,
    applyIPRList,
    setIsKeyShiftDown,
    calculateWidths,
    opened
  }: {
    piecesRef: React.RefObject<HTMLDivElement>;
    isKeyShiftDown: boolean;
    currentSliceSteps: EvolutionStep[];
    updateIPRList: (applyIPRList: IprLabel[]) => void;
    applyIPRList: IprLabel[];
    setIsKeyShiftDown: (isKeyShiftDown: boolean) => void;
    calculateWidths: () => void;
    opened: boolean;
  }) => (
    <div className="evolutiontable-body" id="evolutiontable-body" style={defaultActiveStepStyle} ref={piecesRef}>
      {opened && (
        <div className="evolutiontable-content">
          <div>
            <MemoizedQuadrantRows
              isKeyShiftDown={isKeyShiftDown}
              steps={currentSliceSteps}
              updateIPRList={updateIPRList}
              applyIPRList={applyIPRList}
              setIsKeyShiftDown={setIsKeyShiftDown}
              onInit={calculateWidths}
            />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="evolutiontable">
      <EvolutionTableHeader
        isBodyScrollVisible={isBodyScrollVisible}
        currentSliceSteps={currentSliceSteps}
        headerStepId={headerStepId}
      />
      <EvolutionTableBody
        piecesRef={piecesRef}
        isKeyShiftDown={isKeyShiftDown}
        currentSliceSteps={currentSliceSteps}
        updateIPRList={updateIPRList}
        applyIPRList={applyIPRList}
        setIsKeyShiftDown={setIsKeyShiftDown}
        calculateWidths={calculateWidths}
        opened={opened}
      />
    </div>
  );
}

const shouldUpdate = (prevProps: EvolutionTableProps, nextProps: EvolutionTableProps) => {
  return (
    prevProps.steps === nextProps.steps &&
    prevProps.applyIPRList === nextProps.applyIPRList &&
    prevProps.updateIPRList === nextProps.updateIPRList
  );
};

export default React.memo(EvolutionTable, shouldUpdate);
