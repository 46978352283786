import { useShallow } from 'zustand/react/shallow';
import { useCommonBoundStore } from '../../common/stores/useStore';

export function useStepsManager() {
  const setActiveStep = useCommonBoundStore(useShallow((state) => state.setActiveStep));

  const goToStep = (stepIndex: number, isComparer: boolean) => {
    if (stepIndex !== undefined && !Number.isNaN(stepIndex)) {
      window.App.webEventsProxy.movements.goToStep(stepIndex, isComparer);
      setActiveStep(stepIndex);
    }
  };

  const goToTemplate = (stepIndex: number, isComparer: boolean) => {
    if (stepIndex === undefined || Number.isNaN(stepIndex)) return;
    window.App.webEventsProxy.movements.goToTemplate(stepIndex, isComparer);
    setActiveStep(stepIndex);
  };

  return { goToStep, goToTemplate };
}
