import { useCallback } from 'react';
import { CapturePosition, MatchingInfo, Model, Model3dType, ScanOrientation } from '../../common/evergine/types';
import { CaseFile, PatientCase, isStringVoid as isStringNullOrEmpty } from '../../shared';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useFiles } from '../useFiles';
import { useFetchModelFiles } from './useFetchModelFiles';
import JSZip from 'jszip';
import { useGetFiles } from './useGetFiles';
import { useCaseId } from './useCaseId';
import { EDITED_LOWER_MATRIX_FILE_KEY, EDITED_UPPER_MATRIX_FILE_KEY } from '../../orthodontics/components';

export function useLoadFilesIntoFS() {
  const dynamicModels = 'DynamicModels';
  const dynamicModelsFullPath = `/Content/${dynamicModels}`;
  const updateMatchingModelPoints = useBoundStore((state) => state.updateMatchingModelPoints);
  const { isDir, isFile } = useFiles();
  const { fetchNonCachedModelFile } = useFetchModelFiles();
  const [caseId] = useCaseId();
  const { getFile } = useGetFiles(caseId);

  const createModelsDirectoryIfNotExistent = useCallback(() => {
    if (!isDir(dynamicModelsFullPath)) {
      console.log('Creating directory ', dynamicModelsFullPath);
      Module.FS.mkdir(dynamicModelsFullPath);
    }
  }, [dynamicModelsFullPath, isDir]);

  const isFileInFS = useCallback(
    (fileName: string): boolean => {
      const fileNameFullPath = `${dynamicModelsFullPath}/${fileName}`;
      return isFile(fileNameFullPath);
    },
    [dynamicModelsFullPath, isFile]
  );

  const loadFileFromBackendInFS = useCallback(
    async (urlFromBackend: string, pathInFS: string) => {
      try {
        const res = await fetchNonCachedModelFile(urlFromBackend);
        const arrayBufferData = await res.arrayBuffer();
        const data = new Int8Array(arrayBufferData);
        createModelsDirectoryIfNotExistent();
        Module.FS.writeFile(pathInFS, data);
      } catch (error) {
        console.error(error);
      }
    },
    [createModelsDirectoryIfNotExistent, fetchNonCachedModelFile]
  );

  const loadUpperDicomInFS = useCallback(
    async (patientCase): Promise<void> => {
      if (patientCase.captures3D.upperDICOM.name && patientCase.captures3D.upperDICOM.url) {
        const fileName = `${patientCase.captures3D.upperDICOM.name}`;

        if (isFileInFS(fileName)) {
          return;
        }

        await loadFileFromBackendInFS(patientCase.captures3D.upperDICOM.url, `${dynamicModelsFullPath}/${fileName}`);
      }
    },
    [dynamicModelsFullPath, isFileInFS, loadFileFromBackendInFS]
  );

  const loadUpperModel3DInFS = useCallback(
    async (patientCase: PatientCase, modelInfo?: CaseFile): Promise<void> => {
      if ((patientCase.scannings.upperModel3D.name && patientCase.scannings.upperModel3D.url) || modelInfo) {
        const fileName = `${modelInfo ? modelInfo.name : patientCase.scannings.upperModel3D.name}`;

        if (isFileInFS(fileName)) {
          return;
        }

        await loadFileFromBackendInFS(
          modelInfo ? modelInfo.url : patientCase.scannings.upperModel3D.url,
          `${dynamicModelsFullPath}/${fileName}`
        );
      }
    },
    [dynamicModelsFullPath, isFileInFS, loadFileFromBackendInFS]
  );

  const loadModel3DInFS = useCallback(
    async (modelInfo?: CaseFile): Promise<void> => {
      if (modelInfo) {
        if (isFileInFS(modelInfo.name)) {
          return;
        }

        await loadFileFromBackendInFS(modelInfo.url, `${dynamicModelsFullPath}/${modelInfo.name}`);
      }
    },
    [dynamicModelsFullPath, isFileInFS, loadFileFromBackendInFS]
  );

  const loadLowerDicomInFS = useCallback(
    async (patientCase: PatientCase): Promise<void> => {
      if (patientCase.captures3D.lowerDICOM.name && patientCase.captures3D.lowerDICOM.url) {
        const fileName = `${patientCase.captures3D.lowerDICOM.name}`;

        if (isFileInFS(fileName)) {
          return;
        }

        await loadFileFromBackendInFS(patientCase.captures3D.lowerDICOM.url, `${dynamicModelsFullPath}/${fileName}`);
      }
    },
    [dynamicModelsFullPath, isFileInFS, loadFileFromBackendInFS]
  );

  const loadLowerModel3DInFS = useCallback(
    async (patientCase: PatientCase, modelInfo?: CaseFile): Promise<void> => {
      if ((patientCase.scannings.lowerModel3D.name && patientCase.scannings.lowerModel3D.url) || modelInfo) {
        const fileName = `${modelInfo ? modelInfo.name : patientCase.scannings.lowerModel3D.name}`;

        if (isFileInFS(fileName)) {
          return;
        }

        await loadFileFromBackendInFS(
          modelInfo ? modelInfo.url : patientCase.scannings.lowerModel3D.url,
          `${dynamicModelsFullPath}/${fileName}`
        );
      }
    },
    [dynamicModelsFullPath, isFileInFS, loadFileFromBackendInFS]
  );

  const loadRootModel3DInFS = useCallback(
    async (modelInfo?: CaseFile): Promise<void> => {
      await loadModel3DInFS(modelInfo);
    },
    [loadModel3DInFS]
  );

  const loadOriginalModel3DInFS = useCallback(
    async (modelInfo?: CaseFile): Promise<void> => {
      await loadModel3DInFS(modelInfo);
    },
    [loadModel3DInFS]
  );

  const loadRootsModelsFromZipInFs = useCallback(
    async (caseFile: CaseFile, teethArch: CapturePosition): Promise<Model[]> => {
      const resFile = await fetchNonCachedModelFile(caseFile.url);
      const arrayBufferData = await resFile.arrayBuffer();

      const unzipper = new JSZip();
      const zipData = await unzipper.loadAsync(arrayBufferData);

      const models: Model[] = [];

      const processZipEntry = async (zipEntry: JSZip.JSZipObject) => {
        const fileData = await zipEntry.async('uint8array');
        const binData = new Int8Array(fileData);
        const fileFullPath = `${dynamicModelsFullPath}/${zipEntry.name}`;
        createModelsDirectoryIfNotExistent();
        Module.FS.writeFile(fileFullPath, binData);
        models.push({
          id: zipEntry.name.replace('.wepmd', ''),
          uri: `${dynamicModels}/${zipEntry.name}`,
          teethArch: teethArch,
          model3dType: Model3dType.Roots
        });
      };

      // Read the ZIP file
      for (const zipEntryName of Object.keys(zipData.files)) {
        const zipEntry = zipData.files[zipEntryName];
        if (!zipEntry.dir) {
          if (zipEntry.name.endsWith('.zip')) {
            // If the ZIP file contains another ZIP file, read the inner ZIP file
            const innerZipBuffer = await zipEntry.async('arraybuffer');
            const innerUnzipper = new JSZip();
            const innerZipData = await innerUnzipper.loadAsync(innerZipBuffer);

            // Read the inner ZIP file
            for (const innerZipEntryName of Object.keys(innerZipData.files)) {
              const innerZipEntry = innerZipData.files[innerZipEntryName];
              if (!innerZipEntry.dir) {
                await processZipEntry(innerZipEntry);
              }
            }
          } else {
            await processZipEntry(zipEntry);
          }
        }
      }
      return models;
    },
    [createModelsDirectoryIfNotExistent, dynamicModelsFullPath, fetchNonCachedModelFile]
  );

  const patientCaseFilesToMatchingInfo = useCallback(
    (patientCase: PatientCase) => {
      const matchingPoints: Record<string, MatchingInfo> = isStringNullOrEmpty(patientCase.meta)
        ? []
        : JSON.parse(patientCase.meta)['MATCHINGPOINTS'];

      if (!matchingPoints) {
        return;
      }

      const matchingUpperDicomPoints =
        matchingPoints['UPPER'] && matchingPoints['UPPER'].dicomPoints && matchingPoints['UPPER'].dicomPoints.length > 0
          ? matchingPoints['UPPER'].dicomPoints
          : [];
      const matchingUpperModel3dPoints =
        matchingPoints['UPPER'] &&
        matchingPoints['UPPER'].model3dPoints &&
        matchingPoints['UPPER'].model3dPoints.length > 0
          ? matchingPoints['UPPER'].model3dPoints
          : [];
      const matchingLowerDicomPoints =
        matchingPoints['LOWER'] &&
        matchingPoints['LOWER'].dicomPoints &&
        matchingPoints['LOWER']?.dicomPoints.length > 0
          ? matchingPoints['LOWER'].dicomPoints
          : [];
      const matchingLowerModel3dPoints =
        matchingPoints['LOWER'] &&
        matchingPoints['LOWER'].model3dPoints &&
        matchingPoints['LOWER']?.model3dPoints.length > 0
          ? matchingPoints['LOWER'].model3dPoints
          : [];

      const matchingInfo: Record<string, MatchingInfo> = {
        ['UPPER']: {
          dicom: patientCase.captures3D.upperDICOM.name,
          model3D: patientCase.scannings.upperModel3D.name,
          dicomPoints: matchingUpperDicomPoints.length > 0 ? matchingUpperDicomPoints : [],
          model3dPoints: matchingUpperModel3dPoints.length > 0 ? matchingUpperModel3dPoints : []
        },
        ['LOWER']: {
          dicom: patientCase.captures3D.lowerDICOM.name,
          model3D: patientCase.scannings.lowerModel3D.name,
          dicomPoints: matchingLowerDicomPoints.length > 0 ? matchingLowerDicomPoints : [],
          model3dPoints: matchingLowerModel3dPoints.length > 0 ? matchingLowerModel3dPoints : []
        }
      };

      updateMatchingModelPoints(matchingInfo);

      return matchingInfo;
    },
    [updateMatchingModelPoints]
  );

  const loadOrientationTransformationMatrix = async (fileKey: string, versionId: string) => {
    const matrixBlob = await getFile(fileKey, versionId);
    if (matrixBlob) {
      const textMatrix = await matrixBlob.text();
      const parsedMatrix = JSON.parse(textMatrix) as ScanOrientation;

      if (parsedMatrix) {
        window.App.webEventsProxy.modelTransformation.setScanOrientation(parsedMatrix);
      }
    }
  };

  const loadOrientationTransformationMatrices = async (versionId: string) => {
    await loadOrientationTransformationMatrix(EDITED_UPPER_MATRIX_FILE_KEY, versionId);
    await loadOrientationTransformationMatrix(EDITED_LOWER_MATRIX_FILE_KEY, versionId);
  };

  return {
    loadUpperDicomInFS,
    loadUpperModel3DInFS,
    loadLowerDicomInFS,
    loadLowerModel3DInFS,
    loadOriginalModel3DInFS,
    loadRootModel3DInFS,
    patientCaseFilesToMatchingInfo,
    loadRootsModelsFromZipInFs,
    loadOrientationTransformationMatrices
  } as const;
}
