import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ReactComponent as Warning } from '../../../../../assets/icons/modal/warning.svg';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useCaseData, useCaseId } from '../../../../../hooks/shared';
import { CaseVersionState } from '../../../../../shared';

export function ModalConfirmDeleteVersion() {
  const [t] = useTranslation();
  const [caseId] = useCaseId();
  const { toggleModalIsOpened, versionToDelete } = useCommonBoundStore((store) => ({
    toggleModalIsOpened: store.toggleModalIsOpened,
    versionToDelete: store.versionToDelete
  }));
  const { deleteVersion } = useCaseData(caseId);

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  const onConfirmButtonClick = useCallback(async () => {
    try {
      await deleteVersion(versionToDelete?.id);
      toast.success(t('dentalMovements.toast.successDeleteVersion'));
    } finally {
      toggleModalIsOpened('');
    }
  }, [versionToDelete]);

  return (
    <div className="modal-content" data-testid={'modal-previousStep'}>
      <div className="modal-body">
        <h3 className="modal-dental-title mb-3">
          {t(`dentalMovements.modal.deleteTitle`)}
          <Warning className="modal-dental-icon" />
        </h3>
        <p className="modal-text mb-3">{t('dentalMovements.modal.cannotRedo')}</p>
        <p className="modal-text  mb-3">{t('dentalMovements.modal.confirmMessage')}</p>

        <p className="modal-text m-0">{versionToDelete.name}</p>
        <p className="modal-dental-info m-0">{t(`navbar.versions.${CaseVersionState[versionToDelete.result]}`)}</p>
      </div>

      <div className="modal-footer d-flex justify-content-center flex-nowrap">
        <button
          onClick={onCloseButtonClick}
          type="button"
          className="modal-dental-button modal-dental-button--secondary"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.cancel`)}
        </button>
        <button onClick={onConfirmButtonClick} type="button" className="modal-dental-button ">
          {t(`modal.actions.delete`)}
        </button>
      </div>
    </div>
  );
}
