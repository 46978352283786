import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { INJECTED_TYPES, container } from '../../common/ioc';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { OrthodonticsPagesUrl } from '../../types';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { CaseVersion, PROFILES_TYPE } from '../../shared';
import { CaseStatus, ICaseStatusService } from '../../shared/caseStatus';
import { ModalTypes } from '../../surgeries/components/layout';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useCustomNavigate } from './useCustomNavigate';
import { useUtils } from './useUtils';
import { OrthodonticsOrderedPhasesKeys } from '../../orthodontics/components/layout';
import { BaseRoutes, CommonPagesUrl } from '../../types';
import { useShallow } from 'zustand/react/shallow';

const UrlsByCaseState: Record<string, string> = {
  stl_load: CommonPagesUrl.StlCaptures,
  stl_edition: OrthodonticsPagesUrl.StlEdition,
  teeth_segmentation: OrthodonticsPagesUrl.TeethSegmentation,
  axis_and_roots: OrthodonticsPagesUrl.AxisAndRoots,
  treatment: OrthodonticsPagesUrl.Treatment,
  publish: OrthodonticsPagesUrl.Publish,
  dental_movements: OrthodonticsPagesUrl.DentalMovements
};

export function useCaseStatus(caseId: string) {
  const caseStatusService = container.get<ICaseStatusService>(INJECTED_TYPES.ICaseStatusService);
  const {
    caseStatus: caseStatusFromStore,
    updateCaseStatus,
    currentVersion,
    canUndo
  } = useOrthBoundStore(
    useShallow((state) => ({
      caseStatus: state.caseStatus,
      updateCaseStatus: state.updateCaseStatus,
      currentVersion: state.currentVersion,
      canUndo: state.canUndo
    }))
  );
  const [caseStatus, setCaseStatus] = useState<CaseStatus>(caseStatusFromStore);
  const { getRouteWithVersionId } = useCustomNavigate();
  const currentVersionRef = useRef<CaseVersion>();
  const navigate = useNavigate();
  const setProccessUrlByEntryPoint = useOrthBoundStore((state) => state.setProccessUrlByEntryPoint);
  const proccessUrlByEntryPoint = useOrthBoundStore((state) => state.proccessUrlByEntryPoint);
  const isClient = useBoundStore.getState().userProfile?.type === PROFILES_TYPE.client;
  const openModal = useCommonBoundStore((state) => state.openModal);
  const { getCurrentPhaseStageByRoute } = useUtils();
  const isCaseReseting = useCommonBoundStore((state) => state.isCaseReseting);
  const isExpertModeEnabled = useCommonBoundStore((state) => state.isExpertModeEnabled);

  useEffect(() => {
    if (!currentVersion) {
      return;
    }
    fetchCaseStatus();
  }, [currentVersion, isCaseReseting]);

  const fetchCaseStatus = useCallback(async () => {
    if (!currentVersion) {
      return;
    }
    if (!caseStatusFromStore || currentVersionRef.current !== currentVersion) {
      const fetchedCaseStatus = await caseStatusService.getCaseStatusPhases(caseId, currentVersion.id);
      updateCaseStatus(fetchedCaseStatus);
      setCaseStatus(fetchedCaseStatus);

      if (proccessUrlByEntryPoint) {
        navigateByPhase(fetchedCaseStatus);
      }
      currentVersionRef.current = currentVersion;
    }
  }, [currentVersion, caseStatusFromStore, caseId]);

  const navigateByPhase = useCallback(
    (fetchedCaseStatus: CaseStatus) => {
      setProccessUrlByEntryPoint(false);
      const nextPhase = getNextPhaseName(fetchedCaseStatus);

      let route = '';
      if (!nextPhase) {
        route = `/${BaseRoutes.Orthodontics}${OrthodonticsPagesUrl.Publish}/${caseId}`;
      } else {
        route = `/${BaseRoutes.Orthodontics}${UrlsByCaseState[nextPhase]}/${caseId}`;
      }

      if (isClient) {
        route = `/${BaseRoutes.Orthodontics}${OrthodonticsPagesUrl.DentalMovements}/${caseId}`;
      }

      navigate(getRouteWithVersionId(route));
    },
    [caseId]
  );

  const getNextPhaseName = (fetchedCaseStatus: CaseStatus) => {
    const orthodonticsPhases = Object.values(OrthodonticsOrderedPhasesKeys) as string[];
    const validPhases = fetchedCaseStatus?.phases.filter((p) => orthodonticsPhases.includes(p.name));
    const nextPhase = validPhases.find((p) => p.completionDate === null);

    return nextPhase ? nextPhase.name : null;
  };

  const isActualPhaseCompleted = () => {
    const currentPhase = getCurrentPhaseStageByRoute();
    if (!currentPhase) {
      return false;
    }

    const currentPhaseIndex = caseStatusFromStore?.phases.findIndex((p) => p.name === currentPhase);
    if (currentPhaseIndex === -1) {
      return false;
    }
    return caseStatusFromStore?.phases[currentPhaseIndex].completionDate !== null;
  };

  const ifIsNecesaryShowChangesModal = useCallback(() => {
    if (!caseStatusFromStore) {
      return;
    }

    if (
      (!isExpertModeEnabled && isActualPhaseCompleted() === true && canUndo === true && isCaseReseting === false) ||
      (isExpertModeEnabled && isCaseReseting === false && canUndo === true)
    ) {
      openModal(ModalTypes.ModalPreviousCasePhaseModified);
    }
  }, [caseStatusFromStore, isExpertModeEnabled, isActualPhaseCompleted, canUndo, isCaseReseting, openModal]);

  useEffect(() => {
    if (isCaseReseting === true) {
      return;
    }

    ifIsNecesaryShowChangesModal();
  }, [canUndo, isCaseReseting, isExpertModeEnabled]);

  return {
    caseStatus,
    navigateByPhase,
    fetchCaseStatus,
    ifIsNecesaryShowChangesModal,
    isActualPhaseCompleted
  } as const;
}
