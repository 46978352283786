import { useCallback } from 'react';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { orthodonticsAsideConfig, OrthodonticsOrderedPhasesKeys } from '../../orthodontics/components/layout/aside';
import { ModalTypes } from '../../surgeries/components/layout';
import { useCaseId } from './useCaseId';
import { useCaseStatus } from './useCaseStatus';
import { useIprCalculation } from './useIprCalculation';
import { useAttachesManager, useDentalMovementsSnapshot, useDentalMovementsStepsManager } from '../orthodontics';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { TreatmentPhaseChecks } from '../../orthodontics/stores/slices/treatmentPhaseSlice';
import { useShallow } from 'zustand/react/shallow';

export function useCaseChanges(checkUpdateMovements = false) {
  const [caseId] = useCaseId();
  const { caseStatus } = useCaseStatus(caseId);

  const { toggleModalIsOpened, isCaseModified, updateRouteToGo, onlyOneCapture, isExpertModeEnabled } =
    useCommonBoundStore(
      useShallow((state) => ({
        toggleModalIsOpened: state.toggleModalIsOpened,
        isCaseModified: state.isCaseModified,
        updateRouteToGo: state.updateRouteToGo,
        onlyOneCapture: state.onlyOneCapture,
        isExpertModeEnabled: state.isExpertModeEnabled
      }))
    );
  const setIsConfirmed = useBoundStore((state) => state.setIsConfirmed);
  const {
    hasStlBeenOriented,
    haveGumsBeenExtruded,
    hasLoadedOrientedStls,
    hasLoadedExtrudedGums,
    setTreatmentPhaseIssuesChecks
  } = useOrthBoundStore(
    useShallow((state) => ({
      hasStlBeenOriented: state.hasStlBeenOriented,
      haveGumsBeenExtruded: state.haveGumsBeenExtruded,
      hasLoadedOrientedStls: state.hasLoadedOrientedStls,
      hasLoadedExtrudedGums: state.hasLoadedExtrudedGums,
      setTreatmentPhaseIssuesChecks: state.setTreatmentPhaseIssuesChecks
    }))
  );

  const { checkIfIprsAreCalculated } = useIprCalculation();
  const { checkIfAttachesAreSet } = useAttachesManager();
  const { haveBeenMadeChangesInMovements } = useDentalMovementsSnapshot();
  const { hasAnyOvermovement, hasEmptySteps, hasAnyExcessiveIpr, hasAnyModificationWithoutAutoRecalc } =
    useDentalMovementsStepsManager(checkUpdateMovements);

  const getRouteKey = useCallback(() => {
    const combinedMenuItems = orthodonticsAsideConfig.reduce((accumulator, currentData) => {
      return accumulator.concat(currentData.items);
    }, []);

    const currentRoute = new URL(window.location.href);
    const currentRouteMenuItem = combinedMenuItems.find((item) => currentRoute.pathname.includes(item.route));
    if (!currentRouteMenuItem) {
      return null;
    }
    return currentRouteMenuItem.key;
  }, []);

  const onChangeRoute = useCallback(() => {
    if (!isCaseModified || isExpertModeEnabled) {
      return;
    }
    toggleModalIsOpened(ModalTypes.ModalPreviousCasePhaseModified);
  }, [isCaseModified, isExpertModeEnabled, toggleModalIsOpened]);

  const onChangeTreatmentRouteHasIssuesToCheck = useCallback(() => {
    if (!caseStatus || !caseStatus.phases || caseStatus?.phases.length === 0) {
      return false;
    }
    const routeKey = getRouteKey();
    const currentPhase = caseStatus.phases.find((p) => p.name === routeKey);

    if (currentPhase && currentPhase.name == OrthodonticsOrderedPhasesKeys.Treatment) {
      const treatmentPhaseChecks: TreatmentPhaseChecks = {
        isIprCalcPending: !checkIfIprsAreCalculated(),
        isAttachesCalcPending: !checkIfAttachesAreSet() && haveBeenMadeChangesInMovements(),
        isThereAnyOvermovement: hasAnyOvermovement(),
        isThereAnyEmptyStep: hasEmptySteps(),
        isThereAnyExcessiveIpr: hasAnyExcessiveIpr(),
        isThereAnyModificationWithoutAutoRecalc: hasAnyModificationWithoutAutoRecalc()
      };
      if (Object.values(treatmentPhaseChecks).some((check) => check === true)) {
        setTreatmentPhaseIssuesChecks(treatmentPhaseChecks);
        toggleModalIsOpened(ModalTypes.ModalTreatmentPhaseChecks);
        return true;
      }
    }
    return false;
  }, [
    caseStatus,
    getRouteKey,
    checkIfIprsAreCalculated,
    checkIfAttachesAreSet,
    haveBeenMadeChangesInMovements,
    hasAnyOvermovement,
    hasEmptySteps,
    hasAnyExcessiveIpr,
    hasAnyModificationWithoutAutoRecalc,
    setTreatmentPhaseIssuesChecks,
    toggleModalIsOpened
  ]);

  const onChangeTreatmentRouteHasToRecalculateIpr = useCallback(() => {
    if (!caseStatus || !caseStatus.phases || caseStatus?.phases.length === 0) {
      return false;
    }
    const routeKey = getRouteKey();
    const currentPhase = caseStatus.phases.find((p) => p.name === routeKey);

    if (currentPhase.name == OrthodonticsOrderedPhasesKeys.Treatment) {
      if (!checkIfIprsAreCalculated()) {
        toggleModalIsOpened(ModalTypes.ModalPendingIprCalculation);
        return true;
      }
    }
    return false;
  }, [caseStatus, getRouteKey, checkIfIprsAreCalculated, toggleModalIsOpened]);

  const onChangeTreatmentRouteHasToRecalculateAttaches = useCallback(() => {
    if (!caseStatus || !caseStatus.phases || caseStatus?.phases.length === 0) {
      return false;
    }
    const routeKey = getRouteKey();
    const currentPhase = caseStatus.phases.find((p) => p.name === routeKey);

    if (currentPhase.name == OrthodonticsOrderedPhasesKeys.Treatment) {
      if (!checkIfAttachesAreSet()) {
        if (haveBeenMadeChangesInMovements()) {
          toggleModalIsOpened(ModalTypes.ModalPendingAttachesCalculation);
          return true;
        }
        return false;
      }
    }
    return false;
  }, [caseStatus, getRouteKey, checkIfAttachesAreSet, haveBeenMadeChangesInMovements, toggleModalIsOpened]);

  const onChangeSTLCaptureCheckCaptures = useCallback(() => {
    if (!caseStatus || !caseStatus.phases || caseStatus?.phases.length === 0) {
      return false;
    }
    const routeKey = getRouteKey();
    const currentPhase = caseStatus.phases.find((p) => p.name === routeKey);

    if (currentPhase.name == OrthodonticsOrderedPhasesKeys.StlLoad) {
      if (onlyOneCapture) {
        setIsConfirmed(true);
        return true;
      }
    }
    return false;
  }, [caseStatus, getRouteKey, onlyOneCapture, setIsConfirmed]);

  const onChangeSTLEditionCheckOrientationChanges = useCallback(() => {
    if (!caseStatus || !caseStatus.phases || caseStatus?.phases.length === 0) {
      return false;
    }
    const routeKey = getRouteKey();
    const currentPhase = caseStatus.phases.find((p) => p.name === routeKey);

    if (currentPhase.name == OrthodonticsOrderedPhasesKeys.StlEdition) {
      if (!hasStlBeenOriented && !hasLoadedOrientedStls) {
        toggleModalIsOpened(ModalTypes.ModalStlHasNotBeenEdited);
        return true;
      }
    }
    return false;
  }, [caseStatus, getRouteKey, hasStlBeenOriented, hasLoadedOrientedStls, toggleModalIsOpened]);

  const onChangeSTLEditionCheckGumsChanges = useCallback(() => {
    if (!caseStatus || !caseStatus.phases || caseStatus?.phases.length === 0) {
      return false;
    }
    const routeKey = getRouteKey();
    const currentPhase = caseStatus.phases.find((p) => p.name === routeKey);

    if (currentPhase.name == OrthodonticsOrderedPhasesKeys.StlEdition) {
      if (!haveGumsBeenExtruded && !hasLoadedExtrudedGums) {
        toggleModalIsOpened(ModalTypes.ModalGumsHaveNotBeenEdited);
        return true;
      }
    }
    return false;
  }, [caseStatus, getRouteKey, haveGumsBeenExtruded, hasLoadedExtrudedGums, toggleModalIsOpened]);

  const checkIfPreviousCompletedPhaseHasChanged = useCallback(() => {
    if (!caseStatus || !caseStatus.phases || caseStatus?.phases.length === 0) {
      return false;
    }

    const routeKey = getRouteKey();
    const currentPhase = caseStatus.phases.find((p) => p.name === routeKey);

    if (currentPhase.name == OrthodonticsOrderedPhasesKeys.Treatment) {
      checkIfIprsAreCalculated();
    }

    // TODO: need to know last active case phase from backend, for now we will use the last completed phase
    const completedItems = caseStatus.phases.filter((item) => !!item.completionDate);
    const lastCompletedItem = completedItems.reduce(
      (maxItem, currentItem) => (currentItem.completionDate > maxItem.completionDate ? currentItem : maxItem),
      completedItems[0]
    );

    if (lastCompletedItem.id > currentPhase.id) {
      return true;
    }

    return false;
  }, [caseStatus, getRouteKey, checkIfIprsAreCalculated]);

  return {
    isCaseModified,
    onChangeRoute,
    checkIfPreviousCompletedPhaseHasChanged,
    toggleModalIsOpened,
    updateRouteToGo,
    getRouteKey,
    onChangeTreatmentRouteHasToRecalculateIpr,
    onChangeTreatmentRouteHasToRecalculateAttaches,
    onChangeSTLCaptureCheckCaptures,
    onChangeSTLEditionCheckOrientationChanges,
    onChangeSTLEditionCheckGumsChanges,
    onChangeTreatmentRouteHasIssuesToCheck
  };
}
