import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Layers } from '../../../../assets/icons/evergineTools/layers.svg';
import { AbilityAction, AbilityContext, Constants, OrthoAbilitySubject, PROFILES_TYPE } from '../../../../shared';
import { useOrthBoundStore } from '../../../stores/useStore';
import { ToolsDropdownBase, ToolsDropdownBaseItem } from './ToolsDropdownBase';
import { useBoundStore } from '../../../../surgeries/stores/useStore';
import { OrthodonticsPagesUrl } from '../../../../types';
import { useIprCalculation } from '../../../../hooks/shared/useIprCalculation';
import { useShallow } from 'zustand/react/shallow';

const OrthLayers = memo((props: any) => {
  const { dataFor, handleClick, className } = props;

  const {
    upperDentalMovements,
    lowerDentalMovements,
    setShowMiniOcclusogram,
    setShowIPR,
    showIPR,
    setShowInterdental,
    showInterdental,
    forceShowIpr,
    setForceShowIpr
  } = useOrthBoundStore(
    useShallow((state) => ({
      upperDentalMovements: state.upperDentalMovements,
      lowerDentalMovements: state.lowerDentalMovements,
      setShowMiniOcclusogram: state.setShowMiniOcclusogram,
      setShowIPR: state.setShowIPR,
      showIPR: state.showIPR,
      setShowInterdental: state.setShowInterdental,
      showInterdental: state.showInterdental,
      forceShowIpr: state.forceShowIpr,
      setForceShowIpr: state.setForceShowIpr
    }))
  );

  const ability = useContext(AbilityContext);
  const [t] = useTranslation();
  const { checkIfIprsAreCalculated } = useIprCalculation();

  const [toggleAttachments, setToggleAttachments] = useState(true);
  const [toggleLabels, setToggleLabels] = useState(true);
  const [toggleOcclusogram, setToggleOcclusogram] = useState(false);
  const [toggleRoots, setToggleRoots] = useState(false);
  const [opacityLevelRoots, setOpacityLevelRoots] = useState(100);
  const [toggleGum, setToggleGum] = useState(true);
  const [opacityLevelGum, setOpacityLevelGum] = useState(100);
  const [toggleTeeth, setToggleTeeth] = useState(true);
  const [opacityLevelTeeth, setOpacityLevelTeeth] = useState(100);
  const [oclussionZonesLevel, setOclussionZonesLevel] = useState(0);
  const [toggleTADs, setToggleTADs] = useState(true);
  const [toggleIpr, setToggleIpr] = useState(false);
  const [toggleInterdentalDistances, setToggleInterdentalDistances] = useState(showInterdental);
  const isClient = useBoundStore.getState().userProfile?.type === PROFILES_TYPE.client;

  const canManageLabels = useMemo(
    () => ability && ability.can(AbilityAction.View, OrthoAbilitySubject.Labels),
    [ability]
  );

  useEffect(() => {
    if (!upperDentalMovements && !lowerDentalMovements) {
      return;
    }

    if (!canManageLabels) {
      // window.App.webEventsProxy.layers.showLabels(false);
    }
  }, [upperDentalMovements, lowerDentalMovements, canManageLabels]);

  const onToggleAttachments = useCallback(() => {
    const newToggleAttachments = !toggleAttachments;
    setToggleAttachments(newToggleAttachments);
    window.App.webEventsProxy.layers.showAttachments(newToggleAttachments);
  }, [toggleAttachments]);

  const onToggleTADs = useCallback(() => {
    const newToggleTADs = !toggleTADs;
    setToggleTADs(newToggleTADs);
    window.App.webEventsProxy.layers.showTADs(newToggleTADs);
  }, [toggleTADs]);

  const onToggleLabels = useCallback(() => {
    const newToggleLabels = !toggleLabels;
    setToggleLabels(newToggleLabels);
    window.App.webEventsProxy.layers.showLabels(newToggleLabels);
  }, [toggleLabels]);

  const onToggleOcclusogram = useCallback(() => {
    const newToggleOcclusogram = !toggleOcclusogram;
    setToggleOcclusogram(newToggleOcclusogram);
    setShowMiniOcclusogram(newToggleOcclusogram);
    window.App.webEventsProxy.layers.showOcclusogram(newToggleOcclusogram);
  }, [setShowMiniOcclusogram, toggleOcclusogram]);

  const onToggleIpr = useCallback(() => {
    const newToggleIpr = !toggleIpr;
    setToggleIpr(newToggleIpr);
    setShowIPR(newToggleIpr);
    if (newToggleIpr) {
      setToggleInterdentalDistances(false);
      setShowInterdental(false);
    }
    window.App.webEventsProxy.layers.showIprs(newToggleIpr);
  }, [setShowIPR, setShowInterdental, toggleIpr]);

  const onToggleInterdentalDistances = useCallback(() => {
    const newToggleInterdentalDistances = !toggleInterdentalDistances;
    setToggleInterdentalDistances(newToggleInterdentalDistances);
    setShowInterdental(newToggleInterdentalDistances);
    if (newToggleInterdentalDistances) {
      setToggleIpr(false);
      setShowIPR(false);
    }
    window.App.webEventsProxy.layers.showInterdentalDistances(newToggleInterdentalDistances);
  }, [setShowIPR, setShowInterdental, toggleInterdentalDistances]);

  const onToggleRoots = useCallback(() => {
    const isGoingToShowRoots = !toggleRoots;
    setToggleRoots(isGoingToShowRoots);

    onChangeOpacityGum(isGoingToShowRoots ? Constants.defaultPercentageOpacityGum : 100);

    window.App.webEventsProxy.layers.showRoots(isGoingToShowRoots);
  }, [toggleRoots]);

  const onChangeOpacityRoots = (value: number) => {
    setOpacityLevelRoots(value);
    window.App.webEventsProxy.layers.setRootsOpacity(value);
  };

  const onToggleGum = useCallback(() => {
    const newToggleGum = !toggleGum;
    setToggleGum(newToggleGum);
    window.App.webEventsProxy.layers.showGums(newToggleGum);
  }, [toggleGum]);

  const onChangeOpacityGum = (value: number) => {
    setOpacityLevelGum(value);
    window.App.webEventsProxy.layers.setGumsOpacity(value);
  };

  const onToggleTeeth = useCallback(() => {
    const newToggleTeeth = !toggleTeeth;
    setToggleTeeth(newToggleTeeth);
    window.App.webEventsProxy.layers.showTeeth(newToggleTeeth);
  }, [toggleTeeth]);

  const onChangeOpacityTeeth = (value: number) => {
    setOpacityLevelTeeth(value);
    window.App.webEventsProxy.layers.setTeethOpacity(value);
  };

  const onChangeOclussionZones = (value: number) => {
    setOclussionZonesLevel(value);
    window.App.webEventsProxy.layers.setOcclusogramBlend(value);
  };

  const isInDentalMovements = useMemo(() => location.pathname.includes(OrthodonticsPagesUrl.DentalMovements), []);

  const getBaseItems = () => [
    {
      name: t('evergineTools.occlusogram'),
      isDisabled: !upperDentalMovements || !lowerDentalMovements,
      isVisible: toggleOcclusogram,
      withRangeSlider: true,
      rangeSliderValue: oclussionZonesLevel,
      onClick: onToggleOcclusogram,
      handleRangeSlider: onChangeOclussionZones,
      order: 1
    },
    {
      name: t('evergineTools.ipr'),
      isVisible: toggleIpr,
      onClick: onToggleIpr,
      isDisabled: (!upperDentalMovements && !lowerDentalMovements) || (!isClient && !checkIfIprsAreCalculated()),
      order: 2
    },
    {
      name: t('evergineTools.attaches'),
      isVisible: toggleAttachments,
      onClick: onToggleAttachments,
      isDisabled: !upperDentalMovements && !lowerDentalMovements,
      order: 4
    },
    {
      name: t('evergineTools.tads'),
      isVisible: toggleTADs,
      onClick: onToggleTADs,
      isDisabled: !upperDentalMovements && !lowerDentalMovements,
      order: 5
    },

    {
      name: t('evergineTools.roots'),
      isDisabled: !upperDentalMovements && !lowerDentalMovements,
      isVisible: toggleRoots,
      withRangeSlider: true,
      rangeSliderValue: opacityLevelRoots,
      onClick: onToggleRoots,
      handleRangeSlider: onChangeOpacityRoots,
      order: 7
    },
    {
      name: t('evergineTools.gum'),
      isDisabled: !upperDentalMovements && !lowerDentalMovements,
      isVisible: toggleGum,
      withRangeSlider: true,
      rangeSliderValue: opacityLevelGum,
      onClick: onToggleGum,
      handleRangeSlider: onChangeOpacityGum,
      order: 8
    }
  ];

  const baseLayerItems: ToolsDropdownBaseItem[] = useMemo(() => {
    let items = getBaseItems();
    if (isClient && isInDentalMovements) {
      items = items.filter((item) => item.name !== t('evergineTools.ipr'));
    }

    return items;
  }, [
    checkIfIprsAreCalculated,
    isClient,
    isInDentalMovements,
    lowerDentalMovements,
    oclussionZonesLevel,
    onToggleAttachments,
    onToggleGum,
    onToggleIpr,
    onToggleOcclusogram,
    onToggleRoots,
    onToggleTADs,
    opacityLevelGum,
    opacityLevelRoots,
    t,
    toggleAttachments,
    toggleGum,
    toggleIpr,
    toggleOcclusogram,
    toggleRoots,
    toggleTADs,
    upperDentalMovements,
    getBaseItems
  ]);

  const extraDesignerLayerItems: ToolsDropdownBaseItem[] = useMemo(
    () => [
      {
        name: t('evergineTools.interdentalDistances'),
        isVisible: toggleInterdentalDistances,
        onClick: onToggleInterdentalDistances,
        isDisabled: !upperDentalMovements && !lowerDentalMovements,
        order: 3,
        defaultActive: isClient && isInDentalMovements
      },
      {
        name: t('evergineTools.labels'),
        isVisible: toggleLabels,
        onClick: onToggleLabels,
        isDisabled: !upperDentalMovements && !lowerDentalMovements,
        order: 6
      },
      {
        name: t('evergineTools.teeth'),
        isDisabled: !upperDentalMovements && !lowerDentalMovements,
        isVisible: toggleTeeth,
        withRangeSlider: true,
        rangeSliderValue: opacityLevelTeeth,
        onClick: onToggleTeeth,
        handleRangeSlider: onChangeOpacityTeeth,
        order: 9
      }
    ],
    [
      isClient,
      isInDentalMovements,
      lowerDentalMovements,
      onToggleInterdentalDistances,
      onToggleLabels,
      onToggleTeeth,
      opacityLevelTeeth,
      t,
      toggleInterdentalDistances,
      toggleLabels,
      toggleTeeth,
      upperDentalMovements
    ]
  );

  const orthLayerItemsToApply = useMemo(() => {
    if (isInDentalMovements) {
      return baseLayerItems;
    }

    return [...baseLayerItems, ...extraDesignerLayerItems].sort((itemA, itemB) => itemA.order - itemB.order);
  }, [isInDentalMovements, baseLayerItems, extraDesignerLayerItems]);

  useEffect(() => {
    if ((isClient && isInDentalMovements) || forceShowIpr) {
      const defaultActiveIpr = true;
      setToggleIpr(defaultActiveIpr);
      setShowIPR(defaultActiveIpr);
      setToggleInterdentalDistances(!defaultActiveIpr);
      setShowInterdental(!defaultActiveIpr);
      window.App.webEventsProxy.layers.showIprs(defaultActiveIpr);
      setForceShowIpr(false);
    }

    if (isInDentalMovements) {
      window.App.webEventsProxy.layers.showLabels(false);
      window.App.webEventsProxy.layers.showInterdentalDistances(false);
      window.App.webEventsProxy.layers.showTeeth(true);
    }
  }, [isClient, isInDentalMovements, forceShowIpr]);

  return (
    <>
      <ToolsDropdownBase
        title={t('evergineTools.layers')}
        prefix="orthlayers"
        dataFor={dataFor}
        toggleIcon={Layers}
        className={className}
        handleToggleClick={handleClick}
        items={orthLayerItemsToApply}
      />
    </>
  );
});

export default OrthLayers;
