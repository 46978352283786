import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BaseRoutes, CommonPagesUrl } from '../../../types';
import ArrowRightWhite from '../../../assets/icons/arrow-right-white.svg';
import ArrowRightGray from '../../../assets/icons/next.svg';
import HomeLogo from '../../../assets/images/home-logo.svg';
import { container } from '../../../common/ioc/ioc.config';
import { INJECTED_TYPES } from '../../../common/ioc/ioc.types';
import { useGateKeeper } from '../../../hooks';
import { CaseType } from '../../../models';
import {
  AbilityAction,
  CommonAbilitySubject,
  CustomError,
  CustomErrorEnum,
  ICasesService,
  PatientCase
} from '../../../shared';
import { useBoundStore } from '../../stores/useStore';
import { AlertMessage, AlertMessageType } from '../alertMessage';
import './home.scss';

function Home() {
  const navigate = useNavigate();
  useGateKeeper(AbilityAction.View, CommonAbilitySubject.LoadCase, () => navigate('/forbidden'));

  const [t] = useTranslation();
  const [errorType, setErrorType] = useState<CustomErrorEnum>();
  const [isSendButtonEnabled, setIsSendButtonEnabled] = useState<boolean>();
  const updatePatientCase = useBoundStore((state) => state.updatePatientCase);
  const updateCaseId = useBoundStore((state) => state.updateCaseId);
  const caseId = useBoundStore((state) => state.caseId);

  const setCaseIdToSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const caseIdValue = e.target.value.trim();
    updateCaseId(caseIdValue);
    setIsSendButtonEnabled(caseIdValue.length > 0);
  }, []);

  const getCase = useCallback(async () => {
    try {
      const casesService = container.get<ICasesService>(INJECTED_TYPES.ICasesService);
      const fetchedCase: PatientCase = await casesService.getById(caseId, getVersionId());
      updatePatientCase(fetchedCase);

      const isSurgery = fetchedCase.type !== CaseType.Orthodontics;
      const baseRoute = isSurgery ? BaseRoutes.Surgery : BaseRoutes.Orthodontics;
      const routeToNavigate = `${baseRoute}${CommonPagesUrl.GeneralInfo}/${caseId}`;

      navigate(routeToNavigate);
    } catch (error) {
      // ToDo: The services will not raise CustomError anymore, refactor to use toasts or change the services again
      const customError = (error as CustomError).errorType();
      setErrorType(customError);
    }
  }, [caseId]);

  const getVersionId = () => {
    const currentLocation = window.location;
    const searchParams = new URLSearchParams(currentLocation.search);
    if (searchParams.has('versionId')) {
      return searchParams.get('versionId');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getCase();
    }
  };

  return (
    <section className="home justify-content-center d-flex">
      <div className="home-container">
        <div className="col-12">
          <div className="col-9 home-logo">
            <img className="w-100" src={HomeLogo} alt={t('common.company')} />
          </div>
          <div className="col-12 home-text-container">
            <p className="home-text mb-0">{t('home.welcome')}</p>
          </div>
          <div className="home-form d-flex align-items-center col-12">
            <input
              type="text"
              data-testid="case-id-input"
              className="home-input col-12 p-3"
              placeholder={t('home.input')}
              aria-label={t('home.input')}
              onChange={setCaseIdToSearch}
              onKeyPress={handleKeyPress}
            />
            <button
              className={`home-button ${isSendButtonEnabled && 'home-button--enabled'}`}
              type="button"
              onClick={getCase}
              disabled={!isSendButtonEnabled}
              data-testid="case-id-button"
            >
              <img src={isSendButtonEnabled ? ArrowRightWhite : ArrowRightGray} alt={t('home.sendCaseId')} />
            </button>
          </div>
          {errorType !== undefined && (
            <AlertMessage
              message={errorType === CustomErrorEnum.NotFound ? t('home.incorrectId') : t('home.failedLoad')}
              type={errorType === CustomErrorEnum.NotFound ? AlertMessageType.Error : AlertMessageType.Warning}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default Home;
