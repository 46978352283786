import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ForbiddenScreen, LoadingText, NotFoundScreen } from './common/components';
import { IncompatibilityScreen } from './common/components/incompatibilityScreen';
import { AppGuard } from './common/guards/AppGuard';

const Home = lazy(() => import('./surgeries/components/home/Home'));
const SurgeryRoutes = lazy(() => import('./surgeries/SurgeryRoutes'));
const OrthodonticsRoutes = lazy(() => import('./orthodontics/OrthodonticsRoutes'));

import './AppRouter.scss';

export function AppRouter() {
  return (
    <AppGuard>
      <Routes>
        <Route path="/forbidden" element={<ForbiddenScreen />} />
        <Route path="/not-found" element={<NotFoundScreen />} />
        <Route path="/incompatible" element={<IncompatibilityScreen />} />
        <Route
          path="/"
          element={
            <Suspense fallback={<LoadingText />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="surgery/*"
          element={
            <Suspense fallback={<LoadingText />}>
              <SurgeryRoutes />
            </Suspense>
          }
        />
        <Route
          path="orthodontics/*"
          element={
            <Suspense fallback={<LoadingText />}>
              <OrthodonticsRoutes />
            </Suspense>
          }
        />

        <Route path="*" element={<Navigate replace to="/not-found" />} />
      </Routes>
    </AppGuard>
  );
}
