import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as RejectIcon } from '../../../assets/icons/remove-view.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send.svg';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import { ReactComponent as BoltIcon } from '../../../assets/icons/bolt.svg';
import { ModalTypes } from '../../../surgeries/components/layout';
import { useCommonBoundStore } from '../../stores/useStore';
import { IconRoundButton } from '../iconRoundButton';

import './clientValidation.scss';
import { useExpertMode } from '../../../hooks/shared/useExpertMode';
import { useCaseChanges } from '../../../hooks';
import { useShallow } from 'zustand/react/shallow';

interface ClientValidationProps {
  isInEditionMode: boolean;
  isCaseBlocked: boolean;
}

type ClientValidationAction = {
  id: string;
  icon: any;
  tooltip: string;
  showInEditionMode: boolean;
  enabled: boolean;
  callback: () => void;
};

export enum ClientValidationEnum {
  Validate = 'validate',
  Edit = 'edit',
  Reject = 'reject',
  Send = 'send',
  Cancel = 'cancel'
}

export function ClientValidation({ isInEditionMode, isCaseBlocked }: ClientValidationProps) {
  const { toggleModalIsOpened, isThereFeedbackForTreatment, isExpertModeEnabled } = useCommonBoundStore(
    useShallow((state) => ({
      toggleModalIsOpened: state.toggleModalIsOpened,
      isThereFeedbackForTreatment: state.isThereFeedbackForTreatment,
      isExpertModeEnabled: state.isExpertModeEnabled
    }))
  );

  const [t] = useTranslation();
  const { enableExpertMode } = useExpertMode();
  const { onChangeTreatmentRouteHasIssuesToCheck } = useCaseChanges();

  const [isEditingModeEnabled, setEditingModeEnabled] = useState(false);

  const actions: ClientValidationAction[] = [
    {
      id: 'validate',
      icon: CheckIcon,
      callback: () => {
        toggleModalIsOpened(ModalTypes.ModalClientValidationsValidate);
      },
      tooltip: t('clientValidations.validate'),
      showInEditionMode: false,
      enabled: !isCaseBlocked
    },
    {
      id: 'edit',
      icon: EditIcon,
      callback: () => {
        setEditingModeEnabled(true);
        toggleModalIsOpened(ModalTypes.ModalClientValidationsEdit);
      },
      tooltip: t('clientValidations.edit'),
      showInEditionMode: false,
      enabled: !isCaseBlocked
    },
    {
      id: 'reject',
      icon: RejectIcon,
      callback: () => {
        toggleModalIsOpened(ModalTypes.ModalClientValidationsReject);
      },
      tooltip: t('clientValidations.reject'),
      showInEditionMode: false,
      enabled: !isCaseBlocked
    },
    {
      id: 'send',
      icon: SendIcon,
      callback: () => {
        if (isExpertModeEnabled) {
          const hasTreatmentIssues = onChangeTreatmentRouteHasIssuesToCheck();

          if (hasTreatmentIssues) return;
        }

        toggleModalIsOpened(ModalTypes.ModalClientValidationsSendChanges);
      },
      tooltip: t('clientValidations.send'),
      showInEditionMode: true,
      enabled: true
    },
    {
      id: 'cancel',
      icon: CancelIcon,
      callback: () => {
        toggleModalIsOpened(ModalTypes.ModalClientValidationsCancelChanges);
      },
      tooltip: t('clientValidations.cancel'),
      showInEditionMode: true,
      enabled: true
    },
    {
      id: 'expertMode',
      icon: BoltIcon,
      callback: () => {
        enableExpertMode();
      },
      tooltip: t('clientValidations.expertMode'),
      showInEditionMode: true,
      enabled: isEditingModeEnabled
    }
  ];

  const showActionIfItShould = useCallback(
    (action: ClientValidationAction) => {
      const shouldRender =
        (isInEditionMode ? action.showInEditionMode : !action.showInEditionMode) &&
        !isThereFeedbackForTreatment &&
        action.enabled;
      if (shouldRender) {
        return (
          <li data-for={action.id} data-tip data-testid={action.id} key={`key-${action.id}`}>
            <IconRoundButton modifier="secondary" icon={action.icon} size="m" onClick={action.callback} />
            <ReactTooltip id={action.id} place="right" effect="solid" className="tooltip" type="dark">
              {action.tooltip}
            </ReactTooltip>
          </li>
        );
      }

      return null;
    },
    [isInEditionMode, isThereFeedbackForTreatment, isEditingModeEnabled]
  );

  return (
    <div className="client-validation">
      <ul className="client-validation-actions">{actions.map((action) => showActionIfItShould(action))}</ul>
    </div>
  );
}
