import { StateCreator } from 'zustand';

export interface ValidateTreatmentSlice {
  isExpertModeEnabled: boolean;
  isThereFeedbackForTreatment: boolean;
  setIsExpertModeEnabled: (isThereFeedbackForTreatment: boolean) => void;
  setIsThereFeedbackForTreatment: (isThereFeedbackForTreatment: boolean) => void;
}
export const createValidateTreatmentSlice: StateCreator<ValidateTreatmentSlice, [], [], ValidateTreatmentSlice> = (
  set
) => ({
  isExpertModeEnabled: false,
  isThereFeedbackForTreatment: false,
  setIsExpertModeEnabled: (isExpertModeEnabled: boolean) => set(() => ({ isExpertModeEnabled })),
  setIsThereFeedbackForTreatment: (isThereFeedbackForTreatment: boolean) => set(() => ({ isThereFeedbackForTreatment }))
});
