import { CSSProperties, useLayoutEffect, useRef, useState, useCallback } from 'react';
import { Vector2 } from '../../evergine';
import './customTooltip.scss';

type CustomTooltipProps = {
  text: string;
  position: Vector2;
  show?: boolean;
};

export const CustomTooltip = ({ position, text, show = true }: CustomTooltipProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [styles, setStyles] = useState<CSSProperties>({
    visibility: 'hidden',
    position: 'absolute'
  });

  const calculatePositionAndWidth = useCallback(() => {
    if (tooltipRef.current) {
      const width = tooltipRef.current.getBoundingClientRect().width;
      const middleWidth = width / 2;
      const calculateY = position.y + 60;
      const calculateX = position.x - middleWidth;

      setStyles((prevStyles) => {
        if (
          prevStyles.bottom === `${calculateY}px` &&
          prevStyles.left === `${calculateX}px` &&
          prevStyles.visibility === (show ? 'visible' : 'hidden')
        ) {
          // Evitar actualización innecesaria si los valores son los mismos
          return prevStyles;
        }

        return {
          bottom: `${calculateY}px`,
          left: `${calculateX}px`,
          position: 'absolute',
          visibility: show ? 'visible' : 'hidden'
        };
      });
    }
  }, [position, show]);

  useLayoutEffect(() => {
    calculatePositionAndWidth();
  }, [calculatePositionAndWidth]);

  return (
    <div ref={tooltipRef} className="tooltip-custom" style={styles}>
      <span>{text}</span>
    </div>
  );
};
