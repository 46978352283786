import { FC, ReactNode } from 'react';
import { Stage } from '../..';
import { useCaseId, useRenderModels } from '../../../hooks';
interface RenderModelType {
  stage: Stage;
  children: ReactNode;
}
export const RenderModels: FC<RenderModelType> = ({ children, stage }) => {
  const [caseId] = useCaseId();
  const { modelsLoaded } = useRenderModels(caseId, stage);

  if (!modelsLoaded) {
    return null;
  }

  return <>{children}</>;
};
