import { StateCreator } from 'zustand';
import { TeethArchPosition } from '../../../models';
import { CameraPosition } from '../../../common';

export interface OrthTeethArchSlice {
  orthTeethArch: TeethArchPosition;
  setOrthTeethArch: (teethArchPosition: TeethArchPosition) => void;
  showUpperArch: boolean;
  setShowUpperArch: (showUpperArch: boolean) => void;
  showLowerArch: boolean;
  setShowLowerArch: (showLowerArch: boolean) => void;
  cameraPosition: CameraPosition;
  setCameraPosition: (cameraPosition: CameraPosition) => void;
}

export const createOrthTeethArchSlice: StateCreator<OrthTeethArchSlice, [], [], OrthTeethArchSlice> = (set) => ({
  orthTeethArch: TeethArchPosition.BOTH,
  cameraPosition: CameraPosition.Front,
  setOrthTeethArch: (orthTeethArch: TeethArchPosition) => set((_) => ({ orthTeethArch })),
  showUpperArch: true,
  setShowUpperArch: (showUpperArch: boolean) => set((_) => ({ showUpperArch })),
  showLowerArch: true,
  setShowLowerArch: (showLowerArch: boolean) => set((_) => ({ showLowerArch })),
  setCameraPosition: (cameraPosition: CameraPosition) => set((_) => ({ cameraPosition }))
});
