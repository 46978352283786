import classNames from 'classnames';
import { AttachmentState } from '../../../../common';
import { EvolutionTooth } from '../';
import { FC, memo, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { CustomTooltip } from '../../../../common/components/customTooltip';
import { useTranslation } from 'react-i18next';
import './toothInfoBrick.scss';

interface ToothInfoBrickProps {
  evolutionTooth: EvolutionTooth | undefined;
  isMissingTooth: boolean;
  attachmentState?: AttachmentState;
  isOverMovement: boolean;
  isOppositeMovement: boolean;
  columnIndex: number;
  canDrag: boolean;
  quadrant: number;
  onDrag?: (e: React.DragEvent<HTMLDivElement>, rowNumber: number, className: string) => void;
  onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragStart?: (e: React.DragEvent<HTMLDivElement>, rowNumber: number, className: string, quadrant: number) => void;
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragOver?: (e: React.DragEvent<HTMLDivElement>, rowNumber: number) => void;
}

const tooltipPosition = { y: -40, x: 9 };

export const ToothInfoBrick: FC<ToothInfoBrickProps> = memo(
  ({
    evolutionTooth,
    isMissingTooth,
    attachmentState,
    isOverMovement,
    isOppositeMovement,
    columnIndex,
    canDrag,
    quadrant,
    onDrag,
    onDragEnd,
    onDragStart,
    onDrop,
    onDragOver
  }) => {
    const baseClass = 'toothinfobrick';
    const [t] = useTranslation();
    const [isHovered, setIsHovered] = useState(false);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    const classes = useMemo(
      () =>
        classNames(baseClass, {
          ['toothinfobrick--nomovement']: !evolutionTooth?.isMoved && !evolutionTooth?.isPassiveAligner,
          ['toothinfobrick--missingtooth']: isMissingTooth || evolutionTooth?.isExtracted,
          ['toothinfobrick--activealigner']: evolutionTooth?.isMoved,
          ['toothinfobrick--passivealigner']: evolutionTooth?.isPassiveAligner,
          ['toothinfobrick--attachestart']: attachmentState === AttachmentState.Start,
          ['toothinfobrick--attacheend']: attachmentState === AttachmentState.End,
          ['toothinfobrick--bothattache']: attachmentState === AttachmentState.Both,
          ['toothinfobrick--grabable']: evolutionTooth?.isMoved && canDrag,
          ['toothinfobrick--oppositemovement']: isOppositeMovement && !isOverMovement,
          ['toothinfobrick--overmovement']: isOverMovement
        }),
      [evolutionTooth, isMissingTooth, attachmentState, isOppositeMovement, isOverMovement, canDrag]
    );

    const handleMouseEnter = useCallback(() => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      setIsHovered(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
      debounceTimeout.current = setTimeout(() => {
        setIsHovered(false);
      }, 1000);
    }, []);

    const handleDrag = useCallback(
      (e: React.DragEvent<HTMLDivElement>) => {
        if (onDrag && evolutionTooth) {
          onDrag(e, evolutionTooth.fdi, classes);
        }
      },
      [onDrag, evolutionTooth, classes]
    );

    const handleDragStart = useCallback(
      (e: React.DragEvent<HTMLDivElement>) => {
        if (onDragStart && evolutionTooth) {
          onDragStart(e, evolutionTooth.fdi, classes, quadrant);
        }
      },
      [onDragStart, evolutionTooth, classes]
    );

    const handleDragOver = useCallback(
      (e: React.DragEvent<HTMLDivElement>) => {
        if (onDragOver && evolutionTooth) {
          onDragOver(e, evolutionTooth.fdi);
        }
      },
      [onDragOver, evolutionTooth]
    );

    const handleDrop = useCallback(
      (e: React.DragEvent<HTMLDivElement>) => {
        onDrop(e);
      },
      [onDrop]
    );

    const handleDragEnd = useCallback(
      (e: React.DragEvent<HTMLDivElement>) => {
        if (onDragEnd) {
          onDragEnd(e);
        }
      },
      [onDragEnd]
    );

    useEffect(() => {
      return () => {
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
        }
      };
    }, []);

    return (
      <>
        <div
          onMouseOver={handleMouseEnter}
          onMouseOut={handleMouseLeave}
          className={classes}
          data-row={evolutionTooth?.fdi}
          data-column={columnIndex}
          draggable={classes.includes('toothinfobrick--grabable')}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        ></div>
        {isOverMovement && (
          <CustomTooltip
            text={t('evolutionPanel.info.tooltip.overMovement')}
            position={tooltipPosition}
            show={isHovered}
          />
        )}
        {isOppositeMovement && !isOverMovement && (
          <CustomTooltip
            text={t('evolutionPanel.info.tooltip.oppositeMovements')}
            position={tooltipPosition}
            show={isHovered}
          />
        )}
      </>
    );
  }
);
