import { ReactComponent as OcclusionIcon } from '../../../../assets/icons/occlusion.svg';

export function MovementsTableOcclusionRow() {
  return (
    <div className="movementstable-row">
      <div className="movementstable-piece-number">
        <OcclusionIcon />
      </div>
    </div>
  );
}
