import { useCallback } from 'react';

import { OrthodonticsOrderedPhasesKeys } from '../../../orthodontics/components/layout';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { SaveChangesHook } from '../../shared';
import { useSaveProgress } from '../useSaveProgress';

export function useSaveAxisAndRoots(): SaveChangesHook {
  const { saveProgressInBackend } = useSaveProgress();
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const { setMesioDistalLength, setIsAutoAxisRootsConfirmed, setUpperAndLowerDentalMovements } = useOrthBoundStore(
    (state) => ({
      setMesioDistalLength: state.setMesioDistalLength,
      setIsAutoAxisRootsConfirmed: state.setIsAutoAxisRootsConfirmed,
      setUpperAndLowerDentalMovements: state.setUpperAndLowerDentalMovements
    })
  );

  const saveChanges = useCallback(async (): Promise<boolean> => {
    let response = true;
    setWebBusy(true);
    try {
      const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();

      setUpperAndLowerDentalMovements(upperMovements, lowerMovements);

      await saveProgressInBackend(
        { lower: lowerMovements, upper: upperMovements },
        OrthodonticsOrderedPhasesKeys.AxisAndRoots
      );
    } catch (error) {
      response = false;
    } finally {
      setIsAutoAxisRootsConfirmed(false);
      setWebBusy(false);
      return Promise.resolve(response);
    }
  }, [setMesioDistalLength, saveProgressInBackend, setUpperAndLowerDentalMovements]);

  return { saveChanges };
}
