export enum TableMovementType {
  ExtrusionIntrusion = 'extrusionIntrusion',
  TranslationVL = 'translationVL',
  TranslationMD = 'translationMD',
  PureRotation = 'pureRotation',
  Torque = 'torque',
  TIP = 'tip'
}

export interface TableDataValues {
  id: number;
  value: Record<TableMovementType, number>;
}
