import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseCaptures } from '../../../common/components/';
import { CaptureType } from '../../../common/evergine/types';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useGateKeeper } from '../../../hooks';
import { AbilityAction, CommonAbilitySubject } from '../../../shared';

export function DICOMCaptures() {
  const navigate = useNavigate();
  useGateKeeper(AbilityAction.Manage, CommonAbilitySubject.UploadDICOMs, () => navigate('/forbidden'));
  const { captureType, setCaptureType } = useCommonBoundStore((store) => ({
    captureType: store.captureType,
    setCaptureType: store.setCaptureType
  }));

  useEffect(() => {
    setCaptureType(CaptureType.DICOM);
  }, [captureType, setCaptureType]);

  return <BaseCaptures type={CaptureType.DICOM} allowedFileExtension="zip" />;
}
