import { useMemo } from 'react';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';

export function useTeethMovementsToolbarElement() {
  const { isNewStepClientModification } = useCommonBoundStore((state) => ({
    isNewStepClientModification: state.isNewStepClientModification
  }));
  const { lowerDentalMovements, upperDentalMovements, showMovements, setShowMovements } = useOrthBoundStore(
    (state) => ({
      lowerDentalMovements: state.lowerDentalMovements,
      upperDentalMovements: state.upperDentalMovements,
      showMovements: state.showMovements,
      setShowMovements: state.setShowMovements
    })
  );
  const { userProfile } = useBoundStore((state) => ({
    userProfile: state.userProfile
  }));

  const isHidden = useMemo(() => {
    const isClient = userProfile?.type === 'client';
    const isModificationMode = isNewStepClientModification;
    const isHiddenForClient = isClient && !isModificationMode;

    return isHiddenForClient || (!lowerDentalMovements && !upperDentalMovements);
  }, [isNewStepClientModification, lowerDentalMovements, upperDentalMovements, userProfile?.type]);

  return {
    isHidden
  };
}
