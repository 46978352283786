import { useLocation } from 'react-router-dom';
import { lazy, useCallback, useEffect, useMemo } from 'react';
import { useEvergineStore } from 'evergine-react';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { PROFILES_TYPE } from '../../../shared';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { MovementsPanel } from '../../components/movementsPanel';
import { useOrthBoundStore } from '../../stores/useStore';
import { PathLevels } from '../../../models';

import { useCaseId, useGeneralPanels } from '../../../hooks';
import { useDentalMovements, useDentalMovementsStepsManager } from '../../../hooks/orthodontics';
import { EvergineToolbar, PagesWithTools, toolbarConfig } from '../../../common';
import { useShallow } from 'zustand/react/shallow';

import {
  AttachesTools,
  GumExtrusionTools,
  OcclusogramLegend,
  OrthTeethMovements,
  IprPanel,
  Shift,
  StlOrientationTools,
  TadsTools,
  LabelsTools
} from '../../components';

const LateralPanel = lazy(() => import('../../../common/components/lateralPanel/LateralPanel'));
const BoltonPanel = lazy(() => import('../../components/boltonPanel/BoltonPanel'));
const EvolutionPanel = lazy(() => import('../../components/evolutionPanel/EvolutionPanel'));

type GeneralPanelsProps = {
  children: JSX.Element;
  pageWithTools?: PagesWithTools;
};

export function GeneralPanels({ children, pageWithTools }: GeneralPanelsProps) {
  const [caseId] = useCaseId();
  const { pathname } = useLocation();
  const { evergineReady } = useEvergineStore();
  const isEditStep = useCommonBoundStore((store) => store.isEditStep);
  const {
    showMiniOcclusogram,
    showMovements,
    showBoltonPanel,
    setShowBoltonPanel,
    showEvolutionPanel,
    setShowEvolutionPanel,
    showMovementsTable,
    setShowMovementsTable,
    showAttachesPanel,
    showTadsPanel,
    showIprPanel,
    showStlOrientationPanel,
    showLabelsPanel,
    showGumExtrusionPanel
  } = useOrthBoundStore(
    useShallow((state) => ({
      showMiniOcclusogram: state.showMiniOcclusogram,
      showMovements: state.showMovements,
      showBoltonPanel: state.showBoltonPanel,
      setShowBoltonPanel: state.setShowBoltonPanel,
      showEvolutionPanel: state.showEvolutionPanel,
      setShowEvolutionPanel: state.setShowEvolutionPanel,
      showMovementsTable: state.showMovementsTable,
      setShowMovementsTable: state.setShowMovementsTable,
      showAttachesPanel: state.showAttachesPanel,
      showTadsPanel: state.showTadsPanel,
      showIprPanel: state.showIprPanel,
      showStlOrientationPanel: state.showStlOrientationPanel,
      showLabelsPanel: state.showLabelsPanel,
      showGumExtrusionPanel: state.showGumExtrusionPanel
    }))
  );
  const { showLateralPanelIfAllowed, getWidthFromEvolutionPanel, evolutionPanelWidth } = useGeneralPanels();
  const { showInfoPanel, handleInfoPanelToggle } = useDentalMovements(caseId);

  const pageTools = useMemo(() => toolbarConfig[pageWithTools], [pageWithTools]);

  const isClient = useMemo(() => {
    return useBoundStore.getState().userProfile?.type === PROFILES_TYPE.client;
  }, []);

  const isInPublishScreen = useMemo(() => {
    const shortedPathName = `${pathname.split('/')[PathLevels.RouteView]}`;
    if (shortedPathName === 'publish') {
      return true;
    }
    return false;
  }, [pathname]);

  const toolsWithoutMove = useMemo(() => {
    if (isInPublishScreen) {
      return pageTools.filter((item) => item.tooltipKey !== 'evergineTools.teethMovements');
    }
    return;
  }, [isInPublishScreen, pageTools]);

  const shiftMovementsPanel = useMemo(() => {
    if (showMovementsTable) {
      if (showEvolutionPanel && !showInfoPanel) {
        return Shift.EvolutionPanelOpened;
      }

      if (showInfoPanel && !showEvolutionPanel) {
        return Shift.InfoPanelOpened;
      }

      if (showInfoPanel && showEvolutionPanel) {
        return Shift.EvolutionAndInfoPanelsOpened;
      }
    }

    return Shift.None;
  }, [showMovementsTable, showEvolutionPanel, showInfoPanel]);

  const handleEvolutionPanelClose = useCallback(() => setShowEvolutionPanel(false), [setShowEvolutionPanel]);
  const handleMovementsPanelClose = useCallback(() => setShowMovementsTable(false), [setShowMovementsTable]);
  const handleBoltonPanelClose = useCallback(() => setShowBoltonPanel(false), [setShowBoltonPanel]);

  return (
    <>
      {/* starting from load stl phase*/}
      {showLateralPanelIfAllowed && (
        <div>
          <LateralPanel toggleShow={showInfoPanel} handleToggle={handleInfoPanelToggle} />
        </div>
      )}
      {pageTools && evergineReady && (
        <>
          {!isInPublishScreen ? (
            <EvergineToolbar tools={pageTools} />
          ) : (
            <EvergineToolbar tools={isEditStep && isClient ? pageTools : toolsWithoutMove} />
          )}
        </>
      )}
      {/* starting from edit stl phase*/}
      {showStlOrientationPanel && <StlOrientationTools />}
      {showGumExtrusionPanel && <GumExtrusionTools />}
      {/* starting from treatment phase*/}
      {showMiniOcclusogram && <OcclusogramLegend />}
      {showMovements && <OrthTeethMovements />}
      {showBoltonPanel && <BoltonPanel opened={showBoltonPanel} onClickClose={handleBoltonPanelClose} />}
      {
        <EvolutionPanel
          opened={showEvolutionPanel}
          onClickClose={handleEvolutionPanelClose}
          shift={showEvolutionPanel && showInfoPanel ? Shift.InfoPanelOpened : Shift.None}
          getWidthFunction={getWidthFromEvolutionPanel}
        />
      }
      {showMovementsTable && (
        <MovementsPanel
          opened={showMovementsTable}
          onClickClose={handleMovementsPanelClose}
          shiftMode={shiftMovementsPanel}
          evolutionPanelWidth={evolutionPanelWidth}
        />
      )}
      {showIprPanel && <IprPanel />}
      {showAttachesPanel && <AttachesTools />}
      {showTadsPanel && <TadsTools />}
      {showLabelsPanel && <LabelsTools />}
      {children}
    </>
  );
}
