import { useTranslation } from 'react-i18next';
import NextStepIcon from '../../../../assets/icons/next.svg';
import ReactTooltip from 'react-tooltip';
import { useBoundStore } from '../../../../surgeries/stores/useStore';
import { NavBarAction } from './NavBarAction';
import { useEffect, useState } from 'react';
import { useSaveAndNavigateStep } from '../../../../hooks/shared/useSaveAndNavigateStep';
import { usePhaseChangeValidation } from '../../../../hooks/orthodontics/phaseChangeValidation/usePhaseChangeValidation';
import { useOrthBoundStore } from '../../../../orthodontics/stores/useStore';
import { useCommonBoundStore } from '../../../stores/useStore';

export default (actionProps: any) => {
  const isDirectNavigation = actionProps?.block?.isDirectNavigation;
  const checkPhaseChanges = actionProps?.block?.checkPhaseChanges;
  const canBeConfirmed = useBoundStore((state) => state.canBeConfirmed);
  const setIsNavigationToNextPhase = useOrthBoundStore((state) => state.setIsNavigationToNextPhase);
  const setNavigationRoute = useOrthBoundStore((state) => state.setNavigationRoute);
  const isExpertModeEnabled = useCommonBoundStore((state) => state.isExpertModeEnabled);

  const [t] = useTranslation();
  const { saveAndNavigateStep } = useSaveAndNavigateStep({ isDirectNavigation, checkPhaseChanges });
  const { getPhaseChangeValidation } = usePhaseChangeValidation();
  const { isValidPhase } = getPhaseChangeValidation();

  const [isValidPhaseResolved, setIsValidPhaseResolved] = useState(false);

  const buttonId = 'nextStep';

  const onNextButtonClick = async () => {
    setIsNavigationToNextPhase(true);
    setNavigationRoute(null);
    await saveAndNavigateStep(null, true);
  };

  const isDisabled = () => {
    if (!actionProps?.block?.isDirectNavigation && canBeConfirmed == false) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    isValidPhase().then(setIsValidPhaseResolved);
  }, [isValidPhase]);

  return (
    !isExpertModeEnabled && (
      <>
        <NavBarAction
          icon={NextStepIcon}
          dataFor={buttonId}
          disabled={isDisabled() || !isValidPhaseResolved}
          altText={t('navbar.actions.nextStep')}
          action={onNextButtonClick}
          isEndOfBlock={actionProps?.block?.isEndOfBlock}
        />
        <ReactTooltip id={buttonId} place="bottom" effect="solid" className="tooltip" type="dark">
          {t(`navbar.actions.nextStep`)}
        </ReactTooltip>
      </>
    )
  );
};
