import { useEvergineStore } from 'evergine-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VerticalDivider } from '../../../common/components';
import { CapturePosition, CaptureType, Color, MatchingInfo, Model3dType, Stage } from '../../../common/evergine/types';

import { useCalculateMatchingPoints, useCaseData, useCaseId, useLoadFilesIntoFS } from '../../../hooks';
import { useConfirmMatchingPoints } from '../../../hooks/shared/useConfirmMatchingPoints';
import { useFiles } from '../../../hooks/useFiles';
import { TeethArchPosition } from '../../../models';
import { useBoundStore } from '../../stores/useStore';
import { ImageHeader, ResetFileData } from '../layout';
import { MatchingButtons } from './matchingButtons';

const dynamicModels = 'DynamicModels';
const dynamicModelsFullPath = `/Content/${dynamicModels}`;

export function Matching() {
  const [t] = useTranslation();
  const [caseId] = useCaseId();
  const { patientCase, updatePatientCase, getPatientCase } = useCaseData(caseId);
  const { evergineReady } = useEvergineStore();
  const {
    teethArch,
    isConfirmed,
    setIsConfirmed,
    matchingModelPoints,
    updateMatchingModelPoints,
    setShowCanvas,
    showCanvas
  } = useBoundStore((state) => ({
    teethArch: state.teethArch,
    isConfirmed: state.isConfirmed,
    setIsConfirmed: state.setIsConfirmed,
    matchingModelPoints: state.matchingModelPoints,
    updateMatchingModelPoints: state.updateMatchingModelPoints,
    setShowCanvas: state.setShowCanvas,
    showCanvas: state.showCanvas
  }));

  const [calculateMatchingPoints] = useCalculateMatchingPoints();
  const {
    loadUpperDicomInFS,
    loadUpperModel3DInFS,
    loadLowerDicomInFS,
    loadLowerModel3DInFS,
    patientCaseFilesToMatchingInfo
  } = useLoadFilesIntoFS();
  const matchingModelPoint = useBoundStore((state) => state.matchingModelPoint);
  const [colorKeyActive, setColorKeyActive] = useState<Record<string, Color>>({ ['UPPER']: 0, ['LOWER']: 0 });
  const [isColorsReset, setIsResetColors] = useState<boolean>(false);
  const [isFilesLoaded, setIsFilesLoaded] = useState<boolean>(false);
  useConfirmMatchingPoints(patientCase);

  const { isDir } = useFiles();

  useEffect(() => {
    if (evergineReady) {
      window.App.webEventsProxy.common.setStage(Stage.MatchingSetup);
    }
  }, [evergineReady]);

  useEffect(() => {
    if (evergineReady && patientCase) {
      const loadFiles = async () => {
        if (!isDir(dynamicModelsFullPath)) {
          console.log('Creating directory ', dynamicModelsFullPath);
          Module.FS.mkdir(dynamicModelsFullPath);
          await loadUpperDicomInFS(patientCase);
          await loadUpperModel3DInFS(patientCase);
          await loadLowerDicomInFS(patientCase);
          await loadLowerModel3DInFS(patientCase);
        }

        setIsFilesLoaded(true);
      };

      if (isFilesLoaded) {
        const loadCanvasInfo = async () => {
          setShowCanvas(true);
          const patientCase = await getPatientCase();
          const matchingInfo: Record<string, MatchingInfo> = patientCaseFilesToMatchingInfo(patientCase);

          if (!matchingInfo[teethArch].dicom || !matchingInfo[teethArch].model3D) {
            setShowCanvas(false);
            return;
          }

          window.App.webEventsProxy.common.loadModels([
            {
              id: matchingInfo[teethArch].dicom,
              uri: `DynamicModels/${matchingInfo[teethArch].dicom}`,
              teethArch: teethArch === TeethArchPosition.UPPER ? CapturePosition.UPPER : CapturePosition.LOWER
            },
            {
              id: matchingInfo[teethArch].model3D,
              uri: `DynamicModels/${matchingInfo[teethArch].model3D}`,
              teethArch: teethArch === TeethArchPosition.UPPER ? CapturePosition.UPPER : CapturePosition.LOWER,
              model3dType: Model3dType.Scan
            }
          ]);

          window.App.webEventsProxy.surgeries.loadMatchingInfo(matchingInfo[teethArch]);
        };

        loadCanvasInfo();
      }

      loadFiles();
    }
  }, [evergineReady, patientCase, isFilesLoaded, teethArch]);

  const onClickMatchingButton = (color: Color): void => {
    window.App.webEventsProxy.surgeries.selectMatchingColor(color);
    setColorKeyActive({ ['UPPER']: color, ['LOWER']: color });
  };

  useEffect(() => {
    if (isConfirmed) {
      const update = async () => {
        await updatePatientCase({
          MATCHINGPOINTS: {
            ...matchingModelPoints
          }
        });
      };

      update();
    }

    setIsConfirmed(false);
  }, [isConfirmed]);

  useEffect(() => {
    if (matchingModelPoint !== undefined) {
      const isDICOMPoint = matchingModelPoint.modelId.split('_')[0] === '0';
      const isModel3dPoint = matchingModelPoint.modelId.split('_')[0] === '1';

      updateMatchingModelPoints({
        ...matchingModelPoints,
        [teethArch]: {
          dicom: matchingModelPoints[teethArch].dicom,
          model3D: matchingModelPoints[teethArch].model3D,
          dicomPoints: isDICOMPoint
            ? [...calculateMatchingPoints(CaptureType.DICOM, matchingModelPoint, matchingModelPoints, teethArch)]
            : [...matchingModelPoints[teethArch].dicomPoints],
          model3dPoints: isModel3dPoint
            ? [...calculateMatchingPoints(CaptureType.MODEL3D, matchingModelPoint, matchingModelPoints, teethArch)]
            : [...matchingModelPoints[teethArch].model3dPoints]
        }
      });
    }
  }, [matchingModelPoint]);

  const resetData = async (fileType: string) => {
    setIsResetColors(true);
    /* eslint-disable */
    window.App.webEventsProxy.surgeries.loadMatchingInfo({
      dicom: matchingModelPoints[teethArch].dicom,
      model3D: matchingModelPoints[teethArch].model3D,
      dicomPoints:
        fileType === 'DICOM'
          ? [
              { position: null, color: 0 },
              { position: null, color: 1 },
              { position: null, color: 2 },
              { position: null, color: 3 }
            ]
          : matchingModelPoints[teethArch].dicomPoints,
      model3dPoints:
        fileType === 'MODEL3D'
          ? [
              { position: null, color: 0 },
              { position: null, color: 1 },
              { position: null, color: 2 },
              { position: null, color: 3 }
            ]
          : matchingModelPoints[teethArch].model3dPoints
    });

    updateMatchingModelPoints({
      ...matchingModelPoints,
      [teethArch]: {
        dicom: matchingModelPoints[teethArch].dicom,
        model3D: matchingModelPoints[teethArch].model3D,
        dicomPoints:
          fileType === 'DICOM'
            ? [
                { position: null, color: 0 },
                { position: null, color: 1 },
                { position: null, color: 2 },
                { position: null, color: 3 }
              ]
            : matchingModelPoints[teethArch].dicomPoints,
        model3dPoints:
          fileType === 'MODEL3D'
            ? [
                { position: null, color: 0 },
                { position: null, color: 1 },
                { position: null, color: 2 },
                { position: null, color: 3 }
              ]
            : matchingModelPoints[teethArch].model3dPoints
      }
    });
    /* eslint-enable */
  };

  return (
    <div className="matching container-fluid g-0 d-flex flex-column">
      {teethArch === TeethArchPosition.UPPER ? (
        <div className="flex-fill">
          <div className="d-flex">
            <ImageHeader title={t(`dicomCaptures.topTitle`)}>
              <ResetFileData resetFileData={() => resetData('DICOM')} />
            </ImageHeader>
            {(!evergineReady || !showCanvas) && (
              <div className="position-absolute top-50 w-50 d-flex justify-content-center">
                <div className="section-message">
                  <span>{t(`imageManagement.common.noFiles`)}</span>
                </div>
              </div>
            )}
            <ImageHeader title={t(`stlCaptures.topTitle`)}>
              <ResetFileData resetFileData={() => resetData('MODEL3D')} />
            </ImageHeader>
            {(!evergineReady || !showCanvas) && (
              <div className="position-absolute top-50 w-50 end-0 d-flex justify-content-center">
                <div className="section-message">
                  <span>{t(`imageManagement.common.noFiles`)}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex-fill">
          <div className="d-flex">
            <ImageHeader title={t(`dicomCaptures.lowerTitle`)}>
              <ResetFileData resetFileData={() => resetData('DICOM')} />
            </ImageHeader>
            {(!evergineReady || !showCanvas) && (
              <div className="position-absolute top-50 w-50 d-flex justify-content-center">
                <div className="section-message">
                  <span>{t(`imageManagement.common.noFiles`)}</span>
                </div>
              </div>
            )}
            <ImageHeader title={t(`stlCaptures.lowerTitle`)}>
              <ResetFileData resetFileData={() => resetData('MODEL3D')} />
            </ImageHeader>
            {(!evergineReady || !showCanvas) && (
              <div className="position-absolute top-50 w-50 end-0 d-flex justify-content-center">
                <div className="section-message">
                  <span>{t(`imageManagement.common.noFiles`)}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {evergineReady && showCanvas && (
        <MatchingButtons
          teethArchPosition={teethArch}
          onClickMatchingButton={onClickMatchingButton}
          isColorsReset={isColorsReset}
          setIsResetColors={setIsResetColors}
          colorKeyActive={colorKeyActive}
          setColorKeyActive={setColorKeyActive}
        />
      )}
      <VerticalDivider />
    </div>
  );
}
