import { Backdrop, Box } from '@mui/material';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonPagesUrl } from '../../types';
import { ErrorBoundaryMessage, InfoMessage, NavBar, Spinner } from '..';
import EvergineAppCanvas from '../evergine/evergineAppCanvas/EvergineAppCanvas';
import { useCaseId, useCaseType, useCalculateRoutes, useCaseData } from '../../hooks';
import { useCanvasSize } from '../../hooks/shared/useCanvasSize';
import { PathLevels } from '../../models';
import { OrthodonticsPagesUrl } from '../../types';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import {
  Modal,
  ModalAutoClosed,
  ModalClientValidationsCancelChanges,
  ModalClientValidationsEdit,
  ModalClientValidationsReject,
  ModalClientValidationsSendChanges,
  ModalClientValidationsValidate,
  ModalConfirmDeleteVersion,
  ModalDeleteAttach,
  ModalDownloadCase,
  ModalError,
  ModalImplant,
  ModalNextStep,
  ModalNextStepDirect,
  ModalNoMissingTeethInformation,
  ModalOnlyOneFileInformation,
  ModalPreviousCasePhaseModified,
  ModalPreviousStep,
  ModalPublishCase,
  ModalTypes,
  ModalStlHasNotBeenEdited,
  ModalProccesingRealRoots,
  ModalDeleteLabel,
  ModalGumsHaveNotBeenEdited,
  ModalDeleteTAD,
  ModalTreatmentPhaseChecks,
  ModalAddTAD,
  ModalAddLabel,
  ModalAddAttach
} from '../../surgeries/components/layout';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { SurgeryPagesUrl } from '../../surgeries/types';
import { useShallow } from 'zustand/react/shallow';

const allowedPagesToShowCanvas: string[] = [
  CommonPagesUrl.DicomCaptures,
  CommonPagesUrl.StlCaptures,
  SurgeryPagesUrl.Matching,
  SurgeryPagesUrl.ResultMatching,
  SurgeryPagesUrl.Implants,
  OrthodonticsPagesUrl.MeshCorrection,
  OrthodonticsPagesUrl.DentalMovements,
  OrthodonticsPagesUrl.StlEdition,
  OrthodonticsPagesUrl.TeethSegmentation,
  OrthodonticsPagesUrl.AxisAndRoots,
  OrthodonticsPagesUrl.Treatment,
  OrthodonticsPagesUrl.Publish
];

const pagesWithoutNavbar: string[] = [CommonPagesUrl.Forbidden, CommonPagesUrl.NotFound];

const pagesInfoMessage = [
  OrthodonticsPagesUrl.StlEdition,
  OrthodonticsPagesUrl.TeethSegmentation,
  OrthodonticsPagesUrl.AxisAndRoots,
  OrthodonticsPagesUrl.Treatment
];

export function AppGuard({ children }: { children: ReactNode }) {
  const { evergineBusy, showCanvas, webBusy, webBusyMessage, messageInfo, versions, isWebAssemblySupported } =
    useBoundStore(
      useShallow((state) => ({
        evergineBusy: state.evergineBusy,
        showCanvas: state.showCanvas,
        webBusy: state.webBusy,
        webBusyMessage: state.webBusyMessage,
        messageInfo: state.messageInfo,
        versions: state.versions,
        isWebAssemblySupported: state.isWebAssemblySupported
      }))
    );

  const { setIsOrthContextMenuVisible, setPointerPosition } = useOrthBoundStore(
    useShallow((state) => ({
      setIsOrthContextMenuVisible: state.setIsOrthContextMenuVisible,
      setPointerPosition: state.setPointerPosition
    }))
  );

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [caseId] = useCaseId();
  const caseType = useCaseType();
  const [calculateNextRoute, calculatePreviousRoute] = useCalculateRoutes(pathname, caseId, caseType);

  const [t] = useTranslation();
  const { fetchPatientCaseVersions } = useCaseData(caseId);

  const currentRoute = useMemo(() => `/${pathname.split('/')[PathLevels.RouteView]}`, [pathname]);
  const canvasSize = useCanvasSize(pathname, currentRoute);

  useEffect(() => {
    const isNavigatorCompatible = checkSimdSupport() && isWebAssemblySupported;

    if (!isNavigatorCompatible) {
      navigate(CommonPagesUrl.Incompatible);
    }
  }, [navigate, isWebAssemblySupported]);

  useEffect(() => {
    if (!versions) {
      fetchPatientCaseVersions();
    }
  }, [fetchPatientCaseVersions, versions]);

  const handleContextMenu = useCallback(
    (e: any) => {
      const position = { clientX: e.clientX, clientY: e.clientY };
      setPointerPosition(position);
      setIsOrthContextMenuVisible(true);
    },
    [setIsOrthContextMenuVisible, setPointerPosition]
  );

  const showInfoMessageIfNeeded = useMemo(() => {
    const isPageWithInfoMessage = pagesInfoMessage.map(String).includes(currentRoute);
    return isPageWithInfoMessage && <InfoMessage message={t(messageInfo)} />;
  }, [currentRoute, t, messageInfo]);

  const showNavbarIfAllowed = useMemo(() => {
    const isPageWithoutNavbar = pagesWithoutNavbar.map(String).includes(pathname);
    return !isPageWithoutNavbar && <NavBar />;
  }, [pathname]);

  const checkSimdSupport = async () => {
    if (typeof WebAssembly === 'object' && typeof WebAssembly.validate === 'function') {
      const base64ToUint8Array = (base64: string) => {
        const binaryString = atob(base64);
        const binaryLength = binaryString.length;
        const bytes = new Uint8Array(binaryLength);
        for (let i = 0; i < binaryLength; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
      };

      const simdModule = `(module
        (memory 1)
        (func (result v128)
          (v128.const i32x4 0 0 0 0)
        )
      )`;

      try {
        const supported = WebAssembly.validate(base64ToUint8Array('AGFzbQEAAAABBgFgAEEAAQ=='));
        return supported;
      } catch (error) {
        return false;
      }
    }
  };

  const displayCanvas = useMemo(
    () => showCanvas && allowedPagesToShowCanvas.includes(`/${pathname.split('/')[PathLevels.RouteView]}`),
    [pathname, showCanvas]
  );

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorBoundaryMessage}>{showNavbarIfAllowed}</ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorBoundaryMessage}>
        <Modal>
          <ModalNextStep key={ModalTypes.ModalNextStep} nextRoute={calculateNextRoute} />
          <ModalNextStepDirect key={ModalTypes.ModalNextStepDirect} nextRoute={calculateNextRoute} />
          <ModalPreviousStep key={ModalTypes.ModalPreviousStep} previousRoute={calculatePreviousRoute} />
          <ModalError key={ModalTypes.ModalError} />
          <ModalImplant key={ModalTypes.ModalImplant} />
          <ModalClientValidationsValidate key={ModalTypes.ModalClientValidationsValidate} />
          <ModalClientValidationsEdit key={ModalTypes.ModalClientValidationsEdit} />
          <ModalClientValidationsReject key={ModalTypes.ModalClientValidationsReject} />
          <ModalConfirmDeleteVersion key={ModalTypes.ModalConfirmDeleteVersion} />
          <ModalClientValidationsSendChanges key={ModalTypes.ModalClientValidationsSendChanges} />
          <ModalAutoClosed key={ModalTypes.ModalAutoClosed} />
          <ModalClientValidationsCancelChanges key={ModalTypes.ModalClientValidationsCancelChanges} />
          <ModalOnlyOneFileInformation key={ModalTypes.ModalOnlyOneFileInformation} nextRoute={calculateNextRoute} />
          <ModalPreviousCasePhaseModified key={ModalTypes.ModalPreviousCasePhaseModified} />
          <ModalNoMissingTeethInformation key={ModalTypes.ModalNoMissingTeethInformation} />
          <ModalPublishCase key={ModalTypes.ModalPublishCase} />
          <ModalDownloadCase key={ModalTypes.ModalDownloadCase} />
          <ModalDeleteAttach key={ModalTypes.ModalDeleteAttach} />
          <ModalDeleteTAD key={ModalTypes.ModalDeleteTAD} />
          <ModalAddAttach key={ModalTypes.ModalAddAttach} />
          <ModalAddTAD key={ModalTypes.ModalAddTAD} />
          <ModalAddLabel key={ModalTypes.ModalAddLabel} />
          <ModalDeleteLabel key={ModalTypes.ModalDeleteLabel} />
          <ModalTreatmentPhaseChecks key={ModalTypes.ModalTreatmentPhaseChecks} />
          <ModalStlHasNotBeenEdited key={ModalTypes.ModalStlHasNotBeenEdited} nextRoute={calculateNextRoute} />
          <ModalProccesingRealRoots key={ModalTypes.ModalProccesingRealRoots} nextRoute={calculateNextRoute} />
          <ModalGumsHaveNotBeenEdited key={ModalTypes.ModalGumsHaveNotBeenEdited} nextRoute={calculateNextRoute} />
        </Modal>

        {children}
      </ErrorBoundary>

      <div className="section-content d-flex align-items-center justify-content-center">
        <Backdrop style={{ zIndex: 1000 }} open={evergineBusy || webBusy}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <Spinner />
            {webBusyMessage && webBusyMessage.length > 0 && <div className="loading-text">{webBusyMessage}</div>}
          </Box>
        </Backdrop>

        {displayCanvas && <EvergineAppCanvas canvasSize={canvasSize} handleContextMenu={handleContextMenu} />}
      </div>
      {showInfoMessageIfNeeded}
    </>
  );
}
