import { useContext, useMemo, useState } from 'react';
import { AbilityAction, AbilityContext, OrthoAbilitySubject } from '../../shared';

export function useGeneralPanels() {
  const [evolutionPanelWidth, setEvolutionPanelWidth] = useState<number>();
  const getWidthFromEvolutionPanel = (getWidthFunction: () => number) => {
    const width = getWidthFunction();
    setEvolutionPanelWidth(width);
  };

  const ability = useContext(AbilityContext);

  const showLateralPanelIfAllowed = useMemo(() => {
    if (!ability || ability.can(AbilityAction.View, OrthoAbilitySubject.InfoPanel)) {
      return true;
    }
    return false;
  }, [ability]);

  return {
    getWidthFromEvolutionPanel,
    evolutionPanelWidth,
    showLateralPanelIfAllowed
  };
}
