import { useCallback, useMemo } from 'react';
import { TableDataValues, TableMovementType } from './movementsTable.types';
import { MovementsTableCell } from './MovementsTableCell';

interface MovementsTableRowProps {
  pieceNumber: number;
  tableData: TableDataValues[];
  shouldBeWarned: (piece: TableDataValues, movementKey: TableMovementType) => boolean;
}

export function MovementsTableRow({ pieceNumber, tableData, shouldBeWarned }: MovementsTableRowProps) {
  const piece = tableData.find((item) => item.id === pieceNumber);
  const movementsCells = useMemo(() => {
    if (!piece) {
      return;
    }

    return Object.values(TableMovementType).map((movementKey) => {
      const isExceededValue = shouldBeWarned(piece, movementKey);
      return (
        <MovementsTableCell
          key={`piece-${pieceNumber}-movement-${movementKey}`}
          value={piece.value[movementKey]}
          pieceNumber={pieceNumber}
          movementType={movementKey}
          exceeded={isExceededValue}
        />
      );
    });
  }, [piece, pieceNumber, shouldBeWarned]);

  return (
    <div className="movementstable-row">
      <div className="movementstable-piece-number">{pieceNumber}</div>
      {movementsCells}
    </div>
  );
}
