import { useEffect, useMemo, useRef, useState } from 'react';
import Form from 'react-bootstrap/esm/Form';
import { useTranslation } from 'react-i18next';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useHistoryTreatmentChanges, useStepsManager } from '../../../../../hooks/orthodontics';
import { useTreatmentSuggestedChanges } from '../../../../../hooks/orthodontics/useTreatmentSuggestedChanges';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';
import { ModalTypes } from './modal-types.enum';
import Radio from '../../../../../common/components/radio/Radio';
import { usePublishCase } from '../../../../../hooks/shared/usePublishCase';
import { useExpertMode } from '../../../../../hooks/shared/useExpertMode';

import './modalClientValidationsSendChanges.scss';
import { useCaseId, useChat } from '../../../../../hooks';
import { ClientPlanValidationEnum } from '../../../../../shared';

export type RequestedChangeToShow = {
  request: string;
  pieceIdentifier: string;
};

export function ModalClientValidationsSendChanges() {
  const setShowMovements = useOrthBoundStore((state) => state.setShowMovements);
  const upperDentalMovements = useOrthBoundStore((state) => state.upperDentalMovements);
  const lowerDentalMovements = useOrthBoundStore((state) => state.lowerDentalMovements);
  const toggleModalIsOpened = useCommonBoundStore((state) => state.toggleModalIsOpened);
  const setIsModalXL = useCommonBoundStore((state) => state.setIsModalXL);
  const setModalMessage = useCommonBoundStore((state) => state.setModalMessage);
  const setModalTitle = useCommonBoundStore((state) => state.setModalTitle);
  const setIsNewStepClientModification = useCommonBoundStore((state) => state.setIsNewStepClientModification);
  const setIsThereFeedbackForTreatment = useCommonBoundStore((state) => state.setIsThereFeedbackForTreatment);
  const isExpertModeEnabled = useCommonBoundStore((state) => state.isExpertModeEnabled);

  const [t] = useTranslation();
  const [caseId] = useCaseId();
  const { getTreatmentChangesToShow } = useTreatmentSuggestedChanges();
  const { sendClientPlanValidation, sendClientPublishPlanValidation } = useHistoryTreatmentChanges();
  const changesToShow = getTreatmentChangesToShow();
  const { goToStep } = useStepsManager();
  const { publishCase } = usePublishCase();
  const { cancelExpertMode } = useExpertMode();
  const { sendChatMessage } = useChat(caseId);

  const [isChangesScrollVisible, setIsChangesScrollVisible] = useState<boolean>();
  const [comments, setComments] = useState<string>();
  const [reviewChanges, setReviewChanges] = useState<boolean>(null);

  const maxStoreStepsLength = useMemo(
    () => Math.max(upperDentalMovements?.steps?.length || 0, lowerDentalMovements?.steps?.length || 0),
    [upperDentalMovements, lowerDentalMovements]
  );

  useEffect(() => {
    setIsModalXL(true);

    return () => {
      setIsModalXL(false);
    };
  }, []);

  const formRef = useRef<HTMLFormElement>();
  const changesRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!changesRef.current) {
      return;
    }

    const isScrollVisible = changesRef.current?.clientHeight < changesRef.current?.scrollHeight;
    setIsChangesScrollVisible(isScrollVisible);
  }, [changesRef, changesToShow]);

  const onCloseButtonClick = () => {
    setReviewChanges(null);
    setComments('');
    toggleModalIsOpened('');
  };

  const handleSaveAndSend = async (expertMode: boolean) => {
    try {
      toggleModalIsOpened('');

      if (expertMode && reviewChanges === false) {
        await sendClientPublishPlanValidation();
        await publishCase();
        sendChatMessage(ClientPlanValidationEnum.AcceptedChangesExpertMode);
        setModalTitle(t('modal.clientValidation.send.publishChangesOKTitle'));
        setModalMessage(t('modal.clientValidation.send.publishChangesOKMessage'));
      } else {
        await sendClientPlanValidation(comments);
        setModalTitle(t('modal.clientValidation.send.requestedChangesOKTitle'));
        setModalMessage(t('modal.clientValidation.send.requestedChangesOKMessage'));
      }

      toggleModalIsOpened(ModalTypes.ModalAutoClosed);
      setIsNewStepClientModification(false);
      window.App.webEventsProxy.common.setEditionMode(false);
      setShowMovements(false);
      goToStep(maxStoreStepsLength - 2, false);
      setIsThereFeedbackForTreatment(true);

      if (expertMode) {
        cancelExpertMode();
      }
    } catch (error) {
      setModalTitle(t('modal.clientValidation.send.requestedChangesKOTitle'));
      setModalMessage(t('modal.clientValidation.send.requestedChangesKOMessage'));
      toggleModalIsOpened(ModalTypes.ModalError);
    }
  };

  const handleReviewChangesSelection = (selectedOption: boolean) => {
    if (selectedOption === false) setComments('');
    setReviewChanges(selectedOption);
  };

  return (
    <div className="modal-content" data-testid="modal-client-validations-send">
      <div className="modal-body">
        <h3 className="modal-title mb-4">
          {t(isExpertModeEnabled ? 'modal.clientValidation.send.expertModeTitle' : 'modal.clientValidation.send.title')}
        </h3>
        <div className="sendchanges">
          <div className="sendchanges-column">
            <p className="modal-text mb-3 sendchanges-title">
              {t(
                isExpertModeEnabled
                  ? 'modal.clientValidation.send.expertModeDescription'
                  : 'modal.clientValidation.send.description'
              )}
            </p>

            <Form ref={formRef}>
              {isExpertModeEnabled ? (
                <>
                  <Form.Group>
                    <Radio
                      label={t('modal.clientValidation.send.expertModeNoReviewChanges')}
                      active={reviewChanges === false}
                      callBack={() => handleReviewChangesSelection(false)}
                      expanded={false}
                    />
                    <Radio
                      label={t('modal.clientValidation.send.expertModeReviewChanges')}
                      active={reviewChanges === true}
                      callBack={() => handleReviewChangesSelection(true)}
                      expanded={false}
                    />
                  </Form.Group>
                  {reviewChanges === true && (
                    <Form.Group>
                      <Form.Label className="modal-label padding-top-xs">
                        {t('modal.clientValidation.send.textAreaTitle')}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={10}
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                      />
                    </Form.Group>
                  )}
                </>
              ) : (
                <Form.Group>
                  <Form.Label className="modal-label">{t('modal.clientValidation.send.textAreaTitle')}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={10}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </Form.Group>
              )}
            </Form>

            <div className="sendchanges-actions">
              <button
                onClick={onCloseButtonClick}
                type="button"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
              >
                {t('modal.actions.cancel')}
              </button>
              <button
                onClick={() => handleSaveAndSend(isExpertModeEnabled)}
                disabled={isExpertModeEnabled ? reviewChanges === null : changesToShow.length === 0}
                type="button"
                className="btn btn-primary"
              >
                {t('modal.actions.saveAndSend')}
              </button>
            </div>
          </div>

          {!isExpertModeEnabled && (
            <div className={`sendchanges-column sendchanges-changes ${isChangesScrollVisible ? 'is-scroll' : ''}`}>
              <div
                className={`sendchanges-changes__change sendchanges-changes__header ${
                  isChangesScrollVisible ? 'is-scroll' : ''
                }`}
              >
                <div>{t('modal.clientValidation.send.requestedModification')}</div>
                <div>{t('modal.clientValidation.send.piece')}</div>
              </div>
              <div className="sendchanges-changes__body" ref={changesRef}>
                {changesToShow.map((change, i) => (
                  <div
                    className="sendchanges-changes__change"
                    key={`sendchanges-change-${i}-${change.pieceIdentifier}`}
                  >
                    <div>{change.request}</div>
                    <div>{change.pieceIdentifier}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
