import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { INJECTED_TYPES, container } from '../common';
import { IErrorHandlerService } from '../shared';

export function useFiles() {
  const errorHandlerService = container.get<IErrorHandlerService>(INJECTED_TYPES.IErrorHandlerService);
  const [t] = useTranslation();

  const isDir = useCallback((path: string) => {
    try {
      const { mode: pathMode } = Module.FS.lookupPath(path).node;

      return Module.FS.isDir(pathMode);
    } catch {
      return false;
    }
  }, []);

  const isFile = (path: string) => {
    try {
      Module.FS.readFile(path);
      return true;
    } catch {
      return false;
    }
  };

  const deleteFile = (path: string) => {
    if (!isFile(path)) {
      return;
    }

    Module.FS.unlink(path);
  };

  const deleteDir = (dirPath: string) => {
    if (!isDir(dirPath)) {
      return;
    }

    Module.FS.readdir(dirPath)
      .filter((resource) => resource !== '.' && resource !== '..')
      .forEach((path: string) => {
        const absolutePath = `${dirPath}/${path}`;
        if (isDir(absolutePath)) {
          deleteDir(absolutePath);
        } else {
          deleteFile(absolutePath);
        }
      });
    Module.FS.rmdir(dirPath);
  };

  const removeResourcesByName = (resourceName: string, parentDirectory: string) => {
    if (!isDir(parentDirectory)) {
      return;
    }

    try {
      Module.FS.readdir(parentDirectory)
        .filter((resource) => resource.startsWith(resourceName))
        .forEach((path: string) => {
          const absolutePath = `${parentDirectory}/${path}`;
          if (isDir(absolutePath)) {
            deleteDir(absolutePath);
          } else {
            deleteFile(absolutePath);
          }
        });
    } catch (error) {
      console.error(error);
      errorHandlerService.showError(t('errors.removeFilesError'));
    }
  };

  const downloadFile = (path: string, fileNameToDownload: string) => {
    try {
      const data = Module.FS.readFile(path);
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileNameToDownload;
      link.style.display = 'none';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
      errorHandlerService.showError(t('errors.downloadFileError'));
    }
  };

  return { isDir, isFile, deleteFile, deleteDir, removeResourcesByName, downloadFile };
}
