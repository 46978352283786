import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface LoadingTextProps {
  text?: string;
}

export const LoadingText: FC<LoadingTextProps> = ({ text }) => {
  const [t] = useTranslation();

  return <span className={'sr-only'}>{text ?? t('common.loaderActions.loading')}</span>;
};
