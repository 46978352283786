import { Step } from '../../common';
import { PhaseByUrl } from '../../orthodontics/components/layout';

export const stepSorter = (prevStep: Step, nextStep: Step) => {
  return prevStep.stepIndex - nextStep.stepIndex;
};

export const getCurrentPhaseStageByRoute = () => {
  let currentPath = location.pathname;
  currentPath = currentPath.split('/')[2];
  currentPath = PhaseByUrl[currentPath];
  return currentPath;
};
