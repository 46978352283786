import { TableMovementType } from './movementsTable.types';
import { ReactComponent as MDTraslationIcon } from '../../../../assets/icons/MD-traslation.svg';
import { ReactComponent as TIPAngleIcon } from '../../../../assets/icons/TIP-angle.svg';
import { ReactComponent as VLTraslationIcon } from '../../../../assets/icons/VL-traslation.svg';
import { ReactComponent as ExtrusionIntrusionIcon } from '../../../../assets/icons/extrusion-intrusion.svg';
import { ReactComponent as PureRotationIcon } from '../../../../assets/icons/pure-rotation.svg';
import { ReactComponent as TorqueIcon } from '../../../../assets/icons/torque.svg';

interface MovementsTableHeaderProps {
  isScrollVisible: boolean;
}

const tableMovementIcons: Record<TableMovementType, React.ComponentType> = {
  [TableMovementType.ExtrusionIntrusion]: ExtrusionIntrusionIcon,
  [TableMovementType.TranslationVL]: VLTraslationIcon,
  [TableMovementType.TranslationMD]: MDTraslationIcon,
  [TableMovementType.PureRotation]: PureRotationIcon,
  [TableMovementType.Torque]: TorqueIcon,
  [TableMovementType.TIP]: TIPAngleIcon
};

export function MovementsTableHeader({ isScrollVisible }: MovementsTableHeaderProps) {
  return (
    <div className={`movementstable-header ${isScrollVisible ? 'is-scroll' : ''}`}>
      {(Object.values(TableMovementType) as TableMovementType[]).map((type) => {
        const MovementIcon = tableMovementIcons[type];
        return <MovementIcon key={type} />;
      })}
    </div>
  );
}
