export const isStringVoid = (str: string | undefined | null): boolean =>
  str === undefined || str === null || str.trim() === '';
export const isBlobURL = (str: string): boolean => str.startsWith('blob:');
export const blobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
};

export const areObjectsEqual = (obj1: any, obj2: any): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const fixFloat = (value: number, digits = 1) => {
  if (typeof value !== 'number' || isNaN(value)) {
    return 0;
  }
  return parseFloat(value.toFixed(digits));
};

export const fixFloatFloor = (value: number, digits = 1) => {
  if (typeof value !== 'number' || isNaN(value)) {
    return 0;
  }

  const factor = Math.pow(10, digits);
  return Math.floor(value * factor) / factor;
};
