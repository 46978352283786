import { ReactComponent as GoToNextIcon } from '../../../assets/icons/player-next.svg';
import { ReactComponent as GoToPreviousIcon } from '../../../assets/icons/player-previous.svg';
import { ReactComponent as EllipsisIcon } from '../../../assets/icons/ellipsis.svg';

import './evolutionPanelNavigator.scss';
import React from 'react';
import { useEvolutionPanelNavigator } from '../../../hooks';
import { EvolutionStep } from './EvolutionPanel';

interface EvolutionPanelNavigatorProps {
  steps: EvolutionStep[];
}

function EvolutionPanelNavigator({ steps }: EvolutionPanelNavigatorProps) {
  const lastStepIndex = steps[steps.length - 1].index;

  const { activeStep, isFirstStep, isLastStep, goToPreviousStep, goToNextStep, goToFirstStep, goToLastStep } =
    useEvolutionPanelNavigator(steps);

  return (
    <div className="evolutionpanelnavigator">
      <div
        className={`evolutionpanelnavigator-control evolutionpanelnavigator-index ${
          isFirstStep() ? 'disabled' : 'enabled'
        }`}
        onClick={goToFirstStep}
      >
        0
      </div>
      <div>
        <EllipsisIcon />
      </div>
      <div
        className={`evolutionpanelnavigator-control ${isFirstStep() ? 'disabled' : 'enabled'}`}
        onClick={goToPreviousStep}
      >
        <GoToPreviousIcon />
      </div>
      <div>{activeStep}</div>
      <div
        className={`evolutionpanelnavigator-control ${isLastStep() ? 'disabled' : 'enabled'}`}
        onClick={goToNextStep}
      >
        <GoToNextIcon />
      </div>
      <div>
        <EllipsisIcon />
      </div>
      <div
        className={`evolutionpanelnavigator-control evolutionpanelnavigator-index ${
          isLastStep() ? 'disabled' : 'enabled'
        }`}
        onClick={goToLastStep}
      >
        {lastStepIndex}
      </div>
    </div>
  );
}

export default React.memo(EvolutionPanelNavigator);
