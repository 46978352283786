import { useCallback } from 'react';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useUtils } from '../../shared';
import { PhaseChangeValidationHook } from './usePhaseChangeValidation';
import { useCommonBoundStore } from '../../../common/stores/useStore';

export function useValidateTeethSegmentation(): PhaseChangeValidationHook {
  const isSegmentationApplied = useOrthBoundStore((state) => state.isSegmentationApplied);
  const { getCurrentPhaseStageByRoute } = useUtils();
  const caseStatusFromStore = useOrthBoundStore((state) => state.caseStatus);
  const isCaseReseting = useCommonBoundStore((state) => state.isCaseReseting);

  const getPhaseCompletionDate = useCallback(
    (phaseKey: string) => {
      if (!caseStatusFromStore) {
        return;
      }
      const phase = caseStatusFromStore.phases.find((p) => p.name === phaseKey);
      return phase && phase.completionDate ? new Date(phase.completionDate).toLocaleDateString('es-es') : '';
    },
    [caseStatusFromStore]
  );

  const hasPhaseCompletionDate = useCallback((): boolean => {
    const currentPhase = getCurrentPhaseStageByRoute();
    if (currentPhase === undefined) {
      return false;
    }
    const completionDate = getPhaseCompletionDate(currentPhase);

    return completionDate !== '';
  }, [getPhaseCompletionDate]);

  return {
    isValidPhase: async (): Promise<boolean> => {
      return Promise.resolve((isSegmentationApplied && !isCaseReseting) || hasPhaseCompletionDate());
    }
  };
}
