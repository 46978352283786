import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Grid from '../../../../../assets/icons/evergineTools/grid.svg';
import { NavBarAction, Split } from '../../../../../common';
import { useBoundStore } from '../../../../../surgeries/stores/useStore';
import { useFocusCanvas } from '../../../../../hooks/shared/useFocusCanvas';

export const ShowGridAction = () => {
  const [t] = useTranslation();
  const [showGrid, setShowGrid] = useState(false);
  const splitMode = useBoundStore((store) => store.splitMode);
  const { setFocusOnCanvas } = useFocusCanvas();

  const toggleGrid = useCallback(() => {
    const newValue = !showGrid;

    window.App.webEventsProxy.layers.showGrid(newValue);

    setShowGrid(newValue);
    setFocusOnCanvas();
  }, [showGrid, splitMode]);

  const buttonId = 'toggleGrid';

  return (
    <>
      <NavBarAction icon={Grid} dataFor={buttonId} altText={t('navbar.actions.grid')} action={toggleGrid} />
      <ReactTooltip id={buttonId} place="bottom" effect="solid" className="tooltip" type="dark">
        {t(`navbar.actions.information.grid`)}
      </ReactTooltip>
    </>
  );
};
