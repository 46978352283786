import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Undo from '../../../../../assets/icons/undo.svg';
import { INJECTED_TYPES, NavBarAction, container } from '../../../../../common';
import { ICommandInvoker } from '../../../../../shared';
import { useOrthBoundStore } from '../../../../stores/useStore';

export default () => {
  const [t] = useTranslation();
  const commandInvokerService = container.get<ICommandInvoker>(INJECTED_TYPES.ICommandInvokerService);
  const canUndo = useOrthBoundStore((store) => store.canUndo);
  const [isUndoing, setIsUndoing] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && (event.key === 'z' || event.key === 'Z')) {
        if (canUndo && !isUndoing) {
          handleUndoClick();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [canUndo]);

  const handleUndoClick = useCallback(async () => {
    if (!isUndoing) {
      setIsUndoing(true);
      try {
        await commandInvokerService.undo();
      } finally {
        setIsUndoing(false);
      }
    }
  }, [commandInvokerService, isUndoing]);

  const buttonId = 'undo';

  return (
    <>
      <NavBarAction
        icon={Undo}
        dataFor={buttonId}
        altText={t('navbar.actions.undo')}
        action={handleUndoClick}
        disabled={!canUndo || isUndoing}
      />

      <ReactTooltip id={buttonId} place="bottom" effect="solid" className="tooltip" type="dark">
        {t('navbar.actions.undo')}
      </ReactTooltip>
    </>
  );
};
