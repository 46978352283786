import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTreatmentStepsManager } from './useTreatmentStepsManager';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { ReactComponent as IconDelete } from '../../../assets/icons/delete.svg';
import { ReactComponent as IconPlus } from '../../../assets/icons/plus.svg';
import { ReactComponent as IconReset } from '../../../assets/icons/reset.svg';
import { ResetArch, Step } from '../../../common';
import { useTimelineStepsManager } from './useTimelineManager';
import { useToothTransformStore } from '../../../orthodontics/stores/useToothTransformStore';
import { AbilityAction, AbilityContext, OrthoAbilitySubject } from '../../../shared';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useShallow } from 'zustand/react/shallow';
import { useStepsManager } from '../useStepsManager';

const getStepKeyIndexesFromSteps = (steps: Step[]) =>
  steps.filter((s) => s.isKeyStep === true).map((ks) => ks.stepIndex);

export function useIntermediateSteps(disableTreatmentEffects = false) {
  const ability = useContext(AbilityContext);
  const {
    areMadeChangesOnTeeth,
    setUpperCanAskAIInterpolation,
    setLowerCanAskAIInterpolation,
    setAreMadeChangesOnTeeth,
    setIsRecalculateCancelled
  } = useOrthBoundStore(
    useShallow((state) => ({
      areMadeChangesOnTeeth: state.areMadeChangesOnTeeth,
      setAreMadeChangesOnTeeth: state.setAreMadeChangesOnTeeth,
      setIsRecalculateCancelled: state.setIsRecalculateCancelled,
      setUpperCanAskAIInterpolation: state.setUpperCanAskAIInterpolation,
      setLowerCanAskAIInterpolation: state.setLowerCanAskAIInterpolation
    }))
  );
  const isExpertModeEnabled = useCommonBoundStore((state) => state.isExpertModeEnabled);

  const setWebBusy = useBoundStore((state) => state.setWebBusy);

  const hasTeethBeenTransformed = useToothTransformStore((state) => state.hasTeethBeenTransformed);
  const setHasTeethBeenTransformed = useToothTransformStore((state) => state.setHasTeethBeenTransformed);

  const { stepsModifiedWithNoAutoRecalcIndexes, setStepToNavigate, updateMovements } =
    useTreatmentStepsManager(disableTreatmentEffects);

  const {
    upperSteps,
    lowerSteps,
    activeStep,
    lastStepIndex,
    selectedStepIndexes,
    setActiveStep,
    setSelectedStepIndexes
  } = useTimelineStepsManager();

  const { goToStep } = useStepsManager();

  const keyStepIndexes = useMemo(() => {
    // WORKAROUND debido a que si hay cambios en una sóla arcada, en la otra siguen viniendo como keysteps los stepindex 0 y 1
    const upperStepsKeyIndexes = upperSteps?.length > 2 ? getStepKeyIndexesFromSteps(upperSteps) : [];
    const lowerStepsKeyIndexes = lowerSteps?.length > 2 ? getStepKeyIndexesFromSteps(lowerSteps) : [];

    if (upperStepsKeyIndexes.length >= 2 || lowerStepsKeyIndexes.length >= 2) {
      return Array.from(new Set([...upperStepsKeyIndexes, ...lowerStepsKeyIndexes]));
    }

    return [0, 1];
  }, [upperSteps, lowerSteps]);

  /*useEffect(() => {
    if (!stepsModifiedWithNoAutoRecalcIndexes && !selectedStepIndexes) {
      return;
    }

    setCanAskAIInterpolation(
      stepsModifiedWithNoAutoRecalcIndexes.length > 0 || selectedStepIndexes.length === 2 || areMadeChangesOnTeeth
    );
  }, [
    stepsModifiedWithNoAutoRecalcIndexes,
    selectedStepIndexes,
    setUpperCanAskAIInterpolation,
    setLowerCanAskAIInterpolation,
    areMadeChangesOnTeeth
  ]);*/

  const addStepAction = useCallback(async (): Promise<void> => {
    if (selectedStepIndexes.length === 0) {
      return;
    }

    const stepIndexToBeCloned = selectedStepIndexes[0];

    await window.App.webEventsProxy.movements.addNewStep(stepIndexToBeCloned);
    updateMovements();
    const newStepIndex = stepIndexToBeCloned + 1;
    setSelectedStepIndexes([newStepIndex]);
    setActiveStep(newStepIndex);
  }, [selectedStepIndexes, updateMovements, setSelectedStepIndexes, setActiveStep]);

  const removeSteps = useCallback(
    async (stepIndexes: number[]): Promise<void> => {
      if (!stepIndexes || stepIndexes.length === 0 || stepIndexes.includes(0)) {
        return;
      }
      await window.App.webEventsProxy.movements.removeStep(selectedStepIndexes[0]);
      updateMovements();

      const newStepIndex =
        lastStepIndex > 0 && lastStepIndex == selectedStepIndexes[0] ? lastStepIndex - 1 : selectedStepIndexes[0];

      setSelectedStepIndexes([newStepIndex]);

      goToStep(newStepIndex, false);
    },
    [selectedStepIndexes, updateMovements, lastStepIndex, setSelectedStepIndexes]
  );

  const removeStepsAction = useCallback(async (): Promise<void> => {
    if (selectedStepIndexes.length === 0) {
      return;
    }

    setWebBusy(true);
    await removeSteps(selectedStepIndexes);
    setWebBusy(false);
  }, [selectedStepIndexes, setWebBusy, removeSteps]);

  const resetMovementsAction = useCallback(() => {
    window.App.webEventsProxy.movements.resetArch(ResetArch.BOTH);
    setHasTeethBeenTransformed(false);
    setAreMadeChangesOnTeeth(false);
  }, [setAreMadeChangesOnTeeth, setHasTeethBeenTransformed]);

  const handleModeRecalculateSteps = useCallback(
    (stepIndex: number) => {
      if (stepIndex < activeStep) {
        setSelectedStepIndexes([stepIndex, activeStep]);
      }
    },
    [activeStep, setSelectedStepIndexes]
  );

  const handlePreventedNavigation = useCallback(
    (stepIndex: number, isCtrlKeyPressed: boolean) => {
      if (areMadeChangesOnTeeth) {
        if (!isCtrlKeyPressed) {
          setIsRecalculateCancelled(true);
          setStepToNavigate(stepIndex);
          setSelectedStepIndexes([activeStep]);
        } else {
          handleModeRecalculateSteps(stepIndex);
        }
      }
    },
    [
      areMadeChangesOnTeeth,
      setIsRecalculateCancelled,
      setStepToNavigate,
      setSelectedStepIndexes,
      activeStep,
      handleModeRecalculateSteps
    ]
  );

  const getNewStepIndexes = (oldStepIndexes: number[], stepIndex: number, isCtrlKeyPressed?: boolean) => {
    if (!isCtrlKeyPressed) {
      return [stepIndex];
    }

    if (oldStepIndexes.includes(stepIndex)) {
      return oldStepIndexes.filter((index) => index !== stepIndex);
    }

    if (oldStepIndexes.length === 0) {
      return [stepIndex];
    }

    return [oldStepIndexes[0], stepIndex];
  };

  const addStepToSelection = useCallback(
    (stepIndex: number, isCtrlKeyPressed?: boolean) => {
      const newStepIndexes = getNewStepIndexes(selectedStepIndexes, stepIndex, isCtrlKeyPressed);
      setSelectedStepIndexes(newStepIndexes);
    },
    [setSelectedStepIndexes, selectedStepIndexes]
  );

  const mustHideAddStepsAction = useCallback(() => {
    return (
      !isExpertModeEnabled && (!ability || ability.cannot(AbilityAction.Manage, OrthoAbilitySubject.AddStepsManually))
    );
  }, [ability, isExpertModeEnabled]);

  const mustHideRemoveStepsAction = useCallback(() => {
    const mustHideBecauseNoPermission =
      !ability || ability.cannot(AbilityAction.Manage, OrthoAbilitySubject.RemoveStepsManually);
    const mustHideBecauseNoAllowedSteps = selectedStepIndexes[0] === 0 || selectedStepIndexes.length > 1;

    return (
      (!isExpertModeEnabled || mustHideBecauseNoAllowedSteps) &&
      (mustHideBecauseNoPermission || mustHideBecauseNoAllowedSteps)
    );
  }, [ability, isExpertModeEnabled, lastStepIndex, selectedStepIndexes]);

  const getIntermediateStepsContextMenuOptions = useCallback(() => {
    return [
      {
        labelKey: 'intermediateSteps.contextMenuOptions.addStep',
        action: addStepAction,
        icon: <IconPlus />,
        hide: mustHideAddStepsAction()
      },
      {
        labelKey: 'intermediateSteps.contextMenuOptions.removeStep',
        action: removeStepsAction,
        icon: <IconDelete />,
        hide: mustHideRemoveStepsAction()
      },
      {
        labelKey: 'intermediateSteps.contextMenuOptions.reset',
        icon: <IconReset />,
        action: resetMovementsAction,
        hide: !hasTeethBeenTransformed
      }
    ];
  }, [
    addStepAction,
    mustHideAddStepsAction,
    removeStepsAction,
    mustHideRemoveStepsAction,
    resetMovementsAction,
    hasTeethBeenTransformed
  ]);

  return {
    selectedStepIndexes,
    keyStepIndexes,
    stepsModifiedWithNoAutoRecalcIndexes,
    addStepAction,
    removeStepsAction,
    handleModeRecalculateSteps,
    handlePreventedNavigation,
    addStepToSelection,
    getIntermediateStepsContextMenuOptions
  };
}
