import './labelsTools.scss';

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import shallow from 'zustand/shallow';

import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { ModalTypes } from '../../../../../surgeries/components/layout';
import { useOrthBoundStore } from '../../../../stores/useStore';
import { ToolPanelDraggableContainer } from '../ToolPanelDraggableContainer';
import { labelsTypes } from './labelsTypes';
import { AttacheModel } from '../attaches/attacheModel';
import { useAttaches } from '../../../../../hooks';
import { useShallow } from 'zustand/react/shallow';

export function LabelsTools() {
  const setShowLabelsPanel = useOrthBoundStore(useShallow((state) => state.setShowLabelsPanel));
  const toggleModalIsOpened = useCommonBoundStore(useShallow((state) => state.toggleModalIsOpened));
  const [t] = useTranslation();
  const { isAttachedSelectedBelongToSelectedTooth, inFirstOrLastStep, noAttacheSelected } = useAttaches();

  const PANEL_SIZE = 144;

  const toolPosition = useMemo(() => {
    return document.getElementById('orthLabelsButton')?.getBoundingClientRect();
  }, []);

  const handleClose = useCallback(() => {
    setShowLabelsPanel(false);
  }, [setShowLabelsPanel]);

  const showDeleteModal = useCallback(() => {
    toggleModalIsOpened(ModalTypes.ModalDeleteLabel);
  }, [toggleModalIsOpened]);

  const mustDisableRemove = () => {
    return noAttacheSelected || inFirstOrLastStep || !isAttachedSelectedBelongToSelectedTooth;
  };

  return (
    <ToolPanelDraggableContainer
      panelSize={PANEL_SIZE}
      toolPosition={toolPosition}
      handlerElementClass="handleOrthLabels"
    >
      <div className="labelstools no-select">
        <div className="labelstools-header">
          <div className={`labelstools-title handleOrthLabels`}>
            <div>{t('evergineTools.labels')}</div>
          </div>

          <button type="button" className="labelstools-close" onClick={handleClose}>
            <CloseIcon className="clickable" />
          </button>
        </div>
        <div className="labelstools-content">
          <div className="labelstools-models">
            {labelsTypes.map((attacheType) => (
              <AttacheModel model={attacheType} key={`labeltype-${attacheType.id}`} />
            ))}
          </div>
        </div>
        <div className="labelstools-remove">
          <button
            onClick={showDeleteModal}
            type="button"
            className="btn btn-outline-primary flex-fill labelstools-remove__delete"
            disabled={mustDisableRemove()}
          >
            {t('common.delete')}
          </button>
        </div>
      </div>
    </ToolPanelDraggableContainer>
  );
}
