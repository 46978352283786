import { useWindowSize } from '.';
import { Constants } from '../../shared';
import { OrthodonticsPagesUrl } from '../../types';
import { useMemo } from 'react';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';

const pagesWithoutYOffset: string[] = [
  OrthodonticsPagesUrl.StlEdition,
  OrthodonticsPagesUrl.TeethSegmentation,
  OrthodonticsPagesUrl.AxisAndRoots
];

export type UseCanvasSizeType = {
  canvasHeight: number;
  canvasWidth: number;
  offsetX: number;
};

export function useCanvasSize(pathname: string, currentRoute: string): UseCanvasSizeType {
  const size = useWindowSize();
  const showEvolutionPanel = useOrthBoundStore((state) => state.showEvolutionPanel);

  const canvasHeight = useMemo(() => {
    const applyOffset = !pagesWithoutYOffset.includes(currentRoute);
    const offset = applyOffset ? Constants.canvasHeightOffset : 0;
    return size.height - offset;
  }, [size.height, currentRoute]);

  const canvasWidth = useMemo(() => {
    const panel = document.querySelector('.evolutionpanel');
    if (!panel || !showEvolutionPanel) return { width: size.width, offset: 0 };

    const panelWidth = panel.getBoundingClientRect().width;
    const panelStyles = window.getComputedStyle(panel, '::before');
    const beforeWidth = parseFloat(panelStyles.width);
    const totalWidth = panelWidth + beforeWidth;
    const offset = Math.min(totalWidth, size.width / 2);

    return { width: size.width - offset, offset };
  }, [showEvolutionPanel, size.width]);

  const canvasSize = useMemo(
    () => ({ canvasHeight, canvasWidth: canvasWidth.width, offsetX: canvasWidth.offset }),
    [canvasHeight, canvasWidth.offset, canvasWidth.width]
  );

  return canvasSize;
}
