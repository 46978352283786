import { useCallback, useEffect, useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import CacheBuster from 'react-cache-buster';
import {
  AbilityContext,
  AppAbility,
  IAuthService,
  ICommandInvoker,
  IErrorHandlerService,
  IUserProfileService,
  PROFILES_TYPE,
  SAAS_PROFILES_TYPE,
  SaaSUserProfileType,
  UserProfileType,
  defineAbilitiesFor,
  useAuthConfig
} from '../../shared';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { container, INJECTED_TYPES } from '../ioc';
import { useCommonBoundStore } from '../stores/useStore';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { isSaasApiEnabled, loadSettings } from '../../shared/settings';
import EvergineCommand from '../../shared/commands/evergineCommand';
import { PathLevels } from '../../models';
import { version } from '../../../package.json';
import { useShallow } from 'zustand/react/shallow';

export function AuthGuard({ children }: { children: ReactNode }) {
  const { updateProfile, newActionCommand } = useBoundStore(
    useShallow((state) => ({
      updateProfile: state.updateProfile,
      newActionCommand: state.newActionCommand
    }))
  );

  const { setClientCanPublish, setClientCanDownloadSTL } = useOrthBoundStore(
    useShallow((state) => ({
      setClientCanPublish: state.setClientCanPublish,
      setClientCanDownloadSTL: state.setClientCanDownloadSTL
    }))
  );

  const { setIsTouchDevice, isTouchDevice, setSettings, setVersion, isExpertModeEnabled, versions } =
    useCommonBoundStore(
      useShallow((state) => ({
        setIsTouchDevice: state.setIsTouchDevice,
        isTouchDevice: state.isTouchDevice,
        setSettings: state.setSettings,
        setVersion: state.setVersion,
        isExpertModeEnabled: state.isExpertModeEnabled,
        versions: state.versions
      }))
    );

  const commandInvokerService = container.get<ICommandInvoker>(INJECTED_TYPES.ICommandInvokerService);
  const errorHandlerService = container.get<IErrorHandlerService>(INJECTED_TYPES.IErrorHandlerService);
  const [t] = useTranslation();
  const { isLogged } = useAuthConfig();

  const [userIsLogged, setUserIsLogged] = useState<boolean>();
  const [ability, setAbility] = useState<AppAbility>();
  const [userType, setUserType] = useState<UserProfileType>(null);

  const isNotLocal = window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1';

  const getUserTypeAndSetAbilities = useCallback(
    async (token: string) => {
      const pathname = window.location.pathname;
      const caseIdFromRoute = pathname.split('/')[PathLevels.CaseId];
      const profileService = container.get<IUserProfileService>(INJECTED_TYPES.IUserProfileService);
      const userProfile = await profileService.getProfile(caseIdFromRoute);

      if (isSaasApiEnabled()) {
        setClientCanPublish(userProfile.canPublish);
        setClientCanDownloadSTL(userProfile.canDownloadSTL);
      }

      let saasUserType = null;
      if (userProfile.type == PROFILES_TYPE.employee) {
        saasUserType = SAAS_PROFILES_TYPE.administrator;
      }

      if (userProfile.type == PROFILES_TYPE.client) {
        saasUserType = SAAS_PROFILES_TYPE.client;
      }

      const userType = await profileService.getUserType(token, caseIdFromRoute, saasUserType as SaaSUserProfileType);

      setUserType(userType as UserProfileType);
      updateProfile(userProfile);
      setAbility(defineAbilitiesFor(userType as UserProfileType, isExpertModeEnabled));
    },
    [isExpertModeEnabled, setClientCanDownloadSTL, setClientCanPublish, updateProfile]
  );

  const refreshProfileAndAbilities = useCallback(async () => {
    const authService = container.get<IAuthService>(INJECTED_TYPES.IAuthService);
    const token = authService.getAccessToken();

    await getUserTypeAndSetAbilities(token);
  }, [getUserTypeAndSetAbilities]);

  useEffect(() => {
    async function initSettings() {
      const settings = await loadSettings();
      setSettings(settings);
      if (settings.testE2E === true) {
        window.testE2E = true;
      }

      if (settings.dsnGlitchTip && settings.environmentGlitchTip) {
        Sentry.init({ dsn: settings.dsnGlitchTip, environment: settings.environmentGlitchTip });
      }

      setVersion(version);
    }
    initSettings();
  }, [setSettings, setVersion]);

  useEffect(() => {
    setUserIsLogged(isLogged);

    if (isLogged === false) {
      errorHandlerService.showError(t('errors.authError'));
    }
  }, [errorHandlerService, isLogged, t]);

  useEffect(() => {
    if (isTouchDevice !== null) {
      return;
    }

    const checkIsTouchDevice = () => {
      return 'ontouchstart' in (window || document.documentElement) || navigator.maxTouchPoints > 0;
    };

    setIsTouchDevice(checkIsTouchDevice());
  }, [isTouchDevice, setIsTouchDevice]);

  useEffect(() => {
    const getUser = async () => {
      if (isLogged) {
        await refreshProfileAndAbilities();
      }
    };

    getUser();
  }, [isLogged, refreshProfileAndAbilities]);

  useEffect(() => {
    if (!newActionCommand) {
      return;
    }
    const command = new EvergineCommand(newActionCommand.id);
    commandInvokerService.addEvergineCommand(command);
  }, [commandInvokerService, newActionCommand]);

  useEffect(() => {
    if (!userType) return;
    setAbility(defineAbilitiesFor(userType as UserProfileType, isExpertModeEnabled));
  }, [isExpertModeEnabled, userType]);

  if (!userIsLogged || !ability) {
    return null;
  }

  return (
    <CacheBuster currentVersion={version} isEnabled={isNotLocal} isVerboseMode={false}>
      <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
    </CacheBuster>
  );
}
