import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import {
  GetIntermediateStep,
  INJECTED_TYPES,
  InputIntermediateSteps,
  OutputIntermediateSteps,
  Step,
  container
} from '../../../common';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { IAIMovementsService, ICommandInvoker, teethFdis } from '../../../shared';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useStepsManager } from '../useStepsManager';
import { useEvergineStore } from 'evergine-react';
import MarkStepAsModifiedWithNoAutoRecalcCommand from '../../../orthodontics/commands/markStepAsModifiedWithNoAutoRecalcCommand';
import { useTimelineStepsManager } from './useTimelineManager';
import { useToothTransformStore } from '../../../orthodontics/stores/useToothTransformStore';
import { useIprCalculation } from '../../shared/useIprCalculation';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import { TeethArchPosition } from '../../../models';
import { useCamViewChange } from '../../shared';

const getModifiedWithNoAutoRecalcStepKeyIndexesFromSteps = (steps: Step[]) =>
  steps.filter((s) => s.stepIndex > 0 && s.isModifiedStepWithNoAutoRecalc === true).map((ks) => ks.stepIndex);

const getKeyStepIndexesFromSteps = (steps: Step[]) =>
  steps.filter((s) => s.stepIndex > 0 && s.isKeyStep === true).map((ks) => ks.stepIndex);

export function useTreatmentStepsManager(disableEffects = false) {
  const {
    isAIInterpolationAsked,
    isRecalculateCancelled,
    stageIsLoaded,
    shouldUpdateMovements,
    setIsAIInterpolationAsked,
    setCanAskAIInterpolation,
    setUpperCanAskAIInterpolation,
    setLowerCanAskAIInterpolation,
    setIsRecalculateCancelled,
    setAreMadeChangesOnTeeth,
    setUpperAndLowerDentalMovements,
    setShouldUpdateMovements
  } = useOrthBoundStore(
    useShallow((state) => ({
      isAIInterpolationAsked: state.isAIInterpolationAsked,
      isRecalculateCancelled: state.isRecalculateCancelled,
      stageIsLoaded: state.stageIsLoaded,
      shouldUpdateMovements: state.shouldUpdateMovements,
      setUpperDentalMovements: state.setUpperDentalMovements,
      setLowerDentalMovements: state.setLowerDentalMovements,
      setIsAIInterpolationAsked: state.setIsAIInterpolationAsked,
      setCanAskAIInterpolation: state.setCanAskAIInterpolation,
      setIsRecalculateCancelled: state.setIsRecalculateCancelled,
      setAreMadeChangesOnTeeth: state.setAreMadeChangesOnTeeth,
      setUpperAndLowerDentalMovements: state.setUpperAndLowerDentalMovements,
      setShouldUpdateMovements: state.setShouldUpdateMovements,
      setUpperCanAskAIInterpolation: state.setUpperCanAskAIInterpolation,
      setLowerCanAskAIInterpolation: state.setLowerCanAskAIInterpolation
    }))
  );

  const { selectedTeethTransformData } = useToothTransformStore(
    useShallow((state) => ({
      selectedTeethTransformData: state.selectedTeethTransformData
    }))
  );
  const { evergineReady } = useEvergineStore();
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const setWebBusyMessage = useBoundStore((state) => state.setWebBusyMessage);
  const setIsPreventedNavigationCancelled = useCommonBoundStore((state) => state.setIsPreventedNavigationCancelled);

  const movementsAIService = container.get<IAIMovementsService>(INJECTED_TYPES.IAIMovementsService);
  const { goToStep } = useStepsManager();
  const { calculateIpr } = useIprCalculation();
  const [t] = useTranslation();
  const { currentArchView } = useCamViewChange();

  const {
    upperSteps,
    lowerSteps,
    activeStep,
    selectedStepIndexes,
    lastStepIndex,
    setSelectedStepIndexes,
    setActiveStep,
    setLastStepIndex
  } = useTimelineStepsManager();

  const [isAIEnabled, setIsAIEnabled] = useState<boolean>(true);
  const [stepToNavigate, setStepToNavigate] = useState<number>();

  const stepsModifiedWithNoAutoRecalcIndexes = useMemo(() => {
    const upperModifiedStepsWithNoAutoRecalcIndexes =
      upperSteps?.length >= 2 ? getModifiedWithNoAutoRecalcStepKeyIndexesFromSteps(upperSteps) : [];
    const lowerModifiedStepsWithNoAutoRecalcIndexes =
      lowerSteps?.length >= 2 ? getModifiedWithNoAutoRecalcStepKeyIndexesFromSteps(lowerSteps) : [];

    return Array.from(
      new Set([...upperModifiedStepsWithNoAutoRecalcIndexes, ...lowerModifiedStepsWithNoAutoRecalcIndexes])
    );
  }, [upperSteps, lowerSteps]);

  const keySteps = useMemo(() => {
    const upperKeySteps = upperSteps?.length >= 2 ? getKeyStepIndexesFromSteps(upperSteps) : [];
    const lowerKeySteps = lowerSteps?.length >= 2 ? getKeyStepIndexesFromSteps(lowerSteps) : [];

    return Array.from(new Set([...upperKeySteps, ...lowerKeySteps]));
  }, [upperSteps, lowerSteps]);

  const setStepAsActiveAndSelected = useCallback(
    (newActiveStep: number) => {
      setActiveStep(newActiveStep);
      setSelectedStepIndexes([newActiveStep]);
    },
    [setActiveStep, setSelectedStepIndexes]
  );

  const getMovementsFromEvergine = useCallback(() => window.App.webEventsProxy.movements.getTreatmentMovements(), []);

  const updateMovements = useCallback(() => {
    const { upperMovements, lowerMovements } = getMovementsFromEvergine();
    setLastStepIndex(Math.max(upperMovements?.steps?.length, lowerMovements?.steps?.length) - 1);
    setUpperAndLowerDentalMovements(upperMovements, lowerMovements);
  }, [setUpperAndLowerDentalMovements, getMovementsFromEvergine, setLastStepIndex]);

  const setStepAsModifiedWithNoAIInterpolation = useCallback(async () => {
    const commandInvokerService = container.get<ICommandInvoker>(INJECTED_TYPES.ICommandInvokerService);
    const command = new MarkStepAsModifiedWithNoAutoRecalcCommand(
      activeStep,
      stepToNavigate,
      setStepAsActiveAndSelected
    );
    await commandInvokerService.execute(command);
    updateMovements();
  }, [activeStep, setStepAsActiveAndSelected, stepToNavigate, updateMovements]);

  const getStepsPrediction = useCallback(
    async (stepFrom: number, stepTo: number): Promise<OutputIntermediateSteps | null> => {
      const currentArch = currentArchView();
      const getIntermediateStep: GetIntermediateStep = {
        stepIndexFrom: stepFrom,
        stepIndexTo: stepTo,
        teethArch: currentArch
      };
      const inputIntermediateSteps = await window.App.webEventsProxy.movements.getInputForIntermediateSteps(
        getIntermediateStep
      );
      if (
        !inputIntermediateSteps ||
        !inputIntermediateSteps.total_mov_teeth ||
        inputIntermediateSteps.total_mov_teeth.length === 0
      ) {
        return null;
      }

      const filteredResult = filterByArch(inputIntermediateSteps, currentArch);

      const inputStringified = JSON.stringify(filteredResult);
      const result = await movementsAIService.getAIMovementsPrediction({
        movementsDelta: inputStringified
      });

      return {
        ...result,
        teethArch: currentArch
      } as OutputIntermediateSteps;
    },
    [movementsAIService]
  );

  const filterByArch = (intermediateStep: InputIntermediateSteps, arch: TeethArchPosition) => {
    if (arch == TeethArchPosition.LOWER) {
      intermediateStep.total_mov_teeth = intermediateStep.total_mov_teeth.filter((teeth) =>
        teethFdis.lowerTeeth.includes(teeth.toothFdi)
      );
    } else if (arch == TeethArchPosition.UPPER) {
      intermediateStep.total_mov_teeth = intermediateStep.total_mov_teeth.filter((tooth) => {
        return teethFdis.upperTeeth.includes(tooth.toothFdi);
      });
    }

    return intermediateStep;
  };

  const loadAIInterpolation = useCallback(
    async (fromStepIndex: number, toStepIndex: number): Promise<boolean> => {
      const outputIntermediateSteps = await getStepsPrediction(fromStepIndex, toStepIndex);
      if (outputIntermediateSteps && outputIntermediateSteps.steps?.length > 0) {
        await window.App.webEventsProxy.movements.updateIntermediateStepsFromAI(
          outputIntermediateSteps,
          fromStepIndex,
          toStepIndex
        );
        await calculateIpr();
        updateMovements();
        goToStep(fromStepIndex, false);
        setSelectedStepIndexes([fromStepIndex, fromStepIndex + outputIntermediateSteps.steps.length + 1]);
        return Promise.resolve(true);
      }

      if (outputIntermediateSteps && outputIntermediateSteps.steps.length === 0) {
        window.App.webEventsProxy.movements.setStepAsStepWithNoAutoRecalc(fromStepIndex, false);
      }

      return Promise.resolve(false);
    },
    [calculateIpr, getStepsPrediction, goToStep, setSelectedStepIndexes, updateMovements]
  );

  const loadAIInterpolationMultiple = useCallback(
    async (keyframes: number[]): Promise<boolean> => {
      if (keyframes.length < 2) {
        return Promise.resolve(false);
      }

      const stepsToCalculate = [...keyframes];
      for (let i = 0; i < stepsToCalculate.length - 1; i++) {
        const fromStepIndex = stepsToCalculate[i];
        const toStepIndex = stepsToCalculate[i + 1];

        const outputIntermediateSteps = await getStepsPrediction(fromStepIndex, toStepIndex);
        if (outputIntermediateSteps) {
          if (outputIntermediateSteps.steps?.length > 0) {
            await window.App.webEventsProxy.movements.updateIntermediateStepsFromAI(
              outputIntermediateSteps,
              fromStepIndex,
              toStepIndex
            );
          } else {
            window.App.webEventsProxy.movements.setStepAsStepWithNoAutoRecalc(fromStepIndex, false);
          }

          updateMovements();

          const lastGeneratedStepIndex = fromStepIndex + outputIntermediateSteps.steps.length + 1;
          stepsToCalculate[i + 1] = lastGeneratedStepIndex;
          for (let j = i + 2; j < stepsToCalculate.length; j++) {
            const oldFromIndex = keyframes[i + 1];
            const oldToIndex = keyframes[j];
            const distanceBetweenBothIndices = oldToIndex - oldFromIndex;
            stepsToCalculate[j] = distanceBetweenBothIndices + lastGeneratedStepIndex;
          }
        }
      }

      await calculateIpr();

      return Promise.resolve(true);
    },
    [calculateIpr, getStepsPrediction, updateMovements]
  );

  useEffect(() => {
    if (!evergineReady || !stageIsLoaded || disableEffects || !shouldUpdateMovements) {
      return;
    }
    updateMovements();
    setShouldUpdateMovements(false);
  }, [disableEffects, evergineReady, selectedTeethTransformData, stageIsLoaded, updateMovements]);

  const isCalculatingRef = useRef(false);
  useEffect(() => {
    if (!isAIInterpolationAsked || disableEffects) {
      return;
    }

    const askForInterpolation = async () => {
      if (isCalculatingRef.current) {
        return;
      }
      isCalculatingRef.current = true;
      setIsAIEnabled(false);
      setWebBusy(true);
      setWebBusyMessage(t('common.loaderActions.calculatingIntermediateSteps'));

      if (selectedStepIndexes.length === 2) {
        const orderedSelectedStepIndexes = selectedStepIndexes.sort((a, b) => a - b);
        await loadAIInterpolation(orderedSelectedStepIndexes[0], orderedSelectedStepIndexes[1]);
      } else {
        const orderedModifiedStepIndexes = [
          ...stepsModifiedWithNoAutoRecalcIndexes,
          ...selectedStepIndexes,
          ...keySteps
        ].sort((a, b) => a - b);
        await loadAIInterpolationMultiple([...new Set([0, ...orderedModifiedStepIndexes, lastStepIndex])]);
      }

      setIsAIInterpolationAsked(false);
      setAreMadeChangesOnTeeth(false);
      const currentArch = currentArchView();
      if (currentArch === TeethArchPosition.BOTH || currentArch === TeethArchPosition.LOWER) {
        setLowerCanAskAIInterpolation(false);
      }
      if (currentArch === TeethArchPosition.BOTH || currentArch === TeethArchPosition.UPPER) {
        setUpperCanAskAIInterpolation(false);
      }

      setWebBusy(false);
      setWebBusyMessage(undefined);
      setIsAIEnabled(true);
      isCalculatingRef.current = false;
    };

    askForInterpolation();
  }, [
    disableEffects,
    isAIInterpolationAsked,
    keySteps,
    lastStepIndex,
    loadAIInterpolation,
    loadAIInterpolationMultiple,
    selectedStepIndexes,
    setAreMadeChangesOnTeeth,
    setCanAskAIInterpolation,
    setIsAIInterpolationAsked,
    setWebBusy,
    setWebBusyMessage,
    stepsModifiedWithNoAutoRecalcIndexes,
    t
  ]);

  useEffect(() => {
    if (disableEffects) return;
    if (isRecalculateCancelled) {
      setStepAsModifiedWithNoAIInterpolation();
      setIsRecalculateCancelled(false);
      setAreMadeChangesOnTeeth(false);
      setIsPreventedNavigationCancelled(true);
    }
  }, [
    disableEffects,
    isRecalculateCancelled,
    setAreMadeChangesOnTeeth,
    setIsPreventedNavigationCancelled,
    setIsRecalculateCancelled,
    setStepAsModifiedWithNoAIInterpolation
  ]);

  return {
    isAIEnabled,
    setIsAIEnabled,
    selectedStepIndexes,
    stepsModifiedWithNoAutoRecalcIndexes,
    setStepToNavigate,
    updateMovements,
    loadAIInterpolation
  };
}
