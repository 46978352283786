import { TableMovementType } from './movementsTable.types';

interface MovementsTableCellProps {
  value: number;
  exceeded: boolean;
  pieceNumber: number;
  movementType: TableMovementType;
}

export function MovementsTableCell({ value, exceeded, pieceNumber, movementType }: MovementsTableCellProps) {
  return (
    <div
      key={`piece-movement-${pieceNumber}-${movementType}`}
      className={`movementstable-piece ${exceeded ? 'exceeded' : ''}`}
    >
      {value}
    </div>
  );
}
