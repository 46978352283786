import { useMemo } from 'react';
import { TableDataValues, TableMovementType } from './movementsTable.types';
import { MovementsTableRow } from './MovementsTableRow';

interface MovementsTableQuadrantProps {
  quadrantNumber: number;
  asc: boolean;
  tableData: TableDataValues[];
  shouldBeWarned: (piece: TableDataValues, movementKey: TableMovementType) => boolean;
}

const quadrantStartEndPieces: Record<number, { start: number; end: number }> = {
  1: {
    start: 18,
    end: 11
  },
  2: {
    start: 21,
    end: 28
  },
  3: {
    start: 38,
    end: 31
  },
  4: {
    start: 41,
    end: 48
  }
};

export function MovementsTableQuadrant({
  quadrantNumber,
  asc,
  tableData,
  shouldBeWarned
}: MovementsTableQuadrantProps) {
  const quadrant = useMemo(() => {
    const divs: JSX.Element[] = [];
    const { start, end } = quadrantStartEndPieces[quadrantNumber];
    const getDirection = (i: number) => (asc ? i <= end : i >= end);

    for (let i = start; getDirection(i); asc ? i++ : i--) {
      divs.push(
        <MovementsTableRow
          key={`quadrant-${quadrantNumber}-row-${i}`}
          pieceNumber={i}
          tableData={tableData}
          shouldBeWarned={shouldBeWarned}
        />
      );
    }

    return divs;
  }, [asc, quadrantNumber, shouldBeWarned, tableData]);

  return <div className="movementstable-quadrant">{quadrant}</div>;
}
