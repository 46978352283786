import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseCaptures } from '../../../common/components';
import { CaptureType } from '../../../common/evergine/types';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useGateKeeper } from '../../../hooks';
import { AbilityAction, CommonAbilitySubject } from '../../../shared';

export function STLCaptures() {
  const navigate = useNavigate();
  useGateKeeper(AbilityAction.Manage, CommonAbilitySubject.Upload3DModels, () => navigate('/forbidden'));
  const { captureType, setCaptureType } = useCommonBoundStore((store) => ({
    captureType: store.captureType,
    setCaptureType: store.setCaptureType
  }));

  useEffect(() => {
    if (!captureType || captureType === CaptureType.MODEL3D) {
      return;
    }

    setCaptureType(CaptureType.MODEL3D);
  }, [captureType, setCaptureType]);

  return <BaseCaptures type={CaptureType.MODEL3D} allowedFileExtension="stl" />;
}
