import { useNavigate } from 'react-router-dom';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { ClientTreatmentRoute, OrthodonticsOrderedRoutes } from '../../orthodontics/components/layout';
import { useCustomNavigate } from './useCustomNavigate';
import { useCaseId } from './useCaseId';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { useClientModification } from './useClientModification';
import { DentalMovementPair } from '../../common';
import { useShallow } from 'zustand/react/shallow';
import { useCallback } from 'react';

export function useExpertMode() {
  const setIsExpertModeEnabled = useCommonBoundStore((state) => state.setIsExpertModeEnabled);
  const { upperDentalMovements, lowerDentalMovements, setUpperAndLowerDentalMovements, setShowMovements } =
    useOrthBoundStore(
      useShallow((state) => ({
        upperDentalMovements: state.upperDentalMovements,
        lowerDentalMovements: state.lowerDentalMovements,
        setUpperAndLowerDentalMovements: state.setUpperAndLowerDentalMovements,
        setShowMovements: state.setShowMovements
      }))
    );

  const navigate = useNavigate();
  const { getRouteWithVersionId } = useCustomNavigate();
  const [caseId] = useCaseId();
  const { removeLastStepOnClientModificationCancelled } = useClientModification();
  const setCanUndo = useOrthBoundStore((state) => state.setCanUndo);

  const enableExpertMode = useCallback(() => {
    // Force to reload edition mode disabling this and then enabling in the correct component
    window.App.webEventsProxy.common.setEditionMode(false);
    setShowMovements(false);

    // Update dental movements to remove the last step if it was added on client modification
    if (!!upperDentalMovements !== false) {
      upperDentalMovements.steps = removeLastStepOnClientModificationCancelled(upperDentalMovements.steps);
    }

    if (!!lowerDentalMovements !== false) {
      lowerDentalMovements.steps = removeLastStepOnClientModificationCancelled(lowerDentalMovements.steps);
    }

    const dentalMovementsPair: DentalMovementPair = {
      upperDentalMovements: upperDentalMovements,
      lowerDentalMovements: lowerDentalMovements
    };

    window.App.webEventsProxy.movements.updateMovements(dentalMovementsPair);
    setUpperAndLowerDentalMovements(upperDentalMovements, lowerDentalMovements);

    setIsExpertModeEnabled(true);

    // Navigate to treatment page with expert mode enabled
    const treatmentRoute = `${OrthodonticsOrderedRoutes.Treatment}/${caseId}`;
    const navigateRoute = getRouteWithVersionId(treatmentRoute);
    setCanUndo(false);
    navigate(navigateRoute);
  }, [
    upperDentalMovements,
    lowerDentalMovements,
    caseId,
    removeLastStepOnClientModificationCancelled,
    setShowMovements,
    setUpperAndLowerDentalMovements,
    setIsExpertModeEnabled,
    setCanUndo,
    navigate
  ]);

  const cancelExpertMode = useCallback(() => {
    setIsExpertModeEnabled(false);

    // Navigate to dental-movement page with expert mode disabled
    const clientTreatmentRoute = `${ClientTreatmentRoute}/${caseId}`;
    const navigateRoute = getRouteWithVersionId(clientTreatmentRoute);
    navigate(navigateRoute);
  }, [setIsExpertModeEnabled, ClientTreatmentRoute, caseId, getRouteWithVersionId, navigate]);

  return { enableExpertMode, cancelExpertMode };
}
