import React, { useMemo } from 'react';
import './evolutionTableHeader.scss';
import { useCommonBoundStore } from '../../../../common/stores/useStore';
import { useShallow } from 'zustand/react/shallow';
import { EvolutionStep } from '../EvolutionPanel';
import { useStepsManager } from '../../../../hooks';

interface EvolutionTableHeaderProps {
  isBodyScrollVisible: boolean;
  currentSliceSteps: EvolutionStep[];
  headerStepId: string;
}

const EvolutionTableHeader = React.memo((props: EvolutionTableHeaderProps) => {
  const { isBodyScrollVisible, currentSliceSteps, headerStepId } = props;

  const { activeStep, isNewStepClientModification, setSelectedStepIndexes, isExpertModeEnabled } = useCommonBoundStore(
    useShallow((state) => ({
      activeStep: state.activeStep,
      isNewStepClientModification: state.isNewStepClientModification,
      setSelectedStepIndexes: state.setSelectedStepIndexes,
      isExpertModeEnabled: state.isExpertModeEnabled
    }))
  );

  const { goToStep } = useStepsManager();

  const highlightedButtons = useMemo(() => {
    return currentSliceSteps.map((step: EvolutionStep) => (
      <button
        className={`evolutiontableheader--step ${step.index === activeStep ? 'is-active' : ''}`}
        key={`evolutiontable-step-${step.index}`}
        id={`${headerStepId}${step.index}`}
        onClick={() => {
          setSelectedStepIndexes([step.index]);
          goToStep(step.index, false);
        }}
        disabled={isNewStepClientModification && !isExpertModeEnabled}
      >
        {step.index}
      </button>
    ));
  }, [
    activeStep,
    currentSliceSteps,
    goToStep,
    isExpertModeEnabled,
    isNewStepClientModification,
    setSelectedStepIndexes,
    headerStepId
  ]);

  return <div className={`evolutiontableheader ${isBodyScrollVisible ? 'is-scroll' : ''}`}>{highlightedButtons}</div>;
});

export default EvolutionTableHeader;
