import { fixFloat, fixFloatFloor } from './../../../shared/functionalities.helper';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MovementCalculationType } from '../../../common';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { DentalMovementDTO, MovementsTableDto } from '../../../shared';
import { TableDataValues, TableMovementType } from '../../../orthodontics';
import { useShallow } from 'zustand/react/shallow';

type ExceededLimit = {
  step: number;
  pieceFdi: number;
  movementType: TableMovementType;
};

export function useMovementsTableData(
  calculationType: MovementCalculationType,
  activeStep: number,
  selectedTeethTransformData: any
) {
  const { movementsTable, setMovementsTable } = useOrthBoundStore(
    useShallow((state) => ({
      movementsTable: state.movementsTable,
      setMovementsTable: state.setMovementsTable
    }))
  );
  const [tableData, setTableData] = useState<TableDataValues[]>([]);
  const [exceededLimits, setExceededLimits] = useState<ExceededLimit[]>([]);

  const predictabilityLimits: Record<TableMovementType, number> = useMemo(
    () => ({
      [TableMovementType.ExtrusionIntrusion]: 1.5,
      [TableMovementType.TranslationVL]: 3,
      [TableMovementType.TranslationMD]: 3,
      [TableMovementType.PureRotation]: 30,
      [TableMovementType.Torque]: 15,
      [TableMovementType.TIP]: 12
    }),
    []
  );

  const predictabilityStepLimits: Record<TableMovementType, number> = useMemo(
    () => ({
      [TableMovementType.ExtrusionIntrusion]: 0.12,
      [TableMovementType.TranslationVL]: 0.25,
      [TableMovementType.TranslationMD]: 0.25,
      [TableMovementType.PureRotation]: 3,
      [TableMovementType.Torque]: 2,
      [TableMovementType.TIP]: 2
    }),
    []
  );

  const refreshToothMovementsTable = useCallback(() => {
    const toothTransform = selectedTeethTransformData;
    const actualMovementsTable = { ...movementsTable };

    getMovementTableByCalculationType(activeStep, actualMovementsTable, calculationType).forEach(
      (movement: DentalMovementDTO) => {
        if (movement.fdi === Number(toothTransform.toothFdi)) {
          Object.assign(movement, toothTransform);
        }
      }
    );

    useOrthBoundStore.setState({ movementsTable: actualMovementsTable });
  }, [selectedTeethTransformData, activeStep, calculationType, movementsTable]);

  const parseMovementsTable = useCallback(
    (movementsTable: MovementsTableDto): TableDataValues[] => {
      return getMovementTableByCalculationType(activeStep, movementsTable, calculationType).map(
        ({ fdi, ...values }: DentalMovementDTO) => {
          return {
            id: fdi,
            value: Object.fromEntries(
              Object.entries(values)
                .filter(([key]) => Object.values(TableMovementType).includes(key as TableMovementType))
                .map(([key, value]) => [key as TableMovementType, getRoundedValue(value, 2)])
            ) as unknown as Record<TableMovementType, number>
          };
        }
      );
    },
    [activeStep, calculationType]
  );

  const getMovementTableByCalculationType = (
    stepIndex: number,
    movementTable: MovementsTableDto,
    calculationType: MovementCalculationType
  ) => {
    switch (calculationType) {
      case MovementCalculationType.Total:
        return movementTable?.movementsTotal || [];
      case MovementCalculationType.Accumulated:
        return movementTable?.movementsAccumulated[stepIndex] || [];
      case MovementCalculationType.Step:
        return movementTable?.movementsRelative[stepIndex] || [];
    }
  };

  const getMovementsTable = useCallback(async () => {
    const response: MovementsTableDto | null = await window.App.webEventsProxy.movements.getMovementsTable();
    setMovementsTable(response);
    setTableData(parseMovementsTable(response));
  }, [parseMovementsTable, setMovementsTable]);

  const shouldBeWarnedAboutExceededMovement = useCallback(
    (piece: TableDataValues, movementType: TableMovementType) => {
      const value = fixFloat(Number(piece.value[movementType]), 2);
      const predictabilityLimit =
        calculationType !== MovementCalculationType.Step
          ? predictabilityLimits[movementType]
          : predictabilityStepLimits[movementType];
      const isExceedingPredictability = Math.abs(value) > predictabilityLimit;
      //calculationType !== MovementCalculationType.Step ? value > predictabilityLimit : false;

      setExceededLimits((prevExceededLimits) => {
        const filteredLimits = prevExceededLimits.filter(
          (item) => !(item.pieceFdi === piece.id && item.movementType === movementType && item.step === activeStep)
        );

        if (isExceedingPredictability) {
          filteredLimits.push({ step: activeStep, pieceFdi: piece.id, movementType });
        }

        return filteredLimits;
      });

      const hasWarningInPreviousSteps = exceededLimits.some(
        (item) => item.pieceFdi === piece.id && item.movementType === movementType && item.step < activeStep
      );

      return (
        isExceedingPredictability || (calculationType !== MovementCalculationType.Step && hasWarningInPreviousSteps)
      );
    },
    [predictabilityLimits, activeStep, calculationType]
  );

  const getRoundedValue = (value: number, decimals: number) => {
    if (value === undefined || value === null) {
      return (0).toFixed(decimals);
    }

    if (value > -1 / Math.pow(10, decimals) && value < 0) {
      return (0).toFixed(decimals);
    }

    return value.toFixed(decimals);
  };

  useEffect(() => {
    if (!movementsTable || !selectedTeethTransformData) return;

    refreshToothMovementsTable();
    setTableData(parseMovementsTable(movementsTable));
  }, [activeStep]);

  return {
    tableData,
    shouldBeWarnedAboutExceededMovement,
    getMovementsTable
  };
}
