import { useCallback, useEffect, useState } from 'react';
import { useOrthBoundStore } from '../../../stores/useStore';
import { useShallow } from 'zustand/react/shallow';

export function useTeethContextMenu(onToggleAction: () => void) {
  const { selectedTeethId, setSelectedTeethId, selectedAttache } = useOrthBoundStore(
    useShallow((state) => ({
      toothHoverData: state.toothHoverData,
      isToothHovered: state.isToothHovered,
      selectedTeethId: state.selectedTeethId,
      setSelectedTeethId: state.setSelectedTeethId,
      selectedAttache: state.selectedAttache
    }))
  );
  const selectedToothId = selectedTeethId[0];

  const [canTeethContextMenuBeShown, setCanTeethContextMenuBeShown] = useState<boolean>(false);

  useEffect(() => {
    setCanTeethContextMenuBeShown(!!selectedToothId && !selectedAttache);
  }, [selectedToothId]);

  const removeTeethIfSelected = useCallback(() => {
    if (selectedToothId && selectedToothId > 0) {
      window.App.webEventsProxy.extractions.markToothAsExtracted(selectedToothId);
      setSelectedTeethId([]);
      onToggleAction();
    }
  }, [selectedToothId]);

  return {
    canTeethContextMenuBeShown,
    removeTeethIfSelected
  };
}
