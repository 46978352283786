import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import PreviousStepIcon from '../../../../assets/icons/previous.svg';
import { NavBarAction } from './NavBarAction';
import { useSaveAndNavigateStep } from '../../../../hooks/shared/useSaveAndNavigateStep';
import { useOrthBoundStore } from '../../../../orthodontics/stores/useStore';
import { useCommonBoundStore } from '../../../stores/useStore';

export default () => {
  const setIsNavigationToNextPhase = useOrthBoundStore((state) => state.setIsNavigationToNextPhase);
  const setNavigationRoute = useOrthBoundStore((state) => state.setNavigationRoute);
  const isExpertModeEnabled = useCommonBoundStore((state) => state.isExpertModeEnabled);
  const [t] = useTranslation();
  const { saveAndNavigateStep } = useSaveAndNavigateStep({ isDirectNavigation: true, checkPhaseChanges: true });

  const onBackButtonClick = () => {
    setIsNavigationToNextPhase(false);
    setNavigationRoute(null);
    saveAndNavigateStep();
  };

  const buttonId = 'previousStep';

  return (
    !isExpertModeEnabled && (
      <>
        <NavBarAction
          icon={PreviousStepIcon}
          dataFor={buttonId}
          altText={t('navbar.actions.previousStep')}
          action={onBackButtonClick}
        />
        <ReactTooltip id={buttonId} place="bottom" effect="solid" className="tooltip" type="dark">
          {t(`navbar.actions.previousStep`)}
        </ReactTooltip>
      </>
    )
  );
};
