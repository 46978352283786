import { ReactComponent as NoMovementIcon } from '../../../assets/icons/evolutionPanel/info/no-movement.svg';
import { ReactComponent as MissingToothIcon } from '../../../assets/icons/evolutionPanel/info/missing-tooth.svg';
import { ReactComponent as ActiveAlignerIcon } from '../../../assets/icons/evolutionPanel/info/active-aligner.svg';
import { ReactComponent as PassiveAlignerIcon } from '../../../assets/icons/evolutionPanel/info/passive-aligner.svg';
import { ReactComponent as StartAttachIcon } from '../../../assets/icons/evolutionPanel/info/start-attach.svg';
import { ReactComponent as EndAttachIcon } from '../../../assets/icons/evolutionPanel/info/end-attach.svg';
import { ReactComponent as SpaceIcon } from '../../../assets/icons/evolutionPanel/info/space.svg';
import { ReactComponent as IPRIcon } from '../../../assets/icons/evolutionPanel/info/ipr.svg';
import { ReactComponent as ExcessiveIPRIcon } from '../../../assets/icons/evolutionPanel/info/excessive-ipr.svg';
import { ReactComponent as OvermovementIcon } from '../../../assets/icons/evolutionPanel/info/overmovement.svg';
import { ReactComponent as OppositeMovementsIcon } from '../../../assets/icons/evolutionPanel/info/opposite-movements.svg';
import { ReactComponent as DragBlockIcon } from '../../../assets/icons/evolutionPanel/info/drag-block-v2.svg';
import { ReactComponent as DragIprIcon } from '../../../assets/icons/evolutionPanel/info/drag-ipr.svg';

import { useTranslation } from 'react-i18next';
import { IconTextInfo, Info } from '../../../common';
import { memo } from 'react';

const EvolutionPanelInfo = memo(function EvolutionPanelInfo() {
  const [t] = useTranslation();

  const infoPairings: IconTextInfo[] = [
    {
      text: t('evolutionPanel.info.activeAligner'),
      icon: ActiveAlignerIcon
    },
    {
      text: t('evolutionPanel.info.overMovement'),
      icon: OvermovementIcon
    },
    {
      text: t('evolutionPanel.info.oppositeMovements'),
      icon: OppositeMovementsIcon
    },
    {
      text: t('evolutionPanel.info.noMovement'),
      icon: NoMovementIcon
    },
    {
      text: t('evolutionPanel.info.passiveAligner'),
      icon: PassiveAlignerIcon
    },
    {
      text: t('evolutionPanel.info.IPR'),
      icon: IPRIcon
    },
    {
      text: t('evolutionPanel.info.excessiveIPR'),
      icon: ExcessiveIPRIcon
    },
    {
      text: t('evolutionPanel.info.blocksDrag'),
      icon: DragBlockIcon
    },
    {
      text: t('evolutionPanel.info.iprDrag'),
      icon: DragIprIcon
    },
    {
      text: t('evolutionPanel.info.space'),
      icon: SpaceIcon
    },
    {
      text: t('evolutionPanel.info.missingTooth'),
      icon: MissingToothIcon
    },
    {
      text: t('evolutionPanel.info.startAtache'),
      icon: StartAttachIcon
    },
    {
      text: t('evolutionPanel.info.endAtache'),
      icon: EndAttachIcon
    }
  ];

  return (
    <div>
      <Info iconTextInfos={infoPairings} darkStyle={true} />
    </div>
  );
});

export default EvolutionPanelInfo;
