import { ToolbarConfigProps } from '../../../common';
import { ReactComponent as AutoCalculate } from '../../../assets/icons/evergineTools/auto-calc.svg';
import { useOrthBoundStore } from '../../stores/useStore';
import { useShallow } from 'zustand/react/shallow';

export const calcAIIntermediateStepsToolbarElement: ToolbarConfigProps = {
  icon: AutoCalculate,
  tooltipKey: 'evergineTools.autoInterpolation',
  labelKey: 'evergineTools.autoInterpolation',
  handleClick: () => {
    useOrthBoundStore.setState({ isAIInterpolationAsked: true });
  },
  isDisabled: () => {
    const { upperCanAskAIInterpolation, lowerCanAskAIInterpolation } = useOrthBoundStore(
      useShallow((state) => ({
        upperCanAskAIInterpolation: state.upperCanAskAIInterpolation,
        lowerCanAskAIInterpolation: state.lowerCanAskAIInterpolation
      }))
    );

    return !upperCanAskAIInterpolation && !lowerCanAskAIInterpolation;
  }
};
