import { useEffect, useState } from 'react';
import { MeasureUnit } from '../../../orthodontics/models';
import { Key } from 'ts-key-enum';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import {
  getInitialValues,
  mapAbsoluteMovementFunctionFrom,
  mapMovementAxisFrom,
  mapMovementFunctionFrom
} from './stlOrientation.helper';
import {
  mapCapturePositionFrom,
  OperationType,
  StlOrientationMovementTypes,
  StlOrientationToolsType,
  StlOrientationValue
} from '../../../orthodontics';
import { StlEditionTarget } from '../../../orthodontics/components/evergineToolbarElements/tools/stlEdition/stlEdition.types';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { ArchsToTreat } from '../../../shared';
import { useShallow } from 'zustand/react/shallow';

const MOVEMENTS_DECIMALS = 2;
const VALID_NUMBER_REGEX = /^[+-]?\d+(\.\d+)?$/;
const MM_STEP_NUMBER = 0.1;
const DEGREES_STEP_NUMBER = 1;

const initialMovementValues: StlOrientationValue = {
  [StlEditionTarget.Both]: getInitialValues(),
  [StlEditionTarget.Lower]: getInitialValues(),
  [StlEditionTarget.Upper]: getInitialValues()
};

export function useStlOrientation() {
  const { setHasStlBeenOriented, hasStlBeenOriented, orientationValueFrom3d } = useOrthBoundStore(
    useShallow((state) => ({
      setHasStlBeenOriented: state.setHasStlBeenOriented,
      hasStlBeenOriented: state.hasStlBeenOriented,
      orientationValueFrom3d: state.orientationValueFrom3d
    }))
  );

  const archsToBeTreated = useCommonBoundStore((state) => state.archsToBeTreated);
  const [orientationValues, setOrientationValues] = useState<StlOrientationValue>(initialMovementValues);
  const [selectedOrientationTarget, setSelectedOrientationTarget] = useState<StlEditionTarget>(StlEditionTarget.Both);

  useEffect(() => {
    if (archsToBeTreated !== ArchsToTreat.Both) {
      setSelectedOrientationTarget(
        archsToBeTreated === ArchsToTreat.Upper ? StlEditionTarget.Upper : StlEditionTarget.Lower
      );
    }
  }, [archsToBeTreated]);

  useEffect(() => {
    if (!orientationValueFrom3d) {
      return;
    }
    const orientationTarget: StlEditionTarget = orientationValueFrom3d.arch as unknown as StlEditionTarget;
    const newValues = {
      ...orientationValues,
      [orientationTarget]: {
        ...orientationValues[orientationTarget],
        [orientationValueFrom3d.movementType]: {
          ...orientationValues[orientationTarget][orientationValueFrom3d.movementType],
          value: orientationValueFrom3d.value
        }
      }
    };
    setOrientationValues(newValues);
  }, [orientationValueFrom3d]);

  const getInputValue = (movementType: StlOrientationMovementTypes) => {
    const value = orientationValues[selectedOrientationTarget][movementType].value;

    if (typeof value === 'number') {
      return value.toFixed(MOVEMENTS_DECIMALS);
    }

    return value;
  };

  const saveInputValue = (value: any, movementType: StlOrientationMovementTypes) => {
    setOrientationValues((prevState) => ({
      ...prevState,
      [selectedOrientationTarget]: {
        ...prevState[selectedOrientationTarget],
        [movementType]: {
          ...prevState[selectedOrientationTarget][movementType],
          value
        }
      }
    }));

    if (!hasStlBeenOriented) {
      setHasStlBeenOriented(true);
    }
  };

  const applyOrientationValue = (value: number, movementType: StlOrientationMovementTypes, isOffset: boolean) => {
    const movementAxis = mapMovementAxisFrom(movementType);
    const sendMovementToEvergine = mapMovementFunctionFrom(movementType);
    sendMovementToEvergine(movementAxis, value, isOffset);
  };

  const onChangeInput = (value: string, teethMovementType: StlOrientationMovementTypes) => {
    const isNegativeSign = value.startsWith('-');
    if ((value.length === 1 && isNegativeSign) || value.length === 0 || !isNaN(Number(value))) {
      const dotPosition = value.indexOf('.');
      const valueToSave =
        value.length > 5 && dotPosition > 0 && VALID_NUMBER_REGEX.test(value)
          ? Number(value).toFixed(MOVEMENTS_DECIMALS)
          : value;
      saveInputValue(valueToSave, teethMovementType);
      return;
    }

    if (!VALID_NUMBER_REGEX.test(value)) {
      return;
    }

    saveInputValue(value, teethMovementType);
  };

  const onInputKeyPress = (e: any, movementType: StlOrientationMovementTypes) => {
    if (e.key !== Key.Enter || !VALID_NUMBER_REGEX.test(e.target.value)) {
      return;
    }

    const value = orientationValues[selectedOrientationTarget][movementType].value;
    const parsedNumber = Math.round(value * 1e12) / 1e12;
    saveInputValue(parsedNumber, movementType);
    const movementAxis = mapMovementAxisFrom(movementType);
    const applyAbsoluteValueFunction = mapAbsoluteMovementFunctionFrom(movementType);
    applyAbsoluteValueFunction(movementAxis, parsedNumber);
  };

  const onAddOrRemoveValueToInput = (tool: StlOrientationToolsType, operation: OperationType) => {
    const movement = orientationValues[selectedOrientationTarget][tool.type];
    const step = tool.measureUnitType === MeasureUnit.Millimeter ? MM_STEP_NUMBER : DEGREES_STEP_NUMBER;

    let newValue = movement.value || 0;
    let valueToBeSent = 0;
    if (operation === OperationType.ADD) {
      newValue += step;
      valueToBeSent = step;
    } else {
      newValue -= step;
      valueToBeSent = step * -1;
    }

    saveInputValue(newValue, tool.type);
    applyOrientationValue(valueToBeSent, tool.type, true);
  };

  const onSelectOrientationTarget = (orientationTarget: StlEditionTarget) => {
    setSelectedOrientationTarget(orientationTarget);
    window.App.webEventsProxy.modelTransformation.sendSelectedArchToTransform(
      mapCapturePositionFrom(orientationTarget)
    );
  };

  const onClickResetAll = () => {
    window.App.webEventsProxy.modelTransformation.sendResetTransform();
    setOrientationValues(initialMovementValues);
    setHasStlBeenOriented(false);
  };

  const getMaxValue = (unitType: MeasureUnit): number => {
    return unitType === MeasureUnit.Millimeter ? 1000 : 360;
  };

  return {
    selectedOrientationTarget,
    onSelectOrientationTarget,
    getInputValue,
    onChangeInput,
    onInputKeyPress,
    onAddOrRemoveValueToInput,
    getMaxValue,
    onClickResetAll
  };
}
