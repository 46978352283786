import { useCallback } from 'react';
import { CapturePosition, Model } from '../common';
import { useFiles } from './useFiles';
import { useFetchModelFiles } from './shared/useFetchModelFiles';

export function useModelHelper() {
  const { fetchNonCachedModelFile } = useFetchModelFiles();

  const dynamicModels = 'DynamicModels';
  const dynamicModelsFullPath = `/Content/${dynamicModels}`;

  const { isDir } = useFiles();

  const loadCapture = useCallback(
    async (position: CapturePosition, modelId: string, url: string) => {
      try {
        const res = await fetchNonCachedModelFile(url);
        const arrayBufferData = await res.arrayBuffer();
        const data = new Int8Array(arrayBufferData);
        const filepath = `${dynamicModels}/${modelId}`;
        const fileFullPath = `${dynamicModelsFullPath}/${modelId}`;
        Module.FS.writeFile(fileFullPath, data);

        return Promise.resolve({ id: modelId, uri: filepath, teethArch: position } as Model);
      } catch (error) {
        console.error(error);
      }
    },
    [dynamicModelsFullPath, fetchNonCachedModelFile]
  );

  const createDirectory = useCallback(() => {
    if (!isDir(dynamicModelsFullPath)) {
      console.log('Creating directory ', dynamicModelsFullPath);
      Module.FS.mkdir(dynamicModelsFullPath);
    }
  }, [dynamicModelsFullPath, isDir]);

  return { dynamicModelsFullPath, loadCapture, createDirectory } as const;
}
