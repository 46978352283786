import { Step } from '../../common';

export function useClientModification() {
  const removeLastStepOnClientModificationCancelled = (list: Step[]): Step[] => {
    if (!!list === false) {
      return list;
    }

    const lastStep = list.length - 1;

    if (list[lastStep].isClientModification !== true) {
      return list;
    }

    const newList = [...list.slice(0, -1)];
    return newList;
  };

  const addNewStepOnClientModification = (list: Step[]) => {
    if (!!list === false) {
      return;
    }

    const lastStep = list.length - 1;

    const duplicatedItem = { ...list[lastStep] };
    duplicatedItem.isClientModification = true;
    duplicatedItem.stepIndex += 1;

    const newList = [...list, duplicatedItem];
    return newList;
  };

  return { addNewStepOnClientModification, removeLastStepOnClientModificationCancelled };
}
