import {
  useTimelineStepsManager,
  useIntermediateSteps,
  useIntermediateStepsContextMenu,
  useDentalMovementsSnapshot,
  useStepsManager
} from '../../../hooks';
import { Timeline } from '../../../common';
import { IntermediateStepsContextMenu } from '../intermediateSteps';
import { useOrthBoundStore } from '../../stores/useStore';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { useCommonBoundStore } from '../../../common/stores/useStore';

import { useShallow } from 'zustand/react/shallow';

export function TreatmentTimeline({ children }: { children: ReactNode }) {
  const isExpertModeEnabled = useCommonBoundStore((state) => state.isExpertModeEnabled);

  const { areMadeChangesOnTeeth, areMovementsLoaded, setShowMovements } = useOrthBoundStore(
    useShallow((state) => ({
      showIpr: state.showIPR,
      areMadeChangesOnTeeth: state.areMadeChangesOnTeeth,
      areMovementsLoaded: state.areMovementsLoaded,
      setShowMovements: state.setShowMovements
    }))
  );

  const { goToStep } = useStepsManager();

  const {
    canShowTimeline,
    activeStep,
    upperSteps,
    lowerSteps,
    upperTeeth,
    lowerTeeth,
    upperAttachments,
    lowerAttachments,
    upperIprLabels,
    lowerIprLabels,
    lastStepIndex
  } = useTimelineStepsManager();

  const {
    selectedStepIndexes,
    handlePreventedNavigation,
    addStepToSelection,
    getIntermediateStepsContextMenuOptions,
    keyStepIndexes,
    stepsModifiedWithNoAutoRecalcIndexes
  } = useIntermediateSteps();

  const {
    showIntermediateStepsContextMenu,
    contextMenuPosition,
    onToggleIntermediateStepsContextMenu,
    onStepContextMenu
  } = useIntermediateStepsContextMenu();

  const { updateDentalMovementsSnapshot } = useDentalMovementsSnapshot();

  useEffect(() => {
    if ((!lowerSteps && !upperSteps) || (lowerSteps.length === 0 && upperSteps.length === 0)) {
      return;
    }

    if (isExpertModeEnabled) {
      window.App.webEventsProxy.common.setEditionMode(true);
      setShowMovements(true);
      goToStep(lastStepIndex, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpertModeEnabled, lastStepIndex]);

  useEffect(() => {
    if (!areMovementsLoaded) {
      return;
    }

    updateDentalMovementsSnapshot();
  }, [areMovementsLoaded, updateDentalMovementsSnapshot]);

  const memoizedContextMenuPosition = useMemo(() => contextMenuPosition, [contextMenuPosition]);
  const memoizedContextMenuOptions = useMemo(
    () => getIntermediateStepsContextMenuOptions(),
    [getIntermediateStepsContextMenuOptions]
  );

  const MemoizedIntermediateStepsContextMenu = React.memo(IntermediateStepsContextMenu);

  return (
    <>
      {children}

      {canShowTimeline && (
        <>
          <Timeline
            upperTeeth={upperTeeth}
            lowerTeeth={lowerTeeth}
            upperSteps={upperSteps}
            lowerSteps={lowerSteps}
            upperAttachments={upperAttachments}
            lowerAttachments={lowerAttachments}
            upperIprLabels={upperIprLabels}
            lowerIprLabels={lowerIprLabels}
            comparerActivated={false}
            vertical={false}
            enableFollowUpOverposition={false}
            onStepContextMenu={onStepContextMenu}
            updateStoreCurrentStep={addStepToSelection}
            storeCurrentStep={activeStep}
            selectedSteps={selectedStepIndexes}
            keyStepsIndexes={keyStepIndexes}
            stepsModifiedWithNoAutoRecalcIndexes={stepsModifiedWithNoAutoRecalcIndexes}
            preventNavigation={areMadeChangesOnTeeth}
            handlePreventedNavigation={handlePreventedNavigation}
          />
          {showIntermediateStepsContextMenu && selectedStepIndexes.length === 1 && (
            <MemoizedIntermediateStepsContextMenu
              position={memoizedContextMenuPosition}
              onToggleMenu={onToggleIntermediateStepsContextMenu}
              items={memoizedContextMenuOptions}
            />
          )}
        </>
      )}
    </>
  );
}
