import { ReactComponent as Movements } from '../../../assets/icons/evergineTools/movements.svg';
import { ReactComponent as VirtualJump } from '../../../assets/icons/evergineTools/virtual-jump.svg';
import { ToolbarConfigProps } from '../../../common';
import { useTeethMovementsToolbarElement } from '../../../hooks';
import { useOrthBoundStore } from '../../stores/useStore';

export const teethMovementsToolbarElement: ToolbarConfigProps = {
  icon: Movements,
  tooltipKey: 'evergineTools.teethMovements',
  customId: 'orthTeethMovementsButton',
  handleClick: () => {
    const currentValue = useOrthBoundStore.getState().showMovements;
    useOrthBoundStore.setState({ showMovements: !currentValue });
  },
  isHidden: () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isHidden } = useTeethMovementsToolbarElement();
    return isHidden;
  }
};

export const virtualJumpToolbarElement: ToolbarConfigProps = {
  icon: VirtualJump,
  tooltipKey: 'evergineTools.virtualJump',
  handleClick: () => undefined
};
