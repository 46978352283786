import { useTranslation } from 'react-i18next';
import { ReactComponent as Info } from '../../../../../assets/icons/modal/info.svg';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';
import { useSaveAndNavigateStep } from '../../../../../hooks/shared/useSaveAndNavigateStep';
import { ModalTypes } from './modal-types.enum';
import { useShallow } from 'zustand/react/shallow';

export function ModalTreatmentPhaseChecks() {
  const { isExpertModeEnabled, toggleModalIsOpened } = useCommonBoundStore(
    useShallow((state) => ({
      isExpertModeEnabled: state.isExpertModeEnabled,
      toggleModalIsOpened: state.toggleModalIsOpened
    }))
  );

  const { isNavigationToNextPhase, navigationRoute, treatmentPhaseIssuesChecks } = useOrthBoundStore(
    useShallow((state) => ({
      isNavigationToNextPhase: state.isNavigationToNextPhase,
      navigationRoute: state.navigationRoute,
      treatmentPhaseIssuesChecks: state.treatmentPhaseIssuesChecks
    }))
  );

  const [t] = useTranslation();
  const { saveAndNavigateStep } = useSaveAndNavigateStep({ isDirectNavigation: true, checkPhaseChanges: true });

  const onReturnButtonClick = () => {
    toggleModalIsOpened('');
  };

  const onContinueButtonClick = async () => {
    toggleModalIsOpened('');

    if (isExpertModeEnabled) {
      toggleModalIsOpened(ModalTypes.ModalClientValidationsSendChanges);
    } else {
      await saveAndNavigateStep(navigationRoute, isNavigationToNextPhase, false, false, false, false);
    }
  };

  return (
    <div className="modal-content" data-testid={'modal-modificationsMade'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <Info className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">{t(`modal.treatmentPhaseChecks.title`)}</h3>
        <ul>
          {Object.entries(treatmentPhaseIssuesChecks)
            .filter(([_, issue]) => issue === true)
            .map(([key]) => (
              <li key={key}>{t(`modal.treatmentPhaseChecks.${key}`)}</li>
            ))}
        </ul>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button onClick={onReturnButtonClick} type="button" className="btn btn-primary flex-fill modal-button">
          {t(`modal.actions.return`)}
        </button>
        <button
          onClick={onContinueButtonClick}
          type="button"
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.continue`)}
        </button>
      </div>
    </div>
  );
}
