import classNames from 'classnames';
import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as EvolutionIcon } from '../../../assets/icons/evolution.svg';
import { ReactComponent as ExpandIcon } from '../../../assets/icons/expand.svg';
import { ReactComponent as CollapseIcon } from '../../../assets/icons/collapse.svg';

import { useTranslation } from 'react-i18next';
import { AttachmentState } from '../../../common';
import { ApplyIprState, IprLabel } from '../../../common/evergine';
import { Shift } from '../dentalMovements';
import './evolutionPanel.scss';
import EvolutionTable from './evolutionTable/EvolutionTable';
import { useOrthBoundStore } from '../../stores/useStore';
import EvolutionPanelNavigator from './EvolutionPanelNavigator';
import { useDentalMovementsStepsManager, useIntermediateSteps } from '../../../hooks';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { AbilityAction, AbilityContext, DentalMovementDTO, OrthoAbilitySubject } from '../../../shared';
import { useShallow } from 'zustand/react/shallow';
import EvolutionPanelInfo from './EvolutionPanelInfo';

interface EvolutionPanelProps {
  opened: boolean;
  shift: Shift;
  onClickClose: () => void;
  getWidthFunction: (getWidth: () => number) => void;
}

type EvolutionPanelContentProps = EvolutionPanelProps & {
  steps: EvolutionStep[];
  applyIPRList: IprLabel[];
  updateIPRList: (list: IprLabel[]) => void;
};

export type EvolutionTooth = {
  fdi: number;
  isExtracted: boolean;
  isMoved: boolean;
  isPassiveAligner: boolean;
};

export type EvolutionInterdentalDistance = {
  leftToothFdi: number;
  rightToothFdi: number;
  distance: number;
  applyIprState: ApplyIprState;
  isSpaceVisible: boolean;
};

export type EvolutionAttachment = {
  toothFdi: number;
  state: AttachmentState;
};

export type EvolutionStep = {
  index: number;
  teeth: EvolutionTooth[];
  attachments: EvolutionAttachment[];
  interdentalDistances: EvolutionInterdentalDistance[];
  relativeDentalMovement: DentalMovementDTO[];
};

const EvolutionPanel: FC<EvolutionPanelProps> = memo(({ ...props }) => {
  const { dentalMovementsSteps, dentalMovementsApplyIPRList, setDentalMovementsApplyIPRList } =
    useDentalMovementsStepsManager(true);

  if (dentalMovementsSteps.length === 0) {
    return null;
  }

  return (
    <EvolutionPanelContent
      steps={dentalMovementsSteps}
      applyIPRList={dentalMovementsApplyIPRList}
      updateIPRList={setDentalMovementsApplyIPRList}
      {...props}
    />
  );
});

const EvolutionPanelContent: FC<EvolutionPanelContentProps> = memo(
  ({ opened, shift, steps, applyIPRList, onClickClose, updateIPRList, getWidthFunction }) => {
    const [t] = useTranslation();
    const ability = useContext(AbilityContext);
    const evolutionPanelRef = useRef<HTMLDivElement>(null);
    const { expandEvolutionPanel, setExpandEvolutionPanel, isEvolutionPanelSliced, fitColumnsInPanel } =
      useOrthBoundStore(
        useShallow((state) => ({
          expandEvolutionPanel: state.expandEvolutionPanel,
          setExpandEvolutionPanel: state.setExpandEvolutionPanel,
          isEvolutionPanelSliced: state.isEvolutionPanelSliced,
          fitColumnsInPanel: state.fitColumnsInPanel
        }))
      );

    const { selectedStepIndexes, isExpertModeEnabled } = useCommonBoundStore(
      useShallow((state) => ({
        selectedStepIndexes: state.selectedStepsIndexes,
        isExpertModeEnabled: state.isExpertModeEnabled
      }))
    );

    const updateWidth = useCallback(() => {
      if (evolutionPanelRef.current) {
        const width = evolutionPanelRef.current.getBoundingClientRect().width;
        getWidthFunction(() => width);
      }
    }, [getWidthFunction]);

    const { addStepAction, removeStepsAction } = useIntermediateSteps(true);

    useEffect(() => {
      if (isEvolutionPanelSliced) {
        const observer = new ResizeObserver(updateWidth);
        const evolutionPanel = evolutionPanelRef.current;
        if (evolutionPanel) {
          observer.observe(evolutionPanel);
        }

        return () => {
          if (evolutionPanel) {
            observer.unobserve(evolutionPanel);
          }
        };
      }
    }, [isEvolutionPanelSliced, updateWidth]);

    useEffect(() => {
      if (evolutionPanelRef?.current && opened) {
        updateWidth();
      }
    }, [updateWidth, opened]);

    const classes = useMemo(
      () =>
        classNames('evolutionpanel', {
          opened,
          ['shift-1']: shift === Shift.InfoPanelOpened
        }),
      [opened, shift]
    );

    const areFitStepsInPanel = useMemo(() => fitColumnsInPanel > steps.length, [steps.length, fitColumnsInPanel]);

    const handleExpandToggle = useCallback(() => {
      setExpandEvolutionPanel(!expandEvolutionPanel);
    }, [expandEvolutionPanel, setExpandEvolutionPanel]);

    const removeStep = useCallback(() => {
      if (selectedStepIndexes.length > 0) removeStepsAction();
    }, [selectedStepIndexes, removeStepsAction]);

    const addStep = useCallback(() => {
      if (selectedStepIndexes.length > 0) addStepAction();
    }, [selectedStepIndexes, addStepAction]);

    const showAddStepButtonIfAllowed = useMemo(() => {
      if (
        !isExpertModeEnabled &&
        (!ability || ability.cannot(AbilityAction.Manage, OrthoAbilitySubject.AddStepsManually))
      )
        return null;
      return (
        <div
          className={`evolutionpanel-navigator-add ${selectedStepIndexes.length === 0 ? 'disabled' : 'enabled'}`}
          onClick={addStep}
        >
          {t('evolutionPanel.addStep')}
        </div>
      );
    }, [isExpertModeEnabled, ability, selectedStepIndexes, t, addStep]);

    const showRemoveStepButtonIfAllowed = useMemo(() => {
      if (
        !isExpertModeEnabled &&
        (!ability || ability.cannot(AbilityAction.Manage, OrthoAbilitySubject.RemoveStepsManually))
      )
        return null;
      return (
        <CloseIcon
          className={`clickable ${selectedStepIndexes.length === 0 ? 'disabled' : 'enabled'}`}
          onClick={removeStep}
        />
      );
    }, [isExpertModeEnabled, ability, selectedStepIndexes, removeStep]);

    if (!opened) return null;

    return (
      <div className={`${classes} no-select`} ref={evolutionPanelRef}>
        <div className={`evolutionpanel-container ${expandEvolutionPanel ? 'expanded' : ''}`}>
          <div className="evolutionpanel-header">
            <div className="evolutionpanel-tools">
              <div className="evolutionpanel-top">
                <div>
                  <EvolutionIcon /> <span className="evolutionpanel-top-title">{t('evolutionPanel.title')}</span>
                </div>
                <div>
                  <EvolutionPanelInfo />
                  {(!areFitStepsInPanel || expandEvolutionPanel) && (
                    <div className="clickable" onClick={handleExpandToggle}>
                      {expandEvolutionPanel ? <CollapseIcon /> : <ExpandIcon />}
                    </div>
                  )}
                  <CloseIcon className="clickable" onClick={onClickClose} />
                </div>
              </div>
              <div className="evolutionpanel-paginator">
                <div className="evolutionpanel-navigator">
                  {!areFitStepsInPanel && <EvolutionPanelNavigator steps={steps} />}
                </div>
                <div className="evolutionpanel-buttons">
                  {showRemoveStepButtonIfAllowed}
                  {showAddStepButtonIfAllowed}
                </div>
              </div>
            </div>
          </div>
          <EvolutionTable steps={steps} updateIPRList={updateIPRList} applyIPRList={applyIPRList} opened={opened} />
        </div>
      </div>
    );
  }
);

export default memo(EvolutionPanel);
