import { ReactNode, useContext } from 'react';
import { AbilityAction, AbilityContext, SecretAlignerAbilitySubject } from '../../shared';
import { ForbiddenScreen } from '..';
import { useEvergineStore } from 'evergine-react';

type GateKeeperGuardType = {
  action: AbilityAction;
  subject: SecretAlignerAbilitySubject;
  children: ReactNode;
};

export function GateKeeperGuard({ action, subject, children }: GateKeeperGuardType) {
  const ability = useContext(AbilityContext);

  if (!ability || ability.cannot(action, subject)) {
    return <ForbiddenScreen />;
  }

  return <>{children}</>;
}
