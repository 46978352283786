import { useCallback, useEffect, useMemo, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Panels } from '../../../../assets/icons/evergineTools/panels.svg';
import { useOrthBoundStore } from '../../../stores/useStore';
import { ToolsDropdownBase } from './ToolsDropdownBase';
import { useShallow } from 'zustand/react/shallow';

const OrthPanels = memo((props: any) => {
  const { dataFor, handleClick, className } = props;
  const [t] = useTranslation();
  const {
    showBoltonPanel,
    setShowBoltonPanel,
    showMovementsTable,
    setShowMovementsTable,
    upperDentalMovements,
    lowerDentalMovements,
    showEvolutionPanel,
    setShowEvolutionPanel
  } = useOrthBoundStore(
    useShallow((state) => ({
      showBoltonPanel: state.showBoltonPanel,
      setShowBoltonPanel: state.setShowBoltonPanel,
      showMovementsTable: state.showMovementsTable,
      isCompareTreatmentsActivated: state.isCompareTreatmentsActivated,
      setShowMovementsTable: state.setShowMovementsTable,
      upperDentalMovements: state.upperDentalMovements,
      lowerDentalMovements: state.lowerDentalMovements,
      showEvolutionPanel: state.showEvolutionPanel,
      setShowEvolutionPanel: state.setShowEvolutionPanel
    }))
  );

  const [toggleEvolution, setToggleEvolution] = useState(false);
  const [toggleMovements, setToggleMovements] = useState(false);
  const [toggleBolton, setToggleBolton] = useState(false);

  const onToggleMovements = useCallback(() => {
    setShowMovementsTable(!showMovementsTable);
  }, [setShowMovementsTable, showMovementsTable]);

  const onToggleEvolution = useCallback(() => {
    setShowEvolutionPanel(!toggleEvolution);
  }, [setShowEvolutionPanel, toggleEvolution]);

  const onToggleBolton = useCallback(() => {
    setShowBoltonPanel(!showBoltonPanel);
  }, [setShowBoltonPanel, showBoltonPanel]);

  useEffect(() => {
    setToggleBolton(showBoltonPanel);
  }, [showBoltonPanel]);

  useEffect(() => {
    setToggleMovements(showMovementsTable);
  }, [showMovementsTable]);

  useEffect(() => {
    setToggleEvolution(showEvolutionPanel);
  }, [showEvolutionPanel]);

  const itemsTools = useMemo(
    () => [
      {
        name: t('evergineTools.evolutionPanel'),
        isVisible: toggleEvolution,
        onClick: onToggleEvolution,
        isDisabled: !lowerDentalMovements && !upperDentalMovements,
        order: 1
      },
      {
        name: t('evergineTools.movementsTable'),
        isVisible: toggleMovements,
        onClick: onToggleMovements,
        isDisabled: !lowerDentalMovements && !upperDentalMovements,
        order: 2
      },
      {
        name: t('evergineTools.bolton'),
        id: 'bolton-panel-toggle',
        isVisible: toggleBolton,
        onClick: onToggleBolton,
        isDisabled: !lowerDentalMovements || !upperDentalMovements,
        order: 3
      }
    ],
    [
      lowerDentalMovements,
      onToggleBolton,
      onToggleEvolution,
      onToggleMovements,
      t,
      toggleBolton,
      toggleEvolution,
      toggleMovements,
      upperDentalMovements
    ]
  );

  return (
    <>
      <ToolsDropdownBase
        title={t('evergineTools.panels')}
        prefix="orthpanels"
        dataFor={dataFor}
        toggleIcon={Panels}
        className={className}
        handleToggleClick={handleClick}
        items={itemsTools}
      />
    </>
  );
});

export default OrthPanels;
