import { useCallback, useEffect, useState } from 'react';
import { container } from '../../common/ioc/ioc.config';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { IHttpService } from '../http';
import { ILoginService } from '../login';
import { IAuthService } from './iauth.service';
import { isSaasApiEnabled } from '../settings';

export function useAuthConfig() {
  const settings = useCommonBoundStore((store) => store.settings);

  const [isLogged, setIsLogged] = useState<boolean>(undefined);
  const authService = container.get<IAuthService>(INJECTED_TYPES.IAuthService);

  const isValidToken = useCallback(async () => {
    return await authService.isValidToken();
  }, []);

  const getAuthToken = useCallback(async () => {
    const params = new URLSearchParams(window.location.search);
    const authTokenFromParams = params.get(authService.authTokenUrlParamIndex);

    if (!isSaasApiEnabled() && (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')) {
      return await developModeLogin();
    }

    if (!authTokenFromParams) {
      const savedToken = authService.getAccessToken();

      if (savedToken) {
        authService.setAccessToken(savedToken);
        return savedToken;
      }

      return null;
    } else {
      authService.setAccessToken(authTokenFromParams);
      return authTokenFromParams;
    }
  }, []);

  const getPublicAccessToken = useCallback(async () => {
    const params = new URLSearchParams(window.location.search);
    const publicAccessTokenFromParams = params.get(authService.publicTokenUrlParamIndex);

    if (!publicAccessTokenFromParams) {
      const savedToken = authService.getPublicAccessToken();

      if (savedToken) {
        authService.setPublicAccessToken(savedToken);
        return savedToken;
      }

      return null;
    } else {
      authService.setPublicAccessToken(publicAccessTokenFromParams);
      return publicAccessTokenFromParams;
    }
  }, []);

  const developModeLogin = async () => {
    const loginService = container.get<ILoginService>(INJECTED_TYPES.ILoginService);

    try {
      const authToken = await loginService.fetchToken();
      authService.setAccessToken(authToken);
      return authToken;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const removeTokensFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('authToken') || searchParams.has('publicAccessToken')) {
      searchParams.delete('authToken');
      searchParams.delete('publicAccessToken');
      const newURL = `${window.location.pathname}?${searchParams.toString()}${window.location.hash}`;
      window.history.replaceState({}, '', newURL);
    }
  };

  useEffect(() => {
    const configAuthCalls = async () => {
      const authToken = await getAuthToken();
      const publicAccessToken = await getPublicAccessToken();

      if (authToken !== null || publicAccessToken !== null) {
        const httpService = container.get<IHttpService>(INJECTED_TYPES.IHttpService);
        if (!publicAccessToken) {
          httpService.setupAuth(authToken, false);
        } else {
          httpService.setupAuth(publicAccessToken, true);
        }
        setIsLogged(true);
        removeTokensFromURL();
      } else {
        setIsLogged(false);
      }
    };

    configAuthCalls();
  }, [settings]);

  return {
    isLogged,
    isValidToken
  };
}
