import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrthBoundStore } from '../../../../orthodontics/stores/useStore';
import Switch from '../../switch/Switch';

import { ReactComponent as Copy } from '../../../../assets/icons/copy.svg';
import { ReactComponent as Delete } from '../../../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import { useCaseData } from '../../../../hooks';
import { CaseVersion } from '../../../../shared';
import { ModalTypes } from '../../../../surgeries/components/layout';
import { useCommonBoundStore } from '../../../stores/useStore';
import { CaseVersionItem } from './CaseVersionItem';
import './togglecomparetreatments.scss';
import { useShallow } from 'zustand/react/shallow';

type ToggleCompareTreatmentsProps = {
  caseId: string;
  isCompareDisabled?: boolean;
  versions: CaseVersion[];
  selectedVersionId?: string;
  setSelectedVersion?: (version: CaseVersion) => void;
};

export type ActionsButton = {
  Icon: FunctionComponent;
  action: (versionId: string, newName?: string) => Promise<void>;
  labelKey: string;
  isEditButton?: boolean;
  isDisabled?: boolean;
};

export default function ToggleCompareTreatments({
  caseId,
  isCompareDisabled,
  versions,
  selectedVersionId,
  setSelectedVersion
}: ToggleCompareTreatmentsProps) {
  const {
    setIsCompareTreatmentsActivated,
    isCompareTreatmentsActivated,
    lowerDentalMovements,
    upperDentalMovements,
    setVersionToCompare
  } = useOrthBoundStore(
    useShallow((state) => ({
      setIsCompareTreatmentsActivated: state.setIsCompareTreatmentsActivated,
      isCompareTreatmentsActivated: state.isCompareTreatmentsActivated,
      lowerDentalMovements: state.lowerDentalMovements,
      upperDentalMovements: state.upperDentalMovements,
      setVersionToCompare: state.setVersionToCompare
    }))
  );
  const [t] = useTranslation();
  const { copyVersion, editVersion, getPatientCaseVersion } = useCaseData(caseId);
  const [editVersionId, setEditVersionId] = useState<string | null>(null);
  const [compareWithVersionId, setCompareWithVersionId] = useState<string | null>(null);
  const { toggleModalIsOpened, updateVersionToDelete } = useCommonBoundStore((store) => ({
    toggleModalIsOpened: store.toggleModalIsOpened,
    updateVersionToDelete: store.updateVersionToDelete
  }));

  useEffect(() => {
    if (!isCompareDisabled) {
      return;
    }

    toggleCompareTreatments(false);
  }, [isCompareDisabled]);

  const toggleCompareTreatments = useCallback(
    (value: boolean) => {
      setIsCompareTreatmentsActivated(value);

      if (value === false) {
        setVersionToCompare(null);
      }
    },
    [setIsCompareTreatmentsActivated]
  );

  const onSelectVersion = useCallback(
    (newVersion: CaseVersion) => {
      if (editVersionId || newVersion.id === selectedVersionId || newVersion.id === compareWithVersionId) {
        return;
      }

      if (isCompareTreatmentsActivated) {
        setCompareWithVersionId(newVersion.id);
        setVersionToCompare(newVersion);
      } else {
        setSelectedVersion(newVersion);
      }
    },
    [editVersionId, selectedVersionId, compareWithVersionId, isCompareTreatmentsActivated]
  );

  const onClickEdit = useCallback((versionToEditId: string) => {
    setEditVersionId(versionToEditId);
  }, []);

  const onClickSave = useCallback(
    async (versionToEditId: string, newName: string) => {
      if (!newName) {
        setEditVersionId(null);
        return;
      }

      const versionToEditIndex = versions.findIndex((v) => v.id === versionToEditId);
      versions[versionToEditIndex].name = newName;

      await editVersion(versions[versionToEditIndex]);

      setEditVersionId(null);
    },
    [editVersionId, versions, editVersion]
  );

  const onClickCopy = useCallback(
    async (versionIdToCopy: string) => {
      if (editVersionId) {
        return;
      }

      await copyVersion(versionIdToCopy);
    },
    [editVersionId, versions, copyVersion]
  );

  const showConfirmDeleteModal = useCallback(
    async (versionIdToDelete: string) => {
      if (editVersionId) {
        return;
      }

      const versionToDelete = versions.find((v) => v.id === versionIdToDelete);

      updateVersionToDelete(versionToDelete);
      toggleModalIsOpened(ModalTypes.ModalConfirmDeleteVersion);
    },
    [editVersionId, versions]
  );

  const getActions = useCallback(
    (versionId: string): ActionsButton[] => {
      return [
        {
          Icon: Edit,
          action: async (versionId: string, newName: string) =>
            !editVersionId ? onClickEdit(versionId) : await onClickSave(versionId, newName),
          labelKey: 'common.edit',
          isEditButton: true
        },
        {
          Icon: Copy,
          action: onClickCopy,
          labelKey: 'common.duplicate'
        },
        {
          Icon: Delete,
          action: showConfirmDeleteModal,
          labelKey: 'common.delete',
          isDisabled: true
          //isDisabled: versionId === selectedVersionId
        }
      ];
    },
    [versions, onClickEdit, onClickCopy, showConfirmDeleteModal, onClickSave, editVersionId, selectedVersionId]
  );

  useEffect(() => {
    if (!isCompareTreatmentsActivated) {
      setCompareWithVersionId(null);
    }
  }, [isCompareTreatmentsActivated]);

  const isSwitchEnabled = useMemo(() => {
    return versions.length > 1 && (lowerDentalMovements !== null || upperDentalMovements !== null);
  }, [lowerDentalMovements, upperDentalMovements, versions]);

  useEffect(() => {
    if (!compareWithVersionId) {
      return;
    }
    const version = getPatientCaseVersion(compareWithVersionId);
  }, [compareWithVersionId]);

  return (
    <div className="togglecomparetreatments">
      <div className="togglecomparetreatments-row">
        <div className="togglecomparetreatments-label">{t('caseVersionSelector.toggleCompareTreatments')}</div>
        {/* <Switch handleToggle={toggleCompareTreatments} disabled={!isSwitchEnabled} /> */}
        <Switch handleToggle={toggleCompareTreatments} disabled={true} />
      </div>
      <div className="togglecomparetreatments-container">
        {versions ? (
          versions.map((v: CaseVersion, i: number) => {
            const id = `select-item-${v.caseId}-${i}`;
            const isEditingVersion = editVersionId === v.id;

            return (
              versions && (
                <div className="togglecomparetreatments-item" key={id}>
                  <CaseVersionItem
                    editVersionId={editVersionId}
                    selectedVersionId={selectedVersionId}
                    compareWithVersionId={compareWithVersionId}
                    actions={getActions(v.id)}
                    version={v}
                    isEditingVersion={isEditingVersion}
                    isComparingTreatmentActive={isCompareTreatmentsActivated}
                    handleSelectVersion={onSelectVersion}
                  />
                </div>
              )
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
