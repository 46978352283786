import { FC, memo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { EvergineCanvas as EvergineCanvasReact } from 'evergine-react';
import { EVERGINE_CANVAS_ID } from '../config';
import { UseCanvasSizeType } from '../../../hooks/shared/useCanvasSize';
import { useTranslation } from 'react-i18next';
import { ErrorBoundaryMessage } from '../..';

type EvergineProps = {
  canvasSize: UseCanvasSizeType;
  handleContextMenu: (e: any) => void;
};

const EvergineAppCanvas: FC<EvergineProps> = ({ canvasSize, handleContextMenu }) => {
  const [t] = useTranslation();

  return (
    <div
      className="app"
      style={{
        display: 'inline'
      }}
    >
      <ErrorBoundary FallbackComponent={ErrorBoundaryMessage}>
        <div style={{ marginLeft: `${canvasSize.offsetX}px` }}>
          <EvergineCanvasReact
            canvasId={EVERGINE_CANVAS_ID}
            width={canvasSize.canvasWidth}
            height={canvasSize.canvasHeight}
            onContextMenu={handleContextMenu}
          />
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default memo(EvergineAppCanvas);
