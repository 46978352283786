import { StateCreator } from 'zustand';
export interface IntermediateStepsSlice {
  areMadeChangesOnTeeth: boolean;
  isRecalculateCancelled: boolean;
  isAIInterpolationAsked: boolean;
  canAskAIInterpolation: boolean;
  upperCanAskAIInterpolation: boolean;
  lowerCanAskAIInterpolation: boolean;
  shouldUpdateMovements: boolean;
  setAreMadeChangesOnTeeth: (areMadeChangesOnTeeth: boolean) => void;
  setIsRecalculateCancelled: (isRecalculateCancelled: boolean) => void;
  setIsAIInterpolationAsked: (isAIInterpolationAsked: boolean) => void;
  setCanAskAIInterpolation: (canAskAIInterpolation: boolean) => void;
  setShouldUpdateMovements: (shouldUpdateMovements: boolean) => void;
  setUpperCanAskAIInterpolation: (upperCanAskAIInterpolation: boolean) => void;
  setLowerCanAskAIInterpolation: (lowerCanAskAIInterpolation: boolean) => void;
}

export const createIntermediateStepsSlice: StateCreator<IntermediateStepsSlice, [], [], IntermediateStepsSlice> = (
  set
) => ({
  areMadeChangesOnTeeth: false,
  isRecalculateCancelled: false,
  isAIInterpolationAsked: false,
  canAskAIInterpolation: false,
  shouldUpdateMovements: true,
  upperCanAskAIInterpolation: false,
  lowerCanAskAIInterpolation: false,
  setAreMadeChangesOnTeeth: (areMadeChangesOnTeeth: boolean) => set((_) => ({ areMadeChangesOnTeeth })),
  setIsRecalculateCancelled: (isRecalculateCancelled: boolean) => set((_) => ({ isRecalculateCancelled })),
  setIsAIInterpolationAsked: (isAIInterpolationAsked: boolean) => set((_) => ({ isAIInterpolationAsked })),
  setCanAskAIInterpolation: (canAskAIInterpolation: boolean) => set((_) => ({ canAskAIInterpolation })),
  setShouldUpdateMovements: (shouldUpdateMovements: boolean) => set((_) => ({ shouldUpdateMovements })),
  setUpperCanAskAIInterpolation: (upperCanAskAIInterpolation: boolean) => set((_) => ({ upperCanAskAIInterpolation })),
  setLowerCanAskAIInterpolation: (lowerCanAskAIInterpolation: boolean) => set((_) => ({ lowerCanAskAIInterpolation }))
});
