import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { useOrthBoundStore } from '../../stores/useStore';
import { PointerPosition } from '../../stores/slices/orthContextMenuSlice';
import { EmptySceneContextMenu } from './EmptySceneContextMenu';
import { TeethContextMenu, useTeethContextMenu } from './teethContextMenu';
import { AttachesContextMenu, useAttachesContextMenu } from './attachesContextMenu';
import { PublishContextMenu } from './publishContextMenu';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { PROFILES_TYPE } from '../../../shared';
import { useShallow } from 'zustand/react/shallow';

export enum PagesWithSpecificOptions {
  Treatment,
  Publish
}

type OrthContextualMenuProps = {
  preventContextMenu?: boolean;
  pageWithSpecificOptions?: PagesWithSpecificOptions;
};

export function OrthContextualMenu({ preventContextMenu = false, pageWithSpecificOptions }: OrthContextualMenuProps) {
  const { isOrthContextMenuVisible, pointerPosition, hoveredAttach, setIsOrthContextMenuVisible } = useOrthBoundStore(
    useShallow((state) => ({
      isOrthContextMenuVisible: state.isOrthContextMenuVisible,
      pointerPosition: state.pointerPosition,
      hoveredAttach: state.hoveredAttach,
      setIsOrthContextMenuVisible: state.setIsOrthContextMenuVisible
    }))
  );

  const [selectedTeethPosition, setSelectedTeethPosition] = useState<PointerPosition>();

  const closeContextMenu = useCallback(() => {
    setIsOrthContextMenuVisible(false);
  }, [setIsOrthContextMenuVisible]);

  const { canTeethContextMenuBeShown } = useTeethContextMenu(closeContextMenu);
  const { canAttachesMenuBeShown, hideAttachesMenu } = useAttachesContextMenu();

  useEffect(() => {
    if (selectedTeethPosition !== pointerPosition) {
      setSelectedTeethPosition(pointerPosition);
    }
  }, [pointerPosition, selectedTeethPosition]);

  const positionData: CSSProperties = useMemo(
    () => ({
      left: selectedTeethPosition?.clientX,
      top: selectedTeethPosition?.clientY
    }),
    [selectedTeethPosition]
  );

  const menuVisibility = useMemo(() => {
    const isClient = useBoundStore.getState().userProfile?.type === PROFILES_TYPE.client;

    return {
      isTeethContextMenu:
        canTeethContextMenuBeShown &&
        pageWithSpecificOptions === PagesWithSpecificOptions.Treatment &&
        hoveredAttach === null,
      isAttachesContextMenu: canAttachesMenuBeShown && pageWithSpecificOptions === PagesWithSpecificOptions.Treatment,
      isPublishContextMenu: !isClient && pageWithSpecificOptions === PagesWithSpecificOptions.Publish
    };
  }, [canTeethContextMenuBeShown, canAttachesMenuBeShown, pageWithSpecificOptions, hoveredAttach]);

  const renderMenu = useMemo(() => {
    if (menuVisibility.isTeethContextMenu) {
      return (
        <TeethContextMenu
          onToggleAction={closeContextMenu}
          positionData={positionData}
          show={isOrthContextMenuVisible}
          teethPosition={selectedTeethPosition}
        />
      );
    }

    if (menuVisibility.isAttachesContextMenu) {
      return (
        <AttachesContextMenu
          onClickOutside={hideAttachesMenu}
          onToggleAction={closeContextMenu}
          positionData={positionData}
          show={isOrthContextMenuVisible}
        />
      );
    }

    if (menuVisibility.isPublishContextMenu) {
      return (
        <PublishContextMenu
          onClickOutside={closeContextMenu}
          onToggleAction={closeContextMenu}
          positionData={positionData}
          show={isOrthContextMenuVisible}
        />
      );
    }

    return (
      <EmptySceneContextMenu
        onToggleAction={closeContextMenu}
        positionData={positionData}
        show={isOrthContextMenuVisible}
      />
    );
  }, [
    menuVisibility,
    closeContextMenu,
    positionData,
    isOrthContextMenuVisible,
    selectedTeethPosition,
    hideAttachesMenu
  ]);

  return renderMenu;
}
