import { useEffect } from 'react';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useCamViewChange, useUtils } from '../../shared';
import { useDentalMovementsSnapshot } from './useDentalMovementsSnapshot';
import { AttachesInputDTO, AttachesResponseDTO, ICalculationAttachesService, teethFdis } from '../../../shared';
import { container, INJECTED_TYPES } from '../../../common';
import { TeethArchPosition } from '../../../models';

export function useAttachesCalculation() {
  const setAreAttachesSet = useOrthBoundStore((state) => state.setAreAttachesSet);
  const setShowAttachesPanel = useOrthBoundStore((state) => state.setShowAttachesPanel);
  const { setWebBusy } = useBoundStore((state) => ({
    setMessageInfo: state.setMessageInfo,
    setWebBusy: state.setWebBusy
  }));

  const { updateMovements } = useUtils();
  const { updateDentalMovementsSnapshot } = useDentalMovementsSnapshot();
  const { currentArchView } = useCamViewChange();

  const calculateAttachesService = container.get<ICalculationAttachesService>(
    INJECTED_TYPES.ICalculationAttachesService
  );

  const calculateAttaches = async () => {
    try {
      setWebBusy(true);

      const resultInputAttach: AttachesInputDTO = await window.App.webEventsProxy.attachments.getInputAttachments();
      const currentArch = currentArchView();
      const responseIA = await calculateAttachesService.getAIAutomaticAttaches(resultInputAttach);
      const filteredResponseIA = filterByArch(responseIA, currentArch);
      await window.App.webEventsProxy.attachments.updateAttachmentsFromAI({
        ...filteredResponseIA,
        teethArch: currentArch
      });
      updateMovements();
      setAreAttachesSet(true);
      updateDentalMovementsSnapshot();
      setShowAttachesPanel(false);
      window.App.webEventsProxy.layers.showAttachments(true);
    } catch (error) {
      console.error(error);
    } finally {
      setWebBusy(false);
    }
  };

  const filterByArch = (attachesResponse: AttachesResponseDTO, arch: TeethArchPosition) => {
    if (arch == TeethArchPosition.LOWER) {
      attachesResponse.attaches = attachesResponse.attaches.filter((attach) =>
        teethFdis.lowerTeeth.includes(attach.toothFdi)
      );
    } else if (arch == TeethArchPosition.UPPER) {
      attachesResponse.attaches = attachesResponse.attaches.filter((attach) =>
        teethFdis.upperTeeth.includes(attach.toothFdi)
      );
    }

    return attachesResponse;
  };

  return {
    calculateAttaches
  };
}
