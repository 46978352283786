import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { NavBarAction } from './NavBarAction';
import SaveChangesIcon from '../../../../assets/icons/save.svg';
import { useHandleEventListener, useModalSaveChanges } from '../../../../hooks';
import { useCallback, useEffect, useState } from 'react';
import { useBoundStore } from '../../../../surgeries/stores/useStore';
import { useCommonBoundStore } from '../../../stores/useStore';

export default (actionProps: any) => {
  const setWebBusyMessage = useBoundStore((state) => state.setWebBusyMessage);
  const isExpertModeEnabled = useCommonBoundStore((state) => state.isExpertModeEnabled);

  const [t] = useTranslation();
  const { EventNames, attachEventListenerToPage, dettachEventListenerToPage } = useHandleEventListener();
  const { getPhaseSaveChange } = useModalSaveChanges();
  const { saveChanges } = getPhaseSaveChange();

  const [saveChangesTrigger, setSaveChangesTrigger] = useState(false);

  const onSaveChanges = async () => {
    setWebBusyMessage(t('common.loaderActions.savingChanges'));
    await saveChanges(true);
    setWebBusyMessage(null);
    setSaveChangesTrigger(false);
  };

  const buttonId = 'saveChanges';

  const handleKeyDown = useCallback(
    (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        if (!saveChangesTrigger) {
          setSaveChangesTrigger(true);
        }
      }
    },
    [onSaveChanges]
  );

  useEffect(() => {
    if (saveChangesTrigger) {
      onSaveChanges();
    }
  }, [saveChangesTrigger]);

  useEffect(() => {
    attachEventListenerToPage(EventNames.Keydown, handleKeyDown);

    return () => {
      dettachEventListenerToPage(EventNames.Keydown, handleKeyDown);
    };
  }, []);

  return (
    !isExpertModeEnabled && (
      <>
        <NavBarAction
          icon={SaveChangesIcon}
          dataFor={buttonId}
          altText={t('navbar.actions.saveChanges')}
          action={onSaveChanges}
        />
        <ReactTooltip id={buttonId} place="bottom" effect="solid" className="tooltip" type="dark">
          {t(`navbar.actions.saveChanges`)}
        </ReactTooltip>
      </>
    )
  );
};
