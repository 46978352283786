import { useEffect, useMemo, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as DropdownToggleIcon } from '../../../../assets/icons/arrow-dropdown.svg';
import { ReactComponent as OpenJaws } from '../../../../assets/icons/jaw-open.svg';
import { ReactComponent as BothTheethArch } from '../../../../assets/icons/jaws.svg';
import { ReactComponent as LowerTheethArch } from '../../../../assets/icons/lower-teetharch.svg';
import { ReactComponent as TickIcon } from '../../../../assets/icons/tick.svg';
import { ReactComponent as UpperTheethArch } from '../../../../assets/icons/upper-teetharch.svg';
import { PathLevels, TeethArchPosition } from '../../../../models';
import './baseTeethArchDropdown.scss';
import { useLocation } from 'react-router-dom';
import { CommonPagesUrl } from '../../../../types';

export type DropdownItems = {
  translationKey: string;
  type: TeethArchPosition;
};

type BaseTeethArchDropdownProps = {
  dropdownOptions: DropdownItems[];
  handleSelectOption: Function;
  defaultType: TeethArchPosition;
};

export const BaseTeethArchDropdown = ({
  dropdownOptions,
  handleSelectOption,
  defaultType
}: BaseTeethArchDropdownProps) => {
  const [t] = useTranslation();

  const [iconToShow, setIconToShow] = useState<TeethArchPosition>();
  const [selectedOption, setSelectedOption] = useState<TeethArchPosition>(defaultType);
  const { pathname } = useLocation();
  const shortedPathName = useMemo(() => `/${pathname.split('/')[PathLevels.RouteView]}`, [pathname]);

  const iconByTeethPosition: { [key: string]: any } = {
    [TeethArchPosition.UPPER]: <UpperTheethArch />,
    [TeethArchPosition.LOWER]: <LowerTheethArch />,
    [TeethArchPosition.BOTH]: <BothTheethArch />,
    [TeethArchPosition.BOTH_OPEN]: <OpenJaws />
  };

  const onSelectOption = (selection: TeethArchPosition) => {
    setSelectedOption(selection);
    handleSelectOption(selection);
  };

  const dropDownItem = (translationKey: string, itemArchPosition: TeethArchPosition) => {
    return (
      <Dropdown.Item
        key={translationKey}
        onClick={() => onSelectOption(itemArchPosition)}
        className="baseteetharchdropdown-dropdown-item"
        disabled={shortedPathName === CommonPagesUrl.StlCaptures}
      >
        {iconByTeethPosition[itemArchPosition]}
        {t(`navbar.actions.maxilla.${translationKey}`)}
        {selectedOption === itemArchPosition && (
          <TickIcon className="baseteetharchdropdown-dropdown-item--check position-absolute ml-3" />
        )}
      </Dropdown.Item>
    );
  };

  const buttonId = 'baseTeethArchDropdown';
  const dropdownButtonProps: any = {
    'data-for': buttonId,
    'data-tip': true
  };

  useEffect(() => {
    const icon = iconByTeethPosition[selectedOption];
    setIconToShow(icon);
  }, [selectedOption]);

  return (
    <>
      <Dropdown className="baseteetharchdropdown-dropdown" autoClose="outside">
        <Dropdown.Toggle variant="language" {...dropdownButtonProps}>
          {iconToShow}
          <DropdownToggleIcon className="icon rotate-90 ms-3" />
        </Dropdown.Toggle>

        <Dropdown.Menu>{dropdownOptions.map((item) => dropDownItem(item.translationKey, item.type))}</Dropdown.Menu>
      </Dropdown>

      <ReactTooltip id={buttonId} place="bottom" effect="solid" className="tooltip" type="dark">
        {t(`navbar.actions.information.jaw`)}
      </ReactTooltip>
    </>
  );
};
