import { useCallback, useEffect, useState } from 'react';
import { useOrthBoundStore } from '../../../stores/useStore';
import { useShallow } from 'zustand/react/shallow';

export function useAttachesContextMenu() {
  const [canAttachesMenuBeShown, setCanBeAttachesMenuBeShown] = useState<boolean>();

  const { selectedAttache, hoveredAttach } = useOrthBoundStore(
    useShallow((state) => ({
      selectedAttache: state.selectedAttache,
      hoveredAttach: state.hoveredAttach
    }))
  );

  useEffect(() => {
    setCanBeAttachesMenuBeShown(selectedAttache !== null || !!hoveredAttach?.toothFdi);
  }, [selectedAttache]);

  const hideAttachesMenu = useCallback(() => {
    if (selectedAttache === null) {
      setCanBeAttachesMenuBeShown(false);
    }
  }, [setCanBeAttachesMenuBeShown, selectedAttache]);

  return {
    canAttachesMenuBeShown,
    hideAttachesMenu
  };
}
