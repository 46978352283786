import { useEvergineStore } from 'evergine-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CapturePosition, MatchingInfo, Model3dType, Stage } from '../../../common/evergine/types';
import { useCaseData, useCaseId, useLoadFilesIntoFS, useTeetharchAffectation } from '../../../hooks';
import { useFiles } from '../../../hooks/useFiles';
import { TeethArchPosition } from '../../../models';
import { useBoundStore } from '../../stores/useStore';
import { ImageHeader } from '../layout/imageHeader';

const dynamicModels = 'DynamicModels';
const dynamicModelsFullPath = `/Content/${dynamicModels}`;

export function MatchingResult() {
  const [caseId] = useCaseId();
  const { affectations } = useTeetharchAffectation();
  const [t] = useTranslation();
  const { evergineReady } = useEvergineStore();
  const { patientCase, getPatientCase } = useCaseData(caseId);
  const { teethArch, setCanBeConfirmed, setShowCanvas, showCanvas } = useBoundStore((state) => ({
    teethArch: state.teethArch,
    setCanBeConfirmed: state.setCanBeConfirmed,
    setShowCanvas: state.setShowCanvas,
    showCanvas: state.showCanvas
  }));
  const [isTeethArchVisited, setIsTeethArchVisited] = useState<string[]>([teethArch]);
  const [isFilesLoaded, setIsFilesLoaded] = useState<boolean>(false);
  const {
    loadUpperDicomInFS,
    loadUpperModel3DInFS,
    loadLowerDicomInFS,
    loadLowerModel3DInFS,
    patientCaseFilesToMatchingInfo
  } = useLoadFilesIntoFS();
  const { hasLowerAffectation, hasUpperAffectation } = affectations;
  const { isDir } = useFiles();

  useEffect(() => {
    if (evergineReady) {
      window.App.webEventsProxy.common.setStage(Stage.MatchingSetup);
    }
  }, [evergineReady]);

  useEffect(() => {
    if (evergineReady && patientCase) {
      const loadFiles = async () => {
        if (!isDir(dynamicModelsFullPath)) {
          console.log('Creating directory ', dynamicModelsFullPath);
          Module.FS.mkdir(dynamicModelsFullPath);
          await loadUpperDicomInFS(patientCase);
          await loadUpperModel3DInFS(patientCase);
          await loadLowerDicomInFS(patientCase);
          await loadLowerModel3DInFS(patientCase);
        }

        setIsFilesLoaded(true);
      };

      if (isFilesLoaded) {
        const loadCanvasInfo = async () => {
          setShowCanvas(true);
          const patientCase = await getPatientCase();
          const matchingInfo: Record<string, MatchingInfo> = patientCaseFilesToMatchingInfo(patientCase);

          if (!matchingInfo[teethArch].dicom || !matchingInfo[teethArch].model3D) {
            setShowCanvas(false);
            return;
          }

          window.App.webEventsProxy.common.loadModels([
            {
              id: matchingInfo[teethArch].dicom,
              uri: `DynamicModels/${matchingInfo[teethArch].dicom}`,
              teethArch: teethArch === TeethArchPosition.UPPER ? CapturePosition.UPPER : CapturePosition.LOWER
            },
            {
              id: matchingInfo[teethArch].model3D,
              uri: `DynamicModels/${matchingInfo[teethArch].model3D}`,
              teethArch: teethArch === TeethArchPosition.UPPER ? CapturePosition.UPPER : CapturePosition.LOWER,
              model3dType: Model3dType.Scan
            }
          ]);

          window.App.webEventsProxy.surgeries.loadMatchingInfo(matchingInfo[teethArch]);
        };

        loadCanvasInfo();
      }

      loadFiles();
    }
  }, [evergineReady, patientCase, isFilesLoaded, teethArch]);

  useEffect(() => {
    setIsTeethArchVisited([...isTeethArchVisited, teethArch]);
  }, [teethArch]);

  useEffect(() => {
    setCanBeConfirmed(false);

    const hasUpperAndLowerAffectations = hasLowerAffectation && hasUpperAffectation;
    const hasOnlyUpperAffectations = !hasLowerAffectation && hasUpperAffectation;
    const hasOnlyLowerAffectations = hasLowerAffectation && !hasUpperAffectation;

    if (hasUpperAndLowerAffectations) {
      if (isTeethArchVisited.includes('UPPER') && isTeethArchVisited.includes('LOWER') === true) {
        setCanBeConfirmed(true);
      }
      return;
    }

    if (hasOnlyUpperAffectations) {
      if (isTeethArchVisited.includes('UPPER')) {
        setCanBeConfirmed(true);
      }
      return;
    }

    if (hasOnlyLowerAffectations) {
      if (isTeethArchVisited.includes('LOWER')) {
        setCanBeConfirmed(true);
      }
      return;
    }
  }, [hasLowerAffectation, hasUpperAffectation, isTeethArchVisited]);

  return (
    <div className="captures container-fluid g-0 d-flex flex-column">
      {teethArch === TeethArchPosition.UPPER ? (
        <div className={`flex-fill captures-item`}>
          <div className="d-flex">
            <ImageHeader title={'Matching Superior'}></ImageHeader>
            {(!evergineReady || !showCanvas) && (
              <div className="capture-message position-absolute top-50 w-100">
                <div className="section-message">
                  <span>{t(`imageManagement.common.noFiles`)}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={`flex-fill captures-item`}>
          <div className="d-flex">
            <ImageHeader title={'Matching Inferior'}></ImageHeader>
            {(!evergineReady || !showCanvas) && (
              <div className="capture-message position-absolute top-50 w-100">
                <div className="section-message">
                  <span>{t(`imageManagement.common.noFiles`)}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
