import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'reflect-metadata';
import App from './App';
import './app.i18n';
import { initializeEvergine } from './common/evergine/evergine-initialize';
import { LoadingText } from './common/components';
import { useBoundStore } from './surgeries/stores/useStore';
import { simd, exceptions } from 'wasm-feature-detect';

(async () => {
  const setWebAssemblySupport = useBoundStore.getState().setIsWebAssemblySupported;
  // Detect WebAssembly support
  const supportsWasm = await simd();
  const supportsExceptions = await exceptions();

  if (!supportsWasm || !supportsExceptions) {
    setWebAssemblySupport(false);
  } else {
    // Initialize Evergine
    Blazor.start()
      .then(() => {
        console.log('Initializing Blazor');
        initializeEvergine();
      })
      .catch((error) => {
        console.error('Error in Blazor.start():', error);
      });
  }
})();

window.addEventListener('unhandledrejection', (event) => {
  const setWebAssemblySupport = useBoundStore.getState().setIsWebAssemblySupported;

  const keywords = ['wasm', 'webassembly'];

  if (event.reason && keywords.some((keyword) => event.reason.message.toLowerCase().includes(keyword))) {
    setWebAssemblySupport(false);
  }
});

(async () => {
  ReactDOM.render(
    <Suspense fallback={<LoadingText />}>
      <App />
      <ToastContainer
        position="bottom-right"
        limit={5}
        autoClose={8000}
        style={{
          width: 'auto',
          minWidth: '320px',
          maxWidth: '500px'
        }}
      />
    </Suspense>,
    document.getElementById('main')
  );
})();
