import { ReactComponent as Attach } from '../../../assets/icons/evergineTools/attach.svg';
import { ReactComponent as AutoCalculate } from '../../../assets/icons/evergineTools/auto-calc.svg';
import { ReactComponent as TADS } from '../../../assets/icons/evergineTools/TADS.svg';
import { ToolbarConfigProps } from '../../../common';
import { useAttachesCalculation } from '../../../hooks';
import { useOrthBoundStore } from '../../stores/useStore';
import { isAttacheType } from './tools/attaches/attacheTypes';
import { OpenAttachesPanel, OpenTadsPanel } from './tools/evergineToolbar.helper';
import { isTadType } from './tools/tads/tadsTypes';

const isDisabledAttachesPanel = () => {
  const selectedAttachment = useOrthBoundStore.getState().selectedAttache;

  if (selectedAttachment && selectedAttachment.type && !isAttacheType(selectedAttachment.type)) {
    return true;
  }

  return false;
};

export const attachesToolbarElement: ToolbarConfigProps = {
  icon: Attach,
  tooltipKey: 'evergineTools.attaches',
  customId: 'orthAttachesButton',
  handleClick: () => {
    OpenAttachesPanel();
  },
  isDisabled: isDisabledAttachesPanel
};

export const calcAIAttachesToolbarElement: ToolbarConfigProps = {
  icon: AutoCalculate,
  tooltipKey: 'evergineTools.autoAttachesCalc',
  labelKey: 'evergineTools.autoAttachesCalc',
  handleClick: async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    await useAttachesCalculation().calculateAttaches();
  }
};

const isDisabledTadsPanel = () => {
  const selectedAttachment = useOrthBoundStore.getState().selectedAttache;

  if (selectedAttachment && selectedAttachment.type && !isTadType(selectedAttachment.type)) {
    return true;
  }

  return false;
};

export const tadsToolbarElement: ToolbarConfigProps = {
  icon: TADS,
  tooltipKey: 'evergineTools.tads',
  customId: 'orthTadsButton',
  handleClick: () => {
    OpenTadsPanel();
  },
  isDisabled: isDisabledTadsPanel
};
