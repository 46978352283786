import { useEvergineStore } from 'evergine-react';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useCallback, useRef, useState } from 'react';
import { CameraPosition, CapturePosition } from '../../common';
import { useShowVisibleTeetharch } from './useShowVisibleTeetharch';
import { TeethArchPosition } from '../../models';
import { useFocusCanvas } from './useFocusCanvas';
import { useShallow } from 'zustand/react/shallow';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { use } from 'i18next';

export function useCamViewChange() {
  const { teethArch, setCamView } = useBoundStore(
    useShallow((state) => ({
      teethArch: state.teethArch,
      setCamView: state.setCamView
    }))
  );

  const { orthTeethArch, cameraPosition, setCameraPosition } = useOrthBoundStore(
    useShallow((state) => ({
      orthTeethArch: state.orthTeethArch,
      cameraPosition: state.cameraPosition,
      setCameraPosition: state.setCameraPosition
    }))
  );

  const { evergineReady } = useEvergineStore();
  const { showVisibleCaptureFromPosition } = useShowVisibleTeetharch();
  const [previousTeetharchPosition, setPreviousTeetharchPosition] = useState<TeethArchPosition>();
  const { setFocusOnCanvas } = useFocusCanvas();

  const isTopOrBottomCameraPosition = (cameraPosition: CameraPosition) =>
    cameraPosition === CameraPosition.Top || cameraPosition === CameraPosition.Bottom;

  const hideTeetharchByPerspective = useCallback(
    (cameraPosition: CameraPosition) => {
      setPreviousTeetharchPosition(teethArch);
      window.App.webEventsProxy.layers.showArches(
        cameraPosition === CameraPosition.Top ? CapturePosition.UPPER : CapturePosition.LOWER
      );
    },
    [teethArch]
  );

  const restoreShowBothTeetharchesIfNecessary = useCallback(() => {
    if (previousTeetharchPosition) {
      showVisibleCaptureFromPosition[previousTeetharchPosition]();
    }
  }, [previousTeetharchPosition]);

  const updateCameraPosition = useCallback(
    async (cameraPosition: CameraPosition) => {
      if (!evergineReady) {
        return;
      }

      const isViewFromTopOrBottom = isTopOrBottomCameraPosition(cameraPosition);
      setCameraPosition(cameraPosition);

      if (isViewFromTopOrBottom) {
        hideTeetharchByPerspective(cameraPosition);
      } else {
        restoreShowBothTeetharchesIfNecessary();
      }

      window.App.webEventsProxy.common.setCameraPosition(cameraPosition);
      setCamView(cameraPosition);
      setFocusOnCanvas();
    },
    [evergineReady, previousTeetharchPosition, showVisibleCaptureFromPosition]
  );

  const currentArchView = useCallback(() => {
    if (orthTeethArch === TeethArchPosition.LOWER) {
      return TeethArchPosition.LOWER;
    } else if (orthTeethArch === TeethArchPosition.UPPER) {
      return TeethArchPosition.UPPER;
    } else if (cameraPosition === CameraPosition.Top) {
      return TeethArchPosition.UPPER;
    } else if (cameraPosition === CameraPosition.Bottom) {
      return TeethArchPosition.LOWER;
    } else {
      return TeethArchPosition.BOTH;
    }
  }, [orthTeethArch, cameraPosition]);

  return {
    updateCameraPosition,
    currentArchView
  };
}
