import React, { useMemo, useCallback } from 'react';
import { ContextMenu } from '../../../../common';
import { CustomContextMenuProps } from '../customContextMenuProps';
import { PointerPosition } from '../../../stores/slices/orthContextMenuSlice';
import { OpenAttachesPanel, OpenTadsPanel } from '../../evergineToolbarElements/tools/evergineToolbar.helper';
import { useTeethContextMenu } from './useTeethContextMenu';

interface TeethContextMenuProps extends CustomContextMenuProps {
  teethPosition?: PointerPosition;
}

export const TeethContextMenu = React.memo(({ onToggleAction, positionData, show }: TeethContextMenuProps) => {
  const { removeTeethIfSelected } = useTeethContextMenu(onToggleAction);

  const handleRemoveTeeth = useCallback(() => {
    removeTeethIfSelected();
  }, [removeTeethIfSelected]);

  const teethOptions = useMemo(
    () => [
      {
        labelKey: 'contextMenuOptions.removeFromStepOne',
        action: handleRemoveTeeth
      },
      {
        labelKey: 'contextMenuOptions.addAttache',
        action: OpenAttachesPanel
      },
      {
        labelKey: 'contextMenuOptions.addTAD',
        action: OpenTadsPanel
      }
    ],
    [handleRemoveTeeth]
  );

  return (
    <ContextMenu
      options={teethOptions}
      positionData={positionData}
      show={show}
      onToggle={onToggleAction}
      autoClose={true}
    />
  );
});
