import { useEffect, useRef, useState } from 'react';
import { useCommonBoundStore } from '../../../../common/stores/useStore';
import { useOrthBoundStore } from '../../../stores/useStore';
import { MovementCalculationType } from '../../../../common';
import { useToothTransformStore } from '../../../stores/useToothTransformStore';
import { useMovementsTableData } from '../../../../hooks';
import { MovementsTableHeader } from './MovementsTableHeader';
import { MovementsTableQuadrant } from './MovementsTableQuadrant';
import { MovementsTableOcclusionRow } from './MovementsTableOcclusionRow';

import './movementstable.scss';
import { useShallow } from 'zustand/react/shallow';
interface MovementsTableProps {
  calculationType: MovementCalculationType;
}

export function MovementsTable({ calculationType }: MovementsTableProps) {
  const selectedTeethTransformData = useToothTransformStore(useShallow((state) => state.selectedTeethTransformData));
  const activeStep = useCommonBoundStore(useShallow((state) => state.activeStep));
  const { showMovementsTable, upperDentalMovements, lowerDentalMovements } = useOrthBoundStore(
    useShallow((state) => ({
      showMovementsTable: state.showMovementsTable,
      upperDentalMovements: state.upperDentalMovements,
      lowerDentalMovements: state.lowerDentalMovements
    }))
  );

  const { tableData, getMovementsTable, shouldBeWarnedAboutExceededMovement } = useMovementsTableData(
    calculationType,
    activeStep,
    selectedTeethTransformData
  );

  const [isBodyScrollVisible, setIsBodyScrollVisible] = useState<boolean>(false);

  const piecesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!piecesRef.current || !showMovementsTable) {
      return;
    }
    const isScrollVisible = piecesRef.current?.clientHeight < piecesRef.current?.scrollHeight;
    setIsBodyScrollVisible(isScrollVisible);
    getMovementsTable();
  }, [piecesRef, showMovementsTable, activeStep, calculationType, upperDentalMovements, lowerDentalMovements]);

  return (
    <div className="movementstable">
      <MovementsTableHeader isScrollVisible={isBodyScrollVisible} />
      <div className="movementstable-body" ref={piecesRef}>
        {tableData.length > 0 &&
          [1, 2, 3, 4].map((quadrant) => (
            <MovementsTableQuadrant
              key={`quadrant-${quadrant}`}
              quadrantNumber={quadrant}
              asc={quadrant % 2 === 0}
              tableData={tableData}
              shouldBeWarned={shouldBeWarnedAboutExceededMovement}
            />
          ))}
        <MovementsTableOcclusionRow />
      </div>
    </div>
  );
}
